import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {DxPopupComponent} from "devextreme-angular/ui/popup";
import {DetectMethodChanges, isVoid, wrapInPromise} from "../utils";
import {
    GetPivotsData, GetPivotsDataReply,
    GetRecentPivots,
    GetRecentPivotsReply,
    PivotPointDescriptor
} from "../shell-communication/shell-operations-protocol";
import {ShellClientService} from "../shell-communication/shell-client.service";

export interface PivotsRow {
    upper?: PivotPointDescriptor;
    lower?: PivotPointDescriptor;
}

export interface DataUnitModel {
    header: string;
    oneMinRow: PivotsRow;
    fiveMinRow: PivotsRow;
    tenMinRow: PivotsRow;
    fifteenMinRow: PivotsRow;
}

@Component({
    selector: 'ets-pivot-points-popup',
    templateUrl: 'pivot-points-popup.component.html',
    styleUrls: ['pivot-points-popup.component.scss']
})

export class PivotPointsPopupComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _shellClient: ShellClientService,
    ) {
    }

    private _allDescriptors: PivotPointDescriptor[] = [];
    private _timeoutId: number;

    @ViewChild(DxPopupComponent)
    popup: DxPopupComponent;

    isLoading = false;

    upTrendClose: DataUnitModel;
    upTrendWicks: DataUnitModel;
    downTrendClose: DataUnitModel;
    downTrendWicks: DataUnitModel;
    mostRecentClose: DataUnitModel;
    mostRecentWicks: DataUnitModel;

    async ngOnInit() {
    }

    @DetectMethodChanges()
    async showPopup() {
        this.popup.visible = true;
        this.setupData();
        this.loadPivots();
    }

    @DetectMethodChanges()
    onHidden() {
        this.popup.visible = false;
        clearTimeout(this._timeoutId);
    }

    @DetectMethodChanges()
    loadPivots() {
        const self = this;
        const qry = new GetPivotsData();
        this._shellClient.processQuery<GetPivotsDataReply>(qry)
            .then((data) => {
                this.onPivotDataLoaded(data);
            })
            .catch((err) => {
                console.error(err);
            })
            .finally(() => {
                this.isLoading = false;
                this._changeDetector.detectChanges();
                if (this.popup.visible) {
                    self._timeoutId = setTimeout(() => this.loadPivots(), 10 * 1000) as any;
                }
            });
    }

    private setupData() {
        this._allDescriptors.length = 0;

        this.upTrendClose = {
            header: "Close",
            oneMinRow: {
                upper: {timeframe: '1 min', trend: 'Up', pivotSource: 'Close', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '1 min', trend: 'Up', pivotSource: 'Close', direction: 'Down', ticker: 'SPX'}
            },
            fiveMinRow: {
                upper: {timeframe: '5 min', trend: 'Up', pivotSource: 'Close', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '5 min', trend: 'Up', pivotSource: 'Close', direction: 'Down', ticker: 'SPX'}
            },
            tenMinRow: {
                upper: {timeframe: '10 min', trend: 'Up', pivotSource: 'Close', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '10 min', trend: 'Up', pivotSource: 'Close', direction: 'Down', ticker: 'SPX'}
            },
            fifteenMinRow: {
                upper: {timeframe: '15 min', trend: 'Up', pivotSource: 'Close', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '15 min', trend: 'Up', pivotSource: 'Close', direction: 'Down', ticker: 'SPX'}
            },
        };
        this.addToDescriptors(this.upTrendClose);

        this.upTrendWicks = {
            header: "Wicks",
            oneMinRow: {
                upper: {timeframe: '1 min', trend: 'Up', pivotSource: 'Wicks', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '1 min', trend: 'Up', pivotSource: 'Wicks', direction: 'Down', ticker: 'SPX'}
            },
            fiveMinRow: {
                upper: {timeframe: '5 min', trend: 'Up', pivotSource: 'Wicks', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '5 min', trend: 'Up', pivotSource: 'Wicks', direction: 'Down', ticker: 'SPX'}
            },
            tenMinRow: {
                upper: {timeframe: '10 min', trend: 'Up', pivotSource: 'Wicks', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '10 min', trend: 'Up', pivotSource: 'Wicks', direction: 'Down', ticker: 'SPX'}
            },
            fifteenMinRow: {
                upper: {timeframe: '15 min', trend: 'Up', pivotSource: 'Wicks', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '15 min', trend: 'Up', pivotSource: 'Wicks', direction: 'Down', ticker: 'SPX'}
            },
        };
        this.addToDescriptors(this.upTrendWicks);

        this.downTrendClose = {
            header: "Close",
            oneMinRow: {
                upper: {timeframe: '1 min', trend: 'Down', pivotSource: 'Close', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '1 min', trend: 'Down', pivotSource: 'Close', direction: 'Down', ticker: 'SPX'}
            },
            fiveMinRow: {
                upper: {timeframe: '5 min', trend: 'Down', pivotSource: 'Close', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '5 min', trend: 'Down', pivotSource: 'Close', direction: 'Down', ticker: 'SPX'}
            },
            tenMinRow: {
                upper: {timeframe: '10 min', trend: 'Down', pivotSource: 'Close', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '10 min', trend: 'Down', pivotSource: 'Close', direction: 'Down', ticker: 'SPX'}
            },
            fifteenMinRow: {
                upper: {timeframe: '15 min', trend: 'Down', pivotSource: 'Close', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '15 min', trend: 'Down', pivotSource: 'Close', direction: 'Down', ticker: 'SPX'}
            },
        };
        this.addToDescriptors(this.downTrendClose)

        this.downTrendWicks = {
            header: "Wicks",
            oneMinRow: {
                upper: {timeframe: '1 min', trend: 'Down', pivotSource: 'Wicks', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '1 min', trend: 'Down', pivotSource: 'Wicks', direction: 'Down', ticker: 'SPX'}
            },
            fiveMinRow: {
                upper: {timeframe: '5 min', trend: 'Down', pivotSource: 'Wicks', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '5 min', trend: 'Down', pivotSource: 'Wicks', direction: 'Down', ticker: 'SPX'}
            },
            tenMinRow: {
                upper: {timeframe: '10 min', trend: 'Down', pivotSource: 'Wicks', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '10 min', trend: 'Down', pivotSource: 'Wicks', direction: 'Down', ticker: 'SPX'}
            },
            fifteenMinRow: {
                upper: {timeframe: '15 min', trend: 'Down', pivotSource: 'Wicks', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '15 min', trend: 'Down', pivotSource: 'Wicks', direction: 'Down', ticker: 'SPX'}
            },
        };
        this.addToDescriptors(this.downTrendWicks);

        this.mostRecentClose = {
            header: "Close",
            oneMinRow: {
                upper: {timeframe: '1 min', trend: 'Most Recent', pivotSource: 'Close', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '1 min', trend: 'Most Recent', pivotSource: 'Close', direction: 'Down', ticker: 'SPX'}
            },
            fiveMinRow: {
                upper: {timeframe: '5 min', trend: 'Most Recent', pivotSource: 'Close', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '5 min', trend: 'Most Recent', pivotSource: 'Close', direction: 'Down', ticker: 'SPX'}
            },
            tenMinRow: {
                upper: {timeframe: '10 min', trend: 'Most Recent', pivotSource: 'Close', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '10 min', trend: 'Most Recent', pivotSource: 'Close', direction: 'Down', ticker: 'SPX'}
            },
            fifteenMinRow: {
                upper: {timeframe: '15 min', trend: 'Most Recent', pivotSource: 'Close', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '15 min', trend: 'Most Recent', pivotSource: 'Close', direction: 'Down', ticker: 'SPX'}
            },
        };
        this.addToDescriptors(this.mostRecentClose);

        this.mostRecentWicks = {
            header: "Wicks",
            oneMinRow: {
                upper: {timeframe: '1 min', trend: 'Most Recent', pivotSource: 'Wicks', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '1 min', trend: 'Most Recent', pivotSource: 'Wicks', direction: 'Down', ticker: 'SPX'}
            },
            fiveMinRow: {
                upper: {timeframe: '5 min', trend: 'Most Recent', pivotSource: 'Wicks', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '5 min', trend: 'Most Recent', pivotSource: 'Wicks', direction: 'Down', ticker: 'SPX'}
            },
            tenMinRow: {
                upper: {timeframe: '10 min', trend: 'Most Recent', pivotSource: 'Wicks', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '10 min', trend: 'Most Recent', pivotSource: 'Wicks', direction: 'Down', ticker: 'SPX'}
            },
            fifteenMinRow: {
                upper: {timeframe: '15 min', trend: 'Most Recent', pivotSource: 'Wicks', direction: 'Up', ticker: 'SPX'},
                lower: {timeframe: '15 min', trend: 'Most Recent', pivotSource: 'Wicks', direction: 'Down', ticker: 'SPX'}
            },
        }
        this.addToDescriptors(this.mostRecentWicks);
    }

    private addToDescriptors(data: DataUnitModel) {
        this._allDescriptors.push(data.oneMinRow.upper);
        this._allDescriptors.push(data.oneMinRow.lower);
        this._allDescriptors.push(data.fiveMinRow.upper);
        this._allDescriptors.push(data.fiveMinRow.lower);
        this._allDescriptors.push(data.tenMinRow.upper);
        this._allDescriptors.push(data.tenMinRow.lower);
        this._allDescriptors.push(data.fifteenMinRow.upper);
        this._allDescriptors.push(data.fifteenMinRow.lower);
    }

    @DetectMethodChanges()
    private onPivotDataLoaded(data: GetPivotsDataReply) {
        if (isVoid(data.pivots)) {
            return;
        }

        data.pivots.forEach(pp => {
            const match = this._allDescriptors.find(x => {
                const same = pp.ticker == x.ticker &&
                    pp.timeframe == x.timeframe &&
                    pp.trend == x.trend &&
                    pp.direction == x.direction &&
                    pp.pivotSource == x.pivotSource;

                return same;
            });

            if (isVoid(match)) {
                return;
            }

            match.pivotPoint = pp.pivotPoint;
            match.roundedPivotPoint = Math.round(pp.pivotPoint);
            match.barTime = pp.barTime;

        });
    }
}