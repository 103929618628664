<div class="root" #root>
  
  <div class="inputs" *ngIf="this.controlPanelExpanded">
    
    <div class="input selector">
      <div class="hedge-selector" (click)="this.showHedgesSelector()">
        <div>
          {{ this.getColumnSelectorTextHedges() }}
        </div>
        <div> | </div>
        <div>
          {{ this.getColumnSelectorTextExpirations() }}
        </div>
      </div>
    </div>
    
    <div class="input pocket">
      <dx-button text="Pocket" height="100%" (onClick)="this.showPocket()"></dx-button>
    </div>
    
    <div class="input positions">
      <div class="label">Show Positions</div>
      <div class="control">
        <dx-check-box [(value)]="this.showPortfolioPositions"
                      (onValueChanged)="this.onShowPortfolioPositionsChanged()"
        ></dx-check-box>
      </div>
    </div>
    
    <div class="input strikes-no">
      <div class="label"># of Strikes</div>
      <div class="control">
        <dx-number-box
          [inputAttr]="{style: 'text-align: center;'}"
          [(value)]="this.numOfStrikes" (onValueChanged)="this.onNumberOfStrikesChanged()"
        ></dx-number-box>
      </div>
    </div>
    
    <div class="input strike-step">
      <div class="label">Strike Step</div>
      <div class="control">
        <dx-select-box [inputAttr]="{style: 'text-align: center;'}"
                       [items]="this.strikeSteps"
                       [(value)]="this.strikeStep"
          (onValueChanged)="this.onStrikeStepChanged()"
        ></dx-select-box>
      </div>
    </div>
    
    <div class="input atm-strike">
      <div class="label">ATM Strike</div>
      <div class="control">
        <ets-strikes-dropdown
          [targetInstrument]="this.tradingInstrument"
          [expiration]="this.expirationForAtmSelector"
          [(strike)]="this.centerStrike"
          (strikeChanged)="this.onCenterStrikeChanged()"
        ></ets-strikes-dropdown>
      </div>
    </div>
    
    <div class="input expand">
      <div class="label">Collapse Panel</div>
      <div class="control">
        <i (click)="this.toggleControlsPanel()" class="fas fa-compress-arrows-alt expand-icon"></i>
      </div>
    </div>
    
    <div class="input reduce">
      <div class="label">Show Headers</div>
      <div class="control">
        <dx-select-box [items]="this.headersList"
                       [(value)]="this.headers"
                       (onValueChanged)="this.onHeadersModeChanged()"
                       [inputAttr]="{style: 'text-align: center;'}"
        ></dx-select-box>
      </div>
    </div>
    
    <div class="input zoom">
      <div class="label">Zoom</div>
      <div class="control">
        <div class="btn plus" (click)="this.decreaseZoom()">
          -
        </div>
        <div class="number">
          <dx-number-box
            [min]="1"
            [max]="100"
            [(value)]="this.zoomLevel"
            [inputAttr]="{style: 'text-align: center;'}"
            format="#%"
            [readOnly]="true"
            (onValueChanged)="this.setZoom($event)"
          ></dx-number-box>
        </div>
        <div class="btn minus" (click)="this.increaseZoom()">
          +
        </div>
      </div>
    </div>
    
  </div>
  
  <div class="inputs collapsed" *ngIf="!this.controlPanelExpanded">
    <div class="input restore">
      <i (click)="this.toggleControlsPanel()" class="fas fa-expand-arrows-alt expand-icon"></i>
      <span>Restore Control Panel</span>
    </div>
    <div class="input reduce">
      <div class="label">Show Headers</div>
      <div class="control">
        <dx-select-box [items]="this.headersList"
                       [(value)]="this.headers"
                       (onValueChanged)="this.onHeadersModeChanged()"
                       [inputAttr]="{style: 'text-align: center;'}"
        ></dx-select-box>
      </div>
    </div>
  </div>
  
  <div class="grid" [style.zoom]="this.zoomLevel">
    <ag-grid-angular
      class="ag-theme-balham-dark"
      [gridOptions]="this.gridOptions"
    ></ag-grid-angular>
  </div>
  
  <dx-load-panel [visible]="this.isLoading" [position]="{my: 'center', at: 'center', of: root}"></dx-load-panel>
</div>

<ets-hg-hedge-matrix-hedges-selector>
</ets-hg-hedge-matrix-hedges-selector>

<ets-hedge-matrix-slide-hedge-dialog>
</ets-hedge-matrix-slide-hedge-dialog>

<ets-hedge-pocket></ets-hedge-pocket>