import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    OnInit,
    Output, QueryList,
    ViewChild, ViewChildren
} from '@angular/core';
import {DxPopupComponent} from 'devextreme-angular/ui/popup';
import {
    deepCloneObject,
    DetectMethodChanges,
    DetectSetterChanges, getShortUUID, isOptionExpired, isValidNumber,
    isVoid, makeFullExpirationDate, makeGuiFriendlyExpirationDate,
    shortNameCodeForStrategy
} from 'projects/shared-components/utils';
import {
    AdjustmentSweetPrice,
    DynamicTemplateMapping,
    ExpirationSweetPrice,
    GlobalSweetPriceExclusion,
    SweetPriceNotificationStrategy,
    SweetPriceSettings
} from './SweetPriceTrackingSettings';
import * as Enumerable from 'linq';
import {SweetPriceTrackingService} from '../../services/sweet-price-tracking.service';
import {ToastrService} from 'ngx-toastr';
import {ICashFlowAdjustmentSettingsTemplate} from '../../model/ICashFlowAdjustmentSettingsTemplate';
import {CashFlowStrategyTemplatesService} from '../../services/cashflow-strategy-templates.service';
import {PriceZoneDescriptor} from "./PriceZoneDescriptor";
import {PositionsData} from "../model/PositionsData";
import {BeforePositionModel} from '../model/BeforePositionModel';
import {HedgePositionsService} from "../../../hedging-grid/positions-section/hedge-positions/hedge-positions.service";
import {ServiceConfiguration} from "../../services/ServiceConfiguration";
import {CashFlowPortfoliosService} from "../../services/cashflow-portfolios.service";
import {HedgeToTrack} from "./hedge-to-track";
import {UserSettingsService} from "../../../user-settings.service";
import {PackagesStorageKey} from "../../../package-comparison/package-comparison.component";
import {Hedge, PackageComparisonDto} from "../../../package-comparison/model/PackageComparisonModel";
import {IPackageToTrack, PackageToTrack, PackageToTrackGroup} from "./package-to-track";
import {SessionService} from "../../../authentication/session-service.service";
import {MessageBusService} from "../../../message-bus.service";
import {
    CheckIfPivotsTracking,
    CheckIfPivotsTrackingReply
} from "../../../shell-communication/shell-operations-protocol";
import {ShellClientService} from "../../../shell-communication/shell-client.service";
import {
    HedgeTrackingGroup,
    HedgesTotalSweetPriceComponent
} from "./hedges-total-sweet-price/hedges-total-sweet-price.component";
import {ApgPortfolio} from "../../model/ApgPortfolio";
import {HedgeItemToTrack} from "../../../shell-communication/shell-dto-protocol";
import {invalid} from "moment";
import {HedgeMatrixTransLeg} from "../../../hedging-grid/hedge-matrix/hedge-matrix-trans.leg";

const Adjustments = ['#1', '#2A', '#2B', '#3', '#3DO'];

type PauseNotificationTarget =
    'global-sweet-price'
    | 'sweet-price-by-expiration'
    | 'sweet-price-by-adjustment'
    | 'automated-price-zones'
    | 'custom-price-zones'
    | 'hedges'
    | 'package-comparisons';

export interface SweetPricePopupConfig {
    userId: string;
    portfolio: ApgPortfolio;
    underlying?: string;
    daysToLookForward?: number;
    template: ICashFlowAdjustmentSettingsTemplate,
    positions?: PositionsData[]
}

type PivotsEventToTrack = 'Upper Pivot' | 'Lower Pivot' | 'Both' | 'Breakout' | 'Reversal' | 'Breakout & Reversal';

@Component({
    selector: 'ets-apg-tracking-dialog',
    templateUrl: './apg-tracking-dialog.component.html',
    styleUrls: ['./apg-tracking-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ApgTrackingDialogComponent implements OnInit, AfterViewInit {
    constructor(
        private _changeDetector: ChangeDetectorRef,
        private _sweetPriceSettingsService: SweetPriceTrackingService,
        private _toastr: ToastrService,
        private _templatesService: CashFlowStrategyTemplatesService,
        private _hedgesService: HedgePositionsService,
        private readonly _portfolioService: CashFlowPortfoliosService,
        private readonly _userSettingsService: UserSettingsService,
        private readonly _sessionService: SessionService,
        private readonly _messageBus: MessageBusService,
        private readonly _shellService: ShellClientService
    ) {
    }

    private _config: SweetPricePopupConfig;
    private _onClosePromise: () => void;
    private _advancedPrisingMismatchAcknowledged: boolean;
    private _advancedPrisingMismatch2Acknowledged: boolean;


    @ViewChild(DxPopupComponent) popup: DxPopupComponent;

    @Output() settingsUpdated = new EventEmitter();

    globalHedges: HedgeTrackingGroup;

    hedgesByExpiration: HedgeTrackingGroup[] = [];

    visible = false;

    private _isLoading: boolean;
    get isLoading(): boolean {
        return this._isLoading;
    }

    @DetectSetterChanges()
    set isLoading(v: boolean) {
        this._isLoading = v;
    }

    get isSuperUser(): boolean {
        return this._sessionService.isSuperUser;
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
    }

    adjustmentOneHeader: string;

    adjustmentTwoHeader: string;

    startTime: string;

    stopTime: string;

    private _timezone: string;
    get timezone(): string {
        return this._timezone;
    }

    set timezone(value: string) {
        const old = this._timezone;
        this._timezone = value;
        this._messageBus.publish({
            topic: 'ApgTrackingDialog.TimezoneChanged',
            payload: {
                previous: old,
                current: value
            }
        });
    }

    notificationInterval: number;

    notificationStrategy: SweetPriceNotificationStrategy;

    notificationIntervalList = [
        1,
        5,
        10,
        15,
        20,
        30,
        60
    ];

    notificationStrategyList: SweetPriceNotificationStrategy[] = [
        'At Checkpoints',
        'Within Intervals'
    ];

    expirations = [];

    adjustments1 = [];

    adjustments2 = [];

    private _notifyTwentyFourSeven = false;
    get notifyTwentyFourSeven() {
        return this._notifyTwentyFourSeven;
    }

    @DetectSetterChanges({delay: 0})
    set notifyTwentyFourSeven(value) {
        this._notifyTwentyFourSeven = value;

        if (value) {
            this.startTime = null;
            this.stopTime = null;
            this.timezone = null;
        }
    }

    dynamicTemplates = [
        {dayOfWeek: 'Monday', template: null},
        {dayOfWeek: 'Tuesday', template: null},
        {dayOfWeek: 'Wednesday', template: null},
        {dayOfWeek: 'Thursday', template: null},
        {dayOfWeek: 'Friday', template: null},
    ];

    availableTemplates: ICashFlowAdjustmentSettingsTemplate[] = [];

    // --
    useAdvancedPricing = false;

    sweetPricesByExpiration: ExpirationSweetPrice[] = [];

    sweetPricesByExpirationPauseTillTime: string;

    // --
    sweetPricesByAdjustment: AdjustmentSweetPrice[] = [];

    sweetPricesByAdjustmentPauseTillTime: string;

    // --
    globalSweetPrice: number;

    globalSweetPriceExclusions: GlobalSweetPriceExclusion[] = [];

    globalSweetPriceUseAdvancedPricing = false;

    globalSweetPricePauseTillTime: string

    offsetFilter: number;

    spreadWidthFilter: number;

    upperPoPriceZone: PriceZoneDescriptor = {
        enabled: false,
        nickname: 'Upper PO'
    }

    atmPriceZone: PriceZoneDescriptor = {
        enabled: false,
        nickname: 'ATM'
    }

    bottomPoPriceZone: PriceZoneDescriptor = {
        enabled: false,
        nickname: 'Lower PO'
    }

    // --
    customPriceZones: PriceZoneDescriptor[] = [];

    customPriceZonesPauseTillTime: string;

    autoPriceZonesPauseTillTime: string;

    priceZoneAtmNotificationFilter: number;

    pivotsEnabled = false;

    pivotsPauseTillTime: string;

    pivotsDirection: 'Up' | 'Down' | 'Most Recent';

    pivotsEventToTrackList : PivotsEventToTrack[] = [];
    pivotsEventToTrack: PivotsEventToTrack;

    eventTrigger: 'On Touch' | 'On Close';

    pivotsChartTimeframe: string;

    pivotsTimeToTrack: string;

    pivotsSource: 'Close' | 'Wicks';

    overrideAtm: number;

    private _priceZoneAtmNotificationFilterEnabled = false;
    get priceZoneAtmNotificationFilterEnabled(): boolean {
        return this._priceZoneAtmNotificationFilterEnabled;
    }

    @DetectSetterChanges()
    set priceZoneAtmNotificationFilterEnabled(value: boolean) {
        this._priceZoneAtmNotificationFilterEnabled = value;
        if (!this._priceZoneAtmNotificationFilterEnabled) {
            this.priceZoneAtmNotificationFilter = null;
        }
    }

    // --
    hedgesPauseTillTime: string;

    // --
    packageComparisons: PackageToTrackGroup[] = [];
    packageComparisonsPauseTillTime: string;

    get isSingleSided(): boolean {
        if (!this._config.template) {
            return false;
        }

        return this._config.template.strategyName !== 'Calls & Puts';
    }

    get adjustmentOneEnabled(): boolean {
        return !this.isSingleSided || this._config.template.strategyName !== 'Puts';
    }

    get adjustmentTwoEnabled(): boolean {
        return !this.isSingleSided || this._config.template.strategyName === 'Puts';
    }

    get canSaveChanges(): boolean {
        return true;
    }

    @DetectMethodChanges()
    show(config: SweetPricePopupConfig): Promise<void> {
        this._config = config;

        this.reset();

        this.visible = true;

        return new Promise((res) => {
            this._onClosePromise = res;
        });
    }

    @DetectMethodChanges()
    onHidden() {
        this.visible = false;
        this._onClosePromise();


        console.log(this.globalHedges);
        console.log(this.hedgesByExpiration);
    }

    @DetectMethodChanges({isAsync: true})
    async onShown() {

        this.isLoading = true;

        this.availableTemplates = this._templatesService
            .getTemplates()
            .filter(x => x.strategyName === this._config.template.strategyName)
            .filter(x => x.underlying === this._config.template.underlying);

        if (this._config.template.strategyName === 'Calls & Puts') {
            this.adjustmentOneHeader = 'Calls';
            this.adjustmentTwoHeader = 'Puts';

            this.adjustments1 = Adjustments.map(x => ({name: `${x}(C)`, value: x}));
            this.adjustments2 = Adjustments.map(x => ({name: `${x}(P)`, value: x}));

        } else {

            if (this._config.template.strategyName === 'Puts') {

                this.adjustmentOneHeader = '-';
                this.adjustmentTwoHeader = 'Puts';
                this.adjustments1 = [];
                this.adjustments2 = Adjustments.map(x => ({name: `${x}(P)`, value: x}));

            } else {

                const shortName = shortNameCodeForStrategy(this._config.template.strategyName);

                if (this._config.template.strategyName === 'Calls') {
                    this.adjustmentOneHeader = 'Calls';
                } else {
                    this.adjustmentOneHeader = shortName;
                }

                this.adjustmentTwoHeader = '-';
                this.adjustments1 = Adjustments.map(x => ({name: `${x}(${shortName})`, value: x}));
                this.adjustments2 = [];
            }

        }

        const expirations = [];

        for (let i = 1; i <= this._config.template.expirationSettings.expirationsToLookForward; i++) {
            const name = `Exp. ${i}`;
            expirations.push({name, id: i});
        }

        this.expirations = expirations;

        try {


            const settings = this._sweetPriceSettingsService.getSweetPriceSettings(
                this._config.userId,
                this._config.portfolio.id
            );

            if (isVoid(settings)) {
                this.sweetPricesByExpiration = Enumerable.generate(() => ({price: null}), this._config.daysToLookForward).toArray();
                this.sweetPricesByAdjustment = Enumerable.generate(() => ({}), this._config.daysToLookForward).toArray();
                return;
            }

            this.restoreNotificationWindowSettings(settings);

            this.restoreGlobalAtm(settings);

            this.restoreSweetPriceByExpirationSettings(settings);

            this.restoreSweetPriceByAdjustmentSettings(settings);

            this.restoreTemplatesSettings(settings);

            this.restoreGlobalSweetPriceSettings(settings);

            this.restoreFiltersSettings(settings);

            this.restorePricesZonesSettings(settings);

            this.restorePackageComparisonsSettings(settings);

            await this.restoreHedgesSettings(settings);

            await this.restorePivotsSettings(settings);

        } finally {

            const so = this.makeSettingsObject();

            this.storeSettings(so);

            this.isLoading = false;
        }

    }

    private restorePricesZonesSettings(settings: SweetPriceSettings) {
        const upperPoZone = this._config.positions[0].positions
            .find(x => x.role === 'ProtectiveOption');

        const atmZone = this._config.positions[0].positions
            .find(x => x.role === 'ShortOption');

        let bottomPoZone: BeforePositionModel;
        if (this._config.positions.length > 1) {
            bottomPoZone = this._config.positions[1].positions.find(x => x.role === 'ProtectiveOption');
        }

        const priceZones = settings.priceZones || [];

        if (!isVoid(upperPoZone)) {
            this.upperPoPriceZone.enabled = false;
            this.upperPoPriceZone.midPoint = upperPoZone.strike;

            if (!isVoid(priceZones)) {
                const savedUpperPo = priceZones.find(x => x.nickname === 'Upper PO');
                if (!isVoid(savedUpperPo)) {
                    this.upperPoPriceZone.enabled = savedUpperPo.enabled;
                    this.upperPoPriceZone.rangeUp = savedUpperPo.rangeUp;
                    this.upperPoPriceZone.rangeDown = savedUpperPo.rangeDown;
                }
            }
        } else {
            this.upperPoPriceZone.enabled = false;
        }

        if (!isVoid(atmZone)) {
            this.atmPriceZone.enabled = false;
            this.atmPriceZone.midPoint = atmZone.strike;

            if (!isVoid(priceZones)) {
                const savedATMZone = priceZones.find(x => x.nickname === 'ATM');
                if (!isVoid(savedATMZone)) {
                    this.atmPriceZone.enabled = savedATMZone.enabled;
                    this.atmPriceZone.rangeUp = savedATMZone.rangeUp;
                    this.atmPriceZone.rangeDown = savedATMZone.rangeDown;
                }
            }
        } else {
            this.atmPriceZone.enabled = false;
        }

        if (!isVoid(bottomPoZone)) {
            this.bottomPoPriceZone.midPoint = bottomPoZone.strike;
            this.bottomPoPriceZone.enabled = false;

            if (!isVoid(priceZones)) {
                const savedBottomZone = priceZones
                    .find(x => x.nickname === 'Lower PO');

                if (!isVoid(savedBottomZone)) {
                    this.bottomPoPriceZone.enabled = savedBottomZone.enabled;
                    this.bottomPoPriceZone.rangeUp = savedBottomZone.rangeUp;
                    this.bottomPoPriceZone.rangeDown = savedBottomZone.rangeDown;
                }
            }
        } else {
            this.bottomPoPriceZone.enabled = false;
        }

        priceZones.forEach(pz => {
            if (pz.nickname === 'Upper PO') {
                return;
            }

            if (pz.nickname === 'ATM') {
                return;
            }

            if (pz.nickname === 'Lower PO') {
                return;
            }

            this.customPriceZones.push(pz);

        });

        this.priceZoneAtmNotificationFilter = settings.priceZoneAtmNotificationFilter;

        if (isValidNumber(this.priceZoneAtmNotificationFilter)) {
            this._priceZoneAtmNotificationFilterEnabled = true;
        }

        this.autoPriceZonesPauseTillTime = settings.autoPriceZonesPauseTillTime;

        this.customPriceZonesPauseTillTime = settings.customPriceZonesPauseTillTime;
    }

    private restoreFiltersSettings(settings: SweetPriceSettings) {
        this.offsetFilter = settings.offsetFilter;

        this.spreadWidthFilter = settings.spreadWidthFilter;
    }

    private restoreGlobalSweetPriceSettings(settings: SweetPriceSettings) {
        this.globalSweetPrice = settings.globalSweetPrice;

        this.globalSweetPriceUseAdvancedPricing = settings.globalSweetPriceUseAdvancedPricing || false;

        if (!isVoid(settings.globalSweetPriceExclusions)) {

            this.globalSweetPriceExclusions = deepCloneObject(settings.globalSweetPriceExclusions);

            this.globalSweetPriceExclusions.forEach(x => {
                if (this.isSingleSided) {
                    if (this._config.template.strategyName === 'Puts') {
                        x.selectedAdjustment = null;
                    } else {
                        x.secondSelectedAdjustment = null;
                    }
                    x.singleSided = true;
                } else {
                    x.singleSided = false;
                }

            });
        }

        this.globalSweetPricePauseTillTime = settings.globalSweetPricePauseTillTime;
    }

    private restoreTemplatesSettings(settings: SweetPriceSettings) {
        if (settings.dynamicTemplates) {
            this.dynamicTemplates = settings.dynamicTemplates.map(x => {
                const dtpl = {
                    dayOfWeek: x.dayOfWeek,
                    template: null
                };

                const tpl = this.availableTemplates.find(t => t.templateId === x.templateId);
                dtpl.template = tpl;

                return dtpl;
            });
        }
    }

    private restoreSweetPriceByAdjustmentSettings(settings: SweetPriceSettings) {
        this.sweetPricesByAdjustment = settings.sweetPricesByAdjustment ? settings.sweetPricesByAdjustment.slice() : [];

        this.sweetPricesByAdjustment.forEach(x => {
            if (this.isSingleSided) {
                if (this._config.template.strategyName === 'Puts') {
                    x.selectedAdjustment = null;
                } else {
                    x.secondSelectedAdjustment = null;
                }
                x.singleSided = true;
            } else {
                x.singleSided = false;
            }
        });

        this.sweetPricesByAdjustmentPauseTillTime = settings.sweetPricesByAdjustmentPauseTillTime;
    }

    private restoreSweetPriceByExpirationSettings(settings: SweetPriceSettings) {

        this.useAdvancedPricing = settings.useAdvancedPricing || false;

        let sweetPricesByExpiration = settings.sweetPricesByExpiration.slice();

        // if template updated, then need to sync the number of expirations
        if (settings.sweetPricesByExpiration.length != this._config.daysToLookForward) {
            const fresh = Enumerable.generate(() => ({price: null}), this._config.daysToLookForward).toArray();

            for (let index = 0; index < fresh.length; index++) {

                if (index + 1 > sweetPricesByExpiration.length) {
                    break;
                }

                fresh[index] = sweetPricesByExpiration[index];

            }

            sweetPricesByExpiration = fresh;
        }

        this.sweetPricesByExpiration = sweetPricesByExpiration;

        this.sweetPricesByExpirationPauseTillTime = settings.sweetPricesByExpirationPauseTillTime;
    }

    private restoreNotificationWindowSettings(settings: SweetPriceSettings) {
        this.startTime = settings.startTime;
        this.stopTime = settings.stopTime;
        this._timezone = settings.timezone;
        this.notificationInterval = settings.notificationInterval;
        this.notificationStrategy = settings.notificationStrategy;
        this.notifyTwentyFourSeven = settings.notifyTwentyFourSeven || false;
    }

    private async restorePivotsSettings(settings: SweetPriceSettings): Promise<void> {

        const qry = new CheckIfPivotsTracking(
            this._config.userId,
            this._config.portfolio.id
        );

        const reply = await this._shellService.processQuery<CheckIfPivotsTrackingReply>(qry);

        this.pivotsEnabled = reply?.isTracking;
        this.pivotsDirection = settings.pivotsDirection;
        this.eventTrigger = settings.eventTrigger;
        this.pivotsChartTimeframe = settings.pivotsChartTimeframe;
        this.pivotsEventToTrack = settings.pivotsEventToTrack;
        this.pivotsTimeToTrack = settings.pivotsTimeToTrack;
        this.pivotsSource = settings.pivotsSource;
        this.pivotsPauseTillTime = settings.pivotsPauseTillTime;
    }

//
    @DetectMethodChanges()
    saveSettings() {

        const settingsObject = this.makeSettingsObject();

        try {
            this._sweetPriceSettingsService.validateSweetPriceSettings(
                settingsObject,
                {
                    strategy: this._config.template.strategyName,
                    underlying: this._config.underlying,
                    priceZoneAtmNotificationFilterEnabled: this.priceZoneAtmNotificationFilterEnabled
                },
            );
        } catch (e) {
            this._toastr.error(e);
            return;
        }

        const allAdvancedPricing = this.dynamicTemplates.every(x => {
            const tpl = x.template as ICashFlowAdjustmentSettingsTemplate;
            if (isVoid(tpl) || (typeof tpl === 'string')) {
                return false;
            }
            return tpl.globalSettings.isStrategyAdvancedMode;
        });

        const someAdvancedPricing = this.dynamicTemplates.some(x => {
            const tpl = x.template as ICashFlowAdjustmentSettingsTemplate;
            if (isVoid(tpl) || (typeof tpl === 'string')) {
                return false;
            }
            return tpl.globalSettings.isStrategyAdvancedMode;
        });

        const usingAdvancedPricingSweetPrice = settingsObject.useAdvancedPricing
            || settingsObject.globalSweetPriceUseAdvancedPricing;

        if (usingAdvancedPricingSweetPrice && !allAdvancedPricing) {
            if (!this._advancedPrisingMismatchAcknowledged) {
                const msg = "We've noticed that your sweet price settings are set to use 'Advanced Pricing' in some cases," +
                    " but some or all of the selected templates are not using it. That means you will never receive a sweet price notification " +
                    "for an adjustment from the advanced range.<br><br>This is just an information message. You can change the settings, " +
                    "or click 'Save' again to apply them";

                this._toastr
                    .info(msg, '"Advanced Pricing" Setting Mismatch', {
                        enableHtml: true,
                        closeButton: true,
                        disableTimeOut: true
                    });

                this._advancedPrisingMismatchAcknowledged = true;

                return;
            }
        }

        if (someAdvancedPricing && !usingAdvancedPricingSweetPrice) {
            if (!this._advancedPrisingMismatch2Acknowledged) {
                const msg = "We've noticed that some or all of your selected templates are set to use 'Advanced Pricing' " +
                    "but your sweet price settings are not using it. That means you will never receive a sweet price notification " +
                    "for an adjustment from the advanced range.<br><br>This is just an information message. You can change the settings, " +
                    "or click 'Save' again to apply them";

                this._toastr
                    .info(msg, '"Advanced Pricing" Setting Mismatch', {
                        enableHtml: true,
                        closeButton: true,
                        disableTimeOut: true
                    });

                this._advancedPrisingMismatch2Acknowledged = true;

                return;
            }
        }

        try {
            this.storeSettings(settingsObject);

            this.visible = false;

            this.settingsUpdated.emit(settingsObject);

            this._toastr.success('"Sweet Price Tracking settings have been saved');

        } catch (e) {
            console.error(e);
            this._toastr.error('"Save Sweet Price Tracking Settings" operation completed with errors');
        }

    }

    //
    private storeSettings(sweetPriceSettings: SweetPriceSettings) {
        this._sweetPriceSettingsService.saveSweetPriceTrackingSettings(
            this._config.userId,
            this._config.portfolio.id,
            sweetPriceSettings
        );
    }

    //
    @DetectMethodChanges()
    discardSettings() {
        this.reset();
        this.visible = false;
    }

    //
    @DetectMethodChanges()
    addSweetPriceByAdjustment() {
        this.sweetPricesByAdjustment.push({});
    }

    @DetectMethodChanges()
    addGlobalPriceExclusion() {
        this.globalSweetPriceExclusions.push({});
    }

    //
    @DetectMethodChanges()
    removeSweetPriceByAdjustment(ix: number) {
        this.sweetPricesByAdjustment.splice(ix, 1);
    }

    @DetectMethodChanges()
    removeGlobalPriceExclusion(ix: number) {
        this.globalSweetPriceExclusions.splice(ix, 1);
    }

    private makeSettingsObject(): SweetPriceSettings {

        const sweetPriceSettings: SweetPriceSettings = {
            startTime: this.startTime,
            stopTime: this.stopTime,
            timezone: this.timezone,
            notificationInterval: this.notificationInterval,
            notificationStrategy: this.notificationStrategy,
            notifyTwentyFourSeven: this.notifyTwentyFourSeven,
            useAdvancedPricing: this.useAdvancedPricing,
            sweetPricesByExpiration: this.sweetPricesByExpiration.slice(),
            sweetPricesByAdjustment: this.sweetPricesByAdjustment.slice(),
            dynamicTemplates: this.dynamicTemplates.map(x => {
                const mapping: DynamicTemplateMapping = {
                    dayOfWeek: x.dayOfWeek as any,
                    templateId: x.template ? x.template.templateId : null,
                };

                return mapping;
            }),
            globalSweetPrice: this.globalSweetPrice,
            globalSweetPriceUseAdvancedPricing: this.globalSweetPriceUseAdvancedPricing || false,
            globalSweetPriceExclusions: deepCloneObject(this.globalSweetPriceExclusions),
            offsetFilter: this.offsetFilter,
            spreadWidthFilter: this.spreadWidthFilter,
            priceZoneAtmNotificationFilter: this.priceZoneAtmNotificationFilter,

            autoPriceZonesPauseTillTime: this.autoPriceZonesPauseTillTime,
            customPriceZonesPauseTillTime: this.customPriceZonesPauseTillTime,
            globalSweetPricePauseTillTime: this.globalSweetPricePauseTillTime,
            hedgesPauseTillTime: this.hedgesPauseTillTime,
            packageComparisonsPauseTillTime: this.packageComparisonsPauseTillTime,
            sweetPricesByAdjustmentPauseTillTime: this.sweetPricesByAdjustmentPauseTillTime,
            sweetPricesByExpirationPauseTillTime: this.sweetPricesByExpirationPauseTillTime,

            hedgesToTrack: [],
            packagesToTrack: [],

            overrideAtm: this.overrideAtm,
            overrideAtmExclusions: [],

            pivotsEnabled: this.pivotsEnabled,
            eventTrigger: this.eventTrigger,
            pivotsChartTimeframe: this.pivotsChartTimeframe,
            pivotsEventToTrack: this.pivotsEventToTrack,
            pivotsTimeToTrack: this.pivotsTimeToTrack,
            pivotsPauseTillTime: this.pivotsPauseTillTime,
            pivotsDirection: this.pivotsDirection,
            pivotsSource: this.pivotsSource,
        };

        const zones = this.customPriceZones.slice();

        if (this.upperPoPriceZone.enabled) {
            zones.push(this.upperPoPriceZone);
        }

        if (this.atmPriceZone.enabled) {
            zones.push(this.atmPriceZone);
        }

        if (this.bottomPoPriceZone.enabled) {
            zones.push(this.bottomPoPriceZone);
        }

        sweetPriceSettings.priceZones = zones;

        // Hedges
        const globalTrackingItems = this.globalHedges.getItemsToTrack();
        const hedgeItemToTracks = this.hedgesByExpiration.flatMap(x => x.getItemsToTrack());
        const allHedges = globalTrackingItems.concat(hedgeItemToTracks);
        sweetPriceSettings.hedgesToTrack2 = allHedges;

        const packagesToTrack = this.packageComparisons
            .filter(x => x.isSelected)
            .flatMap(x => {
                const clones = x.items.map(c => {
                    return {
                        packageId: c.packageId,
                        expirationSeqNo: c.selectedExpiration.seqNo,
                        sweetPrice: c.sweetPrice
                    } as IPackageToTrack;
                });
                return clones;
            });

        sweetPriceSettings.packagesToTrack = packagesToTrack;

        return sweetPriceSettings;
    }

    //
    private reset() {
        this._advancedPrisingMismatchAcknowledged = undefined;
        this._advancedPrisingMismatch2Acknowledged = undefined;
        this.startTime = null;
        this.stopTime = null;
        this._timezone = null;
        this.notificationInterval = null;
        this.notificationStrategy = null
        this.notifyTwentyFourSeven = false;
        this.useAdvancedPricing = false;
        this.sweetPricesByExpiration = [];
        this.sweetPricesByAdjustment = [];
        this.globalSweetPrice = null;
        this.globalSweetPriceUseAdvancedPricing = false;
        this.globalSweetPriceExclusions = [];
        this.offsetFilter = null;
        this.spreadWidthFilter = null;
        this.customPriceZones = [];
        this.upperPoPriceZone = {enabled: false, nickname: 'Upper PO'};
        this.atmPriceZone = {enabled: false, nickname: 'ATM'};
        this.bottomPoPriceZone = {enabled: false, nickname: 'Lower PO'};

        this.hedgesByExpiration = [];
        this.globalHedges = null;

        this.packageComparisons = [];

        this.autoPriceZonesPauseTillTime
            = this.customPriceZonesPauseTillTime
            = this.globalSweetPricePauseTillTime
            = this.hedgesPauseTillTime = this.packageComparisonsPauseTillTime
            = this.sweetPricesByAdjustmentPauseTillTime = this.sweetPricesByExpirationPauseTillTime = null;
    }

    @DetectMethodChanges()
    addCustomPriceZone() {
        let zone: PriceZoneDescriptor = {
            enabled: true
        };
        this.customPriceZones.push(zone);
    }

    @DetectMethodChanges()
    removeCustomPriceZone(ix: number) {
        this.customPriceZones.splice(ix, 1);
    }

    @DetectMethodChanges()
    onChange() {

    }

    private async restoreHedgesSettings(settings: SweetPriceSettings) {

        const pf = this._portfolioService.getPortfolios()
            .find(x => x.id === this._config.portfolio.id);

        if (isVoid(pf)) {
            throw Error('Portfolio Not Found');
        }

        const hedgePositions = await this._hedgesService.getHedgePositions(pf);

        const validHedges = Enumerable.from(hedgePositions)
            .orderByDescending(x => x.strike)
            .groupBy(x => x.groupId)
            .where(x => !x.any(y => isOptionExpired(y.ticker)));

        const individualHedges = validHedges.select(x =>
            new HedgeToTrack(x.toArray(), this._changeDetector)
        ).orderByDescending(x => x.baseStrike);

        this.hedgesPauseTillTime = settings.hedgesPauseTillTime;

        const hedgeToTracks = individualHedges.toArray();

        this.fillHedeExpirationsData(hedgeToTracks, settings);
    }

    private restorePackageComparisonsSettings(settings: SweetPriceSettings) {

        const packages = this._userSettingsService
            .getValue<PackageComparisonDto[]>(PackagesStorageKey) || [];

        const namedPackages = packages
            .filter(x => !isVoid(x.name))
            .filter(x => x.portfolioId === this._config.portfolio.id)
            .map(x => new PackageToTrack(x, this._changeDetector))
            .map(x => new PackageToTrackGroup(x, this._changeDetector));

        if (namedPackages.length > 0) {
            namedPackages.forEach(p => {
                const find = settings.packagesToTrack
                    .filter(x => x.packageId === p.packageId);
                if (!isVoid(find)) {
                    p.setExpirations(find);
                    p.isSelected = true;
                }
            });
        }

        this.packageComparisons = namedPackages;

        this.packageComparisonsPauseTillTime = settings.packageComparisonsPauseTillTime;
    }

    private restoreGlobalAtm(settings: SweetPriceSettings) {
        this.overrideAtm = settings.overrideAtm;
    }

    @DetectMethodChanges()
    onPivotsEnabledChanged() {
    }

    private fillHedeExpirationsData(hedges: HedgeToTrack[], settings: SweetPriceSettings) {
        const global = new HedgeTrackingGroup();
        global.header = 'Global';

        const groups: Record<string, HedgeTrackingGroup> = {};

        hedges.forEach(h => {

            const expiration = Enumerable.from(h.positions)
                .select(x => x.expiration)
                .distinct();

            const side = Enumerable.from(h.positions)
                .select(x => x.type)
                .distinct();

            expiration.forEach(e => {

                let expirationGroup = groups[e];

                if (isVoid(expirationGroup)) {
                    expirationGroup = new HedgeTrackingGroup(e);
                    expirationGroup.header = makeFullExpirationDate(e);
                    expirationGroup.somethingChanged$.subscribe(() => this._changeDetector.detectChanges());
                    groups[e] = expirationGroup;
                }

                side.forEach(s => {
                    const item: HedgeItemToTrack = {
                        enabled: false,
                        optionType: s,
                        itemType: 'individual',
                        expiration: e,
                        aggregationType: 'expiration',
                        label: h.name,
                        color: h.color,
                        individualId: h.hedgeId
                    };

                    if (s === 'Call') {
                        expirationGroup.calls.push(item);
                    } else if (s === 'Put') {
                        expirationGroup.puts.push(item);
                    }
                });
            });
        });

        this.globalHedges = global;

        const hedgeTrackingGroups = Object.values(groups);

        this.hedgesByExpiration = Enumerable.from(hedgeTrackingGroups)
            .orderBy(x => x.expiration).toArray();

        settings.hedgesToTrack2
            .filter(hit => hit.enabled)
            .forEach(hit => {
                if (hit.aggregationType === 'global') {

                    const hedgeItemToTrack = global.totals.find(x => x.optionType === hit.optionType);

                    if (!isVoid(hedgeItemToTrack)) {
                        hedgeItemToTrack.enabled = true;
                        hedgeItemToTrack.sweetPrice = hit.sweetPrice;
                    }

                } else if (hit.aggregationType === 'expiration') {

                    const htg = hedgeTrackingGroups.find(x => x.expiration === hit.expiration);

                    if (!isVoid(htg)) {
                        if (hit.itemType === 'aggregate') {

                            const groupHit = htg.totals.find(x => x.optionType === hit.optionType);

                            if (!isVoid(groupHit)) {
                                groupHit.enabled = true;
                                groupHit.sweetPrice = hit.sweetPrice;
                            }

                        } else if (hit.itemType === 'individual') {
                            let container: HedgeItemToTrack[] = [];

                            if (hit.optionType === 'Call') {
                                container = htg.calls;
                            } else if (hit.optionType === 'Put') {
                                container = htg.puts;
                            }

                            const individualHedge = container.find(x => x.individualId === hit.individualId);

                            if (!isVoid(individualHedge)) {
                                individualHedge.enabled = true;
                                individualHedge.sweetPrice = hit.sweetPrice;
                            }

                        }
                    }
                }
            });
    }

    @DetectMethodChanges()
    onPivotsDirectionChanged() {
        if (this.pivotsDirection === 'Most Recent') {
            this.pivotsEventToTrackList = [
                'Upper Pivot',
                'Lower Pivot',
                'Both'
            ];
        } else {
            this.pivotsEventToTrackList = [
                'Breakout',
                'Reversal',
                'Breakout & Reversal'
            ]
        }
    }
}