import {ChangeDetectionStrategy, ChangeDetectorRef, Component} from '@angular/core';
import {IHeaderGroupAngularComp} from "ag-grid-angular";
import {IHeaderGroupParams} from "ag-grid-community";
import {HgHedgeMatrixComponent} from "./hg-hedge-matrix.component";
import {isValidNumber} from "../../utils";

@Component({
    selector: 'ets-hedge-matrix-colgroup-header',
    template: `
        <div class="ag-header-group-cell-label" 
             [class.ets-positive-number]="this.cost > 0"
             [class.ets-negative-number]="this.cost < 0"
             [class.grand-totals]="this.type === 'master'"
        >
          {{this.cost | currency:'USD':'symbol':'1.2-2' }}
        </div>
    `,
    styles: [
        `
            .ag-header-group-cell-label {
                justify-content: center;
                align-items: center;
                justify-self: center;
                text-align: center;
                font-weight: bold;
            }
            
            .grand-totals {
                font-size: 1.3em;
            } 
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class HedgeMatrixCustomColumnHeaderGroup implements IHeaderGroupAngularComp {

    constructor(private _changeDetector: ChangeDetectorRef) {
    }

    get cost() : number {

        switch (this.type) {
            case "master":
                return this.masterTotals();
            case "side":
                return this.sideTotals();
            case "expiration-side":
                return this.expirationSideTotals();
            case "expiration":
                return this.expirationTotals();
        }
    }
    private _interval: number;

    comp: HgHedgeMatrixComponent;

    type: 'master' | 'side' | 'expiration-side' | 'expiration';

    side: string;

    expiration: string;

    allInclusive: boolean;

    agInit(params: IHeaderGroupParams): void {

        this.comp = params.context.comp;

        this.type = params.context.type;

        this.side = params.context.side;

        this.expiration = params.context.expiration;

        this.allInclusive = params.context.allInclusive;

        this._interval = setInterval(() => this._changeDetector.detectChanges(), 250) as any;
    }

    ngOnDestroy() {
        clearInterval(this._interval);
    }

    refresh() {
        return true;
    }

    private masterTotals() {

        const pnls = this.comp.hedgeMatrixDataService.getHedges(this.comp.selectedPortfolio)
            .map(x => {

                const hedgeCost = this.comp.hedgesPricingService.getHedgeGroupCost(x.id);

                const transCost = this.comp.hedgeMatrixDataService.getTransCostAsOwned(
                    this.comp.selectedPortfolio,
                    x.id
                );

                if (!isValidNumber(hedgeCost) && !isValidNumber(transCost)) {
                    return null;
                }

                const sum = (hedgeCost || 0) + (transCost || 0);
                return sum;
            })

        const sum = pnls.reduce((a, b) => a + b, 0);

        return sum;
    }

    private sideTotals() {
        const pnls = this.comp.hedgeMatrixDataService
            .getHedges(this.comp.selectedPortfolio)
            .filter(x => x.type === this.side)
            .map(x => {
                const id = x.id;
                let result: number | undefined;

                const hedgePriceWithTrans = this.comp.getHedgePriceWithTrans(x);
                return hedgePriceWithTrans
            });

        const sum = pnls.reduce((a,b) => a+b, 0);
        return sum;
    }

    private expirationSideTotals() {
        const hedgeIds = this.comp
            .hedgeMatrixDataService
            .getHedges(this.comp.selectedPortfolio)
            .filter(x => {
                const hasExpiration = this.comp.hedgeMatrixDataService
                    .getHedgeExpirations(this.comp.selectedPortfolio,x.id)
                    .indexOf(this.expiration)>=0;
                return hasExpiration;
            })
            .filter(x => this.side === x.type);

        const pnls = hedgeIds.map(x => {
            const originalCost = this.comp.hedgeMatrixDataService
                .getOriginalCost(this.comp.selectedPortfolio,x.id, this.expiration) || 0;

            const transCost = this.comp.hedgeMatrixDataService
                .getTransCostAsOwned(this.comp.selectedPortfolio,x.id, this.expiration) || 0;

            const sum = originalCost + transCost;

            return sum;
        });

        const sum = pnls.reduce((a, b) => a + b, 0);

        return sum;
    }

    private expirationTotals() {
        const hedgeIds = this.comp
            .hedgeMatrixDataService
            .getHedges(this.comp.selectedPortfolio)
            .filter(x => {
                const hasExpiration = this.comp.hedgeMatrixDataService
                    .getHedgeExpirations(this.comp.selectedPortfolio, x.id)
                    .indexOf(this.expiration)>=0;
                return hasExpiration;
            });

        const pnls = hedgeIds.map(x => {

            const originalCost = this.comp.hedgeMatrixDataService.getOriginalCost(
                this.comp.selectedPortfolio,
                x.id,
                this.expiration
            ) || 0;

            const transCost = this.comp.hedgeMatrixDataService
                .getTransCostAsOwned(
                    this.comp.selectedPortfolio,
                    x.id, this.expiration
                ) || 0;

            const sum = originalCost + transCost;

            return sum;
        });

        const sum = pnls.reduce((a, b) => a + b, 0);

        return sum;
    }
}