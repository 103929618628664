import {AfterViewInit, ChangeDetectorRef, Component, ViewChild, ViewContainerRef} from '@angular/core';
import {ICellEditorAngularComp} from "ag-grid-angular";
import {GridApi, ICellEditorParams} from "ag-grid-community";
import {DetectMethodChanges, DxValueChanged, isValidNumber, isVoid} from "../../../utils";
import {ToastrService} from "ngx-toastr";
import {OptionExpirationDescriptor} from "../../../shell-communication/shell-dto-protocol";
import {OptionsChainService} from "../../../option-chains.service";
import {HedgeMatrixRow, HgHedgeMatrixComponent} from "../hg-hedge-matrix.component";
import {ApgDataService} from "../../../adjustment-pricing-grid/services/apg-data.service";
import {HedgeData} from "../hedge-data";
import {getExpirationCellDataIndex} from "../hedge-matrix-grid-options";
import { DxPopupComponent} from "devextreme-angular";
import {HedgePosition} from "../../data-model/hedge-position";
import {HedgeMatrixCellData} from "../hedge-matrix-cell.data";

type CustomParams = {
    etsComponent: HgHedgeMatrixComponent;
    fieldEdited: 'transQty' | 'outcomeQty'
}

class HedgeLegWrapper {
    constructor(private readonly _hedgeLeg: HedgePosition) {
    }

    isSelected = false;

    get originalLeg(): HedgePosition {
        return this._hedgeLeg;
    }

    get ticker(): string {
        return this._hedgeLeg.ticker;
    }

    get strike(): number {
        return this._hedgeLeg.strike;
    }

    get expiration(): string {
        return this._hedgeLeg.expiration;
    }

    get qty(): number {
        return this._hedgeLeg.qty;
    }
}

@Component({
    selector: 'ets-hg-matrix-punch-pad',
    templateUrl: './hg-matrix-punch-pad.component.html',
    styleUrls: ['./hg-matrix-punch-pad.component.scss'],
})
export class HgMatrixPunchPadComponent implements ICellEditorAngularComp, AfterViewInit {

    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _toastr: ToastrService,
        private readonly _optionsChainService: OptionsChainService,
        private readonly _apgDataService: ApgDataService
    ) {
    }

    private _params: { api?: GridApi, etsComponent?: HgHedgeMatrixComponent } = {};

    private _originalQty: number;
    private _originalExpiration: string;
    private _fieldEdited: 'transQty' | 'outcomeQty';

    @ViewChild(DxPopupComponent) popupCmp: DxPopupComponent;

    selectedExpiration: OptionExpirationDescriptor;

    expirationList: OptionExpirationDescriptor[] = [];

    side: 'Long' | 'Short';

    qty: number;

    closeExisting: boolean = false;

    isCancel = false;

    hedgeData: HedgeData;

    legs: HedgeLegWrapper[] = [];

    cellData: HedgeMatrixCellData;

    title: string;

    get isAllLegsSelected(): boolean {
        return !isVoid(this.legs) && this.legs.every(x => x.isSelected);
    }

    get showRollCheckbox(): boolean {
        return !isVoid(this._originalExpiration)
            && this._fieldEdited !== 'transQty'
            && this.selectedExpiration?.optionExpirationDate !== this._originalExpiration;
    }

    // don't use afterGuiAttached for post gui events - hook into ngAfterViewInit instead for this
    ngAfterViewInit() {

    }

    agInit(params: ICellEditorParams & CustomParams): void {

        this._originalQty = undefined;
        this._fieldEdited = params.fieldEdited;
        this.qty = undefined;
        this.selectedExpiration = undefined;
        this.expirationList = [];
        this.side = undefined;

        if (isValidNumber(params.value)) {
            this._originalQty = params.value;
            if (params.value < 0) {
                this.side = 'Short';
            } else if (params.value > 0) {
                this.side = 'Long';
            }
        }

        this.qty = this._originalQty;

        this._params = params;

        this._apgDataService.getUnderlyingOfPortfolio(
            this._params.etsComponent.selectedPortfolio
        ).then((data) => {
            return this._optionsChainService.getChain(data);
        }).then(data => {

            this.expirationList = data.expirations;

            const hedgeData = params.colDef['ets-data'] as HedgeData;

            if (isVoid(hedgeData)) {
                throw new Error('Hedge data not defined');
            }

            this.hedgeData = hedgeData;

            const hedgeId = hedgeData.id;

            const rowData = params.data as HedgeMatrixRow;

            if (isVoid(rowData)) {
                throw new Error('Row data not defined');
            }

            let expiration: string;

            const cellData = this._params.etsComponent.hedgeMatrixDataService.getCellData(
                this._params.etsComponent.selectedPortfolio,
                rowData.strike,
                hedgeId,
            );

            if (!isVoid(cellData)) {

                const expirationCellDataIndex = getExpirationCellDataIndex(
                    rowData.strike,
                    params.api,
                    params.node
                );

                const expirationCellData = cellData[expirationCellDataIndex];
                this.cellData = expirationCellData;

                if (isVoid(expirationCellData)) {
                    let defaultExpiration = this._params.etsComponent.hedgeMatrixDataService
                        .getHedgeDefaultExpiration(this._params.etsComponent.selectedPortfolio, hedgeId);
                    expiration = defaultExpiration;
                } else {
                    expiration = expirationCellData.expiration;

                    this._originalExpiration = expiration;
                }
            } else {
                let defaultExpiration = this._params.etsComponent.hedgeMatrixDataService
                    .getHedgeDefaultExpiration(
                        this._params.etsComponent.selectedPortfolio,
                        hedgeId
                    );
                expiration = defaultExpiration;
            }

            const optionExpirationDescriptor = this.expirationList
                .find(exp => exp.optionExpirationDate === expiration)
                            || this.expirationList[0];

            this.selectedExpiration = optionExpirationDescriptor;

            this.setTitle();

            this.popupCmp.visible = true;

            this._changeDetector.detectChanges();
        });
    }

    getValue(): any {
        const expiration = this.selectedExpiration?.optionExpirationDate || this._originalExpiration;
        const qty = this.qty;
        const closeExisting = this.closeExisting;
        const isCancel = this.isCancel;

        if (closeExisting) {
            const otherLegs = this.legs.filter(x => x.ticker !== this.cellData.ticker);
            this._params.etsComponent.rollOtherLegs(expiration,otherLegs.map(x => x.originalLeg), this.hedgeData);
        }

        return {
            expiration,
            qty,
            closeExisting,
            isCancel
        };
    }

    isPopup(): boolean {
        return true;
    }

    onQtySelected(value: number) {
        if (isVoid(this.side)) {
            this._toastr.error('Trade Side Not Selected');
            return;
        }

        if (this.side === 'Short') {
            value *= -1;
        }

        this.qty = value;

        this._params.api.stopEditing();
    }

    getPopupPosition() {
        return 'under';
    }

    @DetectMethodChanges()
    setSide(side: 'Long' | 'Short') {
        this.side = side;
        if (side === 'Short') {
            this.qty = Math.abs(this.qty) * -1;
        } else if (side === 'Long') {
            this.qty = Math.abs(this.qty);
        }
    }

    @DetectMethodChanges()
    onMinusClicked(num: number) {
        const current = isValidNumber(this.qty) ? this.qty : 0;
        const desired = current - num;
        this.qty = desired;
        this.updateSide();
    }

    @DetectMethodChanges()
    onPlusClicked(num: number) {
        const current = isValidNumber(this.qty) ? this.qty : 0;
        const desired = current + num;
        this.qty = desired;
        this.updateSide();
    }

    onApplyClicked() {
        this._params.api.stopEditing();
    }

    onCancelClicked() {
        this.qty = this._originalQty;
        this.selectedExpiration = null;
        this.isCancel = true;
        this._params.api.stopEditing();
    }

    @DetectMethodChanges()
    reversePosition() {
        if (!isValidNumber(this.qty, true)) {
            return;
        }
        this.qty = this.qty * -1;
        this.updateSide();
    }

    @DetectMethodChanges()
    closePosition() {
        if (!isValidNumber(this.qty, true)) {
            return;
        }
        this.qty = undefined;
        this.updateSide();
    }

    @DetectMethodChanges()
    doublePosition() {
        if (!isValidNumber(this.qty, true)) {
            return;
        }
        this.qty = this.qty * 2;
        this.updateSide();
    }

    @DetectMethodChanges()
    halfPosition() {
        if (!isValidNumber(this.qty, true)) {
            return;
        }
        this.qty = Math.floor(this.qty / 2);
        this.updateSide();
    }

    @DetectMethodChanges()
    updateSide() {
        if (this.qty > 0) {
            this.side = 'Long';
        } else if (this.qty < 0) {
            this.side = 'Short';
        } else {
            this.side = undefined;
        }
    }

    onQtyChanged(args: DxValueChanged<number>) {
        if (isVoid(args.event)) {
            return;
        }
        this.updateSide();
    }

    @DetectMethodChanges()
    onExpirationChanged($event: any) {

    }

    @DetectMethodChanges()
    onHidden() {
        this.onCancelClicked();
    }

    @DetectMethodChanges()
    onRollChanged() {
        if (!this.closeExisting) {
            this.legs = [];
            return;
        }

        this.legs = this.hedgeData.legs.map(x => new HedgeLegWrapper(x));

        const selectedLeg = this.legs.find(x => x.ticker === this.cellData.ticker);

        if (!isVoid(selectedLeg)) {
            selectedLeg.isSelected = true;
        }

    }

    setTitle() {
        let qty: number;

        switch (this._fieldEdited) {
            case "transQty":
                qty = this.cellData.transQty;
                break;
            case "outcomeQty":
                qty = this.cellData.outcomeQty;
                break;
            default:
                qty = this.cellData.qty;
        }

        let title = `${this.hedgeData?.name} - ${this.cellData?.strike}`;

        if (isValidNumber(qty)) {
            const sign = qty > 0 ? '+' : '';
            title += ` (${sign}${qty})`;
        }

        this.title = title;
    }

    @DetectMethodChanges()
    toggleSelectAllLegs() {
        const sum = this.legs.map(x => x.isSelected ? 1 : 0).reduce((a, b) => a+b, 0);
        if (sum !== this.legs.length) {
            this.legs.forEach(x => x.isSelected = true);
        } else {
            this.legs
                .filter(x => x.ticker !== this.cellData?.ticker)
                .forEach(x => x.isSelected = false);
        }
    }
}