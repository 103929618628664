import {makeOptionTicker, OptionType, parseOptionTicker} from "../../options-common/options.model";
import {PricingGridLeg} from "./pricing-grid.leg";
import {MarketSide} from "../../trading-model/market-side.enum";
import {daysToExpiration, findHCF, isNullOrUndefined, isVoid, makeDayOfWeekDate} from "../../utils";
import {PricingGridStrategy} from "./pricing-grid.strategy";
import {PricingGridRow} from "./pricing-grid.row";
import * as Enumerable from "linq";

export class PricingGridStrategyColumn {

    constructor(
        public readonly gridRow: PricingGridRow,
        public readonly optionType: OptionType,
        public strategy: PricingGridStrategy
    ) {
        this.setupColumn();
    }

    private _legs: PricingGridLeg[] = [];
    private _transLegs: PricingGridLeg[] = [];
    private _ownLegs: PricingGridLeg[] = [];

    private _tooltip: string;
    private _strategyCode: string;
    private _tickers: string[] = [];
    private _spreadWidth: number;
    private _columnId: string;

    edgeStrike: number;
    payForIt = false;
    rMultiple = false;
    percentageProfit = false;
    highlightColor = undefined;

    price: number;

    // baseLegMultiplier = 1;
    hcfMultiplier: number = 1;

    get tooltip(): string {
        return this._tooltip;
    }

    get legs(): PricingGridLeg[] {
        return this._legs;
    }

    get ownLegs(): PricingGridLeg[] {
        return this._ownLegs;
    }

    get transLegs(): PricingGridLeg[] {
        return this._transLegs;
    }

    get spreadWidth(): number {
        return this._spreadWidth;
    }

    get tickers(): string[] {
        return this._tickers;
    }

    get strategyCode(): string {
        return this._strategyCode;
    }

    get columnId(): string {
        return this._columnId;
    }

    setStrategy(str: PricingGridStrategy) {
        this.strategy = str;
        this.price = undefined;
        this.setupColumn();
    }

    onExpirationChanged() {
        this.price = undefined;
        this.setupColumn();
    }


    private setupColumn() {
        if (this.gridRow.gridSettings.mode === 'opg') {
            this.setupColumnForOpgMode();
        } else if (this.gridRow.gridSettings.mode === 'pmd') {
            this.setupColumnForPmdMode();
        }
    }

    private setupColumnForOpgMode() {

        const innerLegs: PricingGridLeg[] = [];

        let lastStrike = this.gridRow.rowStrike;

        let strategyLegs = this.strategy.strategyLegs?.slice() || [];


        if (strategyLegs.length > 0) {
            if (this.optionType == OptionType.Call) {
                strategyLegs.reverse();
            }
        } else {
            return;
        }

        strategyLegs.forEach((leg, ix, arr) => {

            if (leg.type === 'width') {
                return;
            }

            let strike = lastStrike;

            let spreadWidth = 0;

            if (ix > 0) {
                const widthIx = ix - 1;

                if (widthIx < arr.length) {
                    const widthLeg = arr[widthIx];
                    if (widthLeg.width) {
                        spreadWidth = widthLeg.width;
                    }
                }

                if (this.optionType === OptionType.Call) {
                    strike = lastStrike + spreadWidth;
                } else if (this.optionType === OptionType.Put) {
                    strike = lastStrike - spreadWidth;
                }
            }

            const legExpirationDescriptor = this.gridRow.gridSettings.findExpirationOffset(leg.expirationOffset);

            if (isVoid(legExpirationDescriptor)) {
                throw new Error('expiration not found');
            }

            const contractTicker = makeOptionTicker(legExpirationDescriptor, this.optionType, strike, 'American');

            const innerLeg: PricingGridLeg = {
                ticker: contractTicker,
                side: leg.side === 'Buy' ? MarketSide.Buy : MarketSide.Sell,
                qty: leg.qty * this.gridRow.gridSettings.defaultQty,
                strike
            };

            innerLegs.push(innerLeg);

            lastStrike = strike;

            this.edgeStrike = lastStrike;

        });

        this._legs = innerLegs;

        if (this.gridRow.gridSettings.mode === 'opg') {
            this.buildTickerFields(innerLegs);
        }
    }

    private buildTickerFields(legs: PricingGridLeg[]) {
        this._columnId = this.makeColumnId();
        this._tooltip = this.makeTooltip(legs);
        this._strategyCode = this.makeStrategyCode(legs);
        this._tickers = this.makeTickers(legs);
        this._spreadWidth = this.makeSpreadWidth(legs);
    }

    private setupColumnForPmdMode() {

        this.setupColumnForOpgMode();

        const hedges = this.gridRow.gridSettings
            .getExistingHedges(this.optionType);

        const currentState = hedges
            .map(hp => {
                return {
                    ticker: hp.ticker,
                    qty: hp.qty
                }
            });

        const ownLegs = currentState.map(x => {
            const optionTicker = parseOptionTicker(x.ticker);
            return {
                ticker: x.ticker,
                qty: Math.abs(x.qty),
                side: x.qty > 0 ? MarketSide.Buy : MarketSide.Sell,
                strike: optionTicker?.strike
            } as PricingGridLeg
        });
        this._ownLegs = ownLegs;

        const desiredState = this._legs.map(l => {
            return {
                ticker: l.ticker,
                qty: l.qty * l.side
            }
        });

        const toOpen = desiredState.filter(x => {
            const ix = currentState.findIndex(y => y.ticker === x.ticker);
            return ix < 0;
        });

        const toClose = currentState.filter(hp => {
            const ix = desiredState.findIndex(op => op.ticker === hp.ticker);
            return ix < 0;
        });

        const changedLegs = currentState.concat(desiredState)
            .filter(tp => toOpen.indexOf(tp) < 0 && toClose.indexOf(tp) < 0);

        const toChange = Enumerable.from(changedLegs)
            .groupBy(x => x.ticker)
            .select(grp => {

                const beforeLeg = currentState
                    .find(x => x.ticker === grp.key());

                const afterLeg = desiredState
                    .find(x => x.ticker === grp.key());

                const diff = afterLeg.qty - beforeLeg.qty;

                return {
                    ticker: grp.key(),
                    qty: diff
                }

            })
            .where(x => x.qty !== 0)
            .toArray();

        toClose.forEach(l => l.qty *= -1);

        const allLegs = toOpen.concat(toClose).concat(toChange);

        const transLegs = Enumerable.from(allLegs)
            .select(x => {
                const optionTicker = parseOptionTicker(x.ticker);
                return {
                    ticker: x.ticker,
                    qty: Math.abs(x.qty),
                    side: x.qty > 0 ? MarketSide.Buy : MarketSide.Sell,
                    strike: optionTicker?.strike
                } as PricingGridLeg
            })
            .toArray();

        this._transLegs = transLegs;

        const transHcf = findHCF(transLegs.map(x => x.qty));

        const hcfMultiplier = transHcf / this.gridRow.gridSettings.defaultQty;

        this.hcfMultiplier = hcfMultiplier;

        if (!this.strategy.isDirty) {
            this.buildTickerFields(transLegs);
        }
    }


    private makeColumnId(): string {
        const colId = `pricing-${this.strategy.strategyId}-${OptionType[this.optionType].toLowerCase()}`;
        return colId;
    }


    private makeTickers(innerLegs: PricingGridLeg[]): string[] {
        return innerLegs.map(x => x.ticker).filter(x => !isNullOrUndefined(x));
    }


    private makeStrategyCode(innerLegs: PricingGridLeg[]): string {

        const codes = innerLegs
            .sort((a, b) => a.strike - b.strike)
            .map(x => {
                let qty = x.qty * x.side;

                const code = `${qty}|${x.ticker}`;

                return code;
            });

        const strategyCode = codes.join(';');

        if (this.gridRow.gridSettings.mode === 'opg') {
            const hcf = findHCF(innerLegs.map(x => x.qty));
            this.hcfMultiplier = hcf / this.gridRow.gridSettings.defaultQty;
        }

        if (!isNullOrUndefined(strategyCode)) {

            const slp = this.gridRow.gridSettings.lastQuoteCache.getLastStrategyPrice(strategyCode);

            if (!isNullOrUndefined(slp)) {
                this.price = slp.price;

                if (this.gridRow.gridSettings.mode === 'opg') {
                    this.price *= this.hcfMultiplier;
                }
            }
        }

        return strategyCode;
    }


    private makeTooltip(legs: PricingGridLeg[]): string {
        let orderedLegs = Enumerable.from(legs);

        if (this.optionType === OptionType.Call) {
            orderedLegs = orderedLegs.orderBy(x => x.strike);
        } else {
            orderedLegs = orderedLegs.orderByDescending(x => x.strike);
        }

        const expirationsCount = orderedLegs
            .select(l => parseOptionTicker(l.ticker))
            .select(x => x.expiration)
            .where(x => !isVoid(x))
            .distinct()
            .count() || 0;

        const strikes = orderedLegs.select((leg) => {
            const parts = leg.ticker?.split(' ') || [];
            if (parts.length < 4) {
                return '0';
            }
            const sign = leg.side === MarketSide.Buy ? '+' : '-';

            let tooltip;
            if (expirationsCount === 1) {
                tooltip = leg.qty === 1 ? `${sign}${parts[3]}` : `${sign}(${leg.qty})${parts[3]}`;
            } else {
                const optionTicker = parseOptionTicker(leg.ticker);
                const dte = daysToExpiration(optionTicker.expiration);
                const dow = makeDayOfWeekDate(optionTicker.expiration, true);
                tooltip = leg.qty === 1 ? `${sign}${parts[3]}[${dow},${dte}d]` : `${sign}(${leg.qty})${parts[3]}[${dow},${dte}d]`;
            }

            return tooltip;
        }).toArray();

        return strikes.join(',');
    }


    private makeSpreadWidth(innerLegs: PricingGridLeg[]): number {
        if (innerLegs.length < 2) {
            return undefined;
        }

        const spreadWidth = Math.abs(innerLegs[0].strike - innerLegs[1].strike);
        return spreadWidth;
    }

    resetPmdState() {
        this._legs = [];
        this._transLegs = [];
        this._ownLegs = [];
    }
}