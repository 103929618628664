import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DetectMethodChanges} from "../../../utils";

type SlideCallback = (direction: 'Up' | 'Down') => void;

@Component({
    selector: 'ets-hedge-matrix-slide-hedge-dialog',
    templateUrl: 'slide-hedge-dialog.component.html',
    styleUrls: ['slide-hedge-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class HedgeMatrixSlideHedgeDialog implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
    ) {
    }

    private _callback: SlideCallback;

    visible = false;

    ngOnInit() {
    }

    @DetectMethodChanges()
    show(callback: SlideCallback) {
        this._callback = callback;
        this.visible = true;
    }

    slideUp() {
        this._callback('Up');
    }

    slideDown() {
        this._callback('Down');
    }

    @DetectMethodChanges()
    onHidden() {
        this.visible = false;
    }
}