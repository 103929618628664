<dx-button (onClick)="this.showPopup()" width="100%" height="100%" text="Pivots" class="action-btn"></dx-button>

<dx-popup
  title="Pivot Points"
  [closeOnOutsideClick]="false"
  [resizeEnabled]="true"
  [shading]="true"
  [width]="920"
  [height]="450"
  (onHidden)="this.onHidden()"
>
  <div *dxTemplate="let data of 'content'" class="content">
    <div #root class="root">
      <div class="column up">
        <div class="title up">Trend - "UP"</div>
        <div class="row close">
          <ng-container *ngTemplateOutlet="table; context: {$implicit: this.upTrendClose}"></ng-container>
        </div>
        <div class="row wick">
          <ng-container *ngTemplateOutlet="table; context: {$implicit: this.upTrendWicks}"></ng-container>
        </div>
      </div>
      <div class="column down">
        <div class="title down">Trend - "DOWN"</div>
        <div class="row close">
          <ng-container *ngTemplateOutlet="table; context: {$implicit: this.downTrendClose}"></ng-container>
        </div>
        <div class="row wick">
          <ng-container *ngTemplateOutlet="table; context: {$implicit: this.downTrendWicks}"></ng-container>
        </div>
      </div>
      <div class="column recent">
        <div class="title">Most Recent</div>
        <div class="row close">
          <ng-container *ngTemplateOutlet="table; context: {$implicit: this.mostRecentClose}"></ng-container>
        </div>
        <div class="row wick">
          <ng-container *ngTemplateOutlet="table; context: {$implicit: this.mostRecentWicks}"></ng-container>
        </div>
      </div>
    </div>
    <dx-load-panel [position]="{my: 'center', at: 'center', of: root}" [visible]="this.isLoading"></dx-load-panel>
  </div>
</dx-popup>

<ng-template #table let-dataObj>
  <table class="table">
    <thead class="header">
    <tr class="caption">
      <th colspan="3">
        {{dataObj?.header}}
      </th>
    </tr>
    <tr>
      <th>Timeframe</th>
      <th>Upper Pivot</th>
      <th>Lower Pivot</th>
    </tr>
    </thead>
    <tbody class="body">
    
<!--  1 min -->
    <tr>
      <td class="tf">1-minute</td>
      <td class="tf" #oneMinUpper>
        {{ dataObj?.oneMinRow?.upper?.pivotPoint | currency:'USD':'symbol':'0.0-0' }}
        <dx-tooltip [target]="oneMinUpper" showEvent="mouseenter" hideEvent="mouseleave">
          <div *dxTemplate="let data = data; of: 'content'">
            {{dataObj?.oneMinRow?.upper?.pivotPoint}}
            <br/>
            {{dataObj?.oneMinRow?.upper?.barTime}}
          </div>
        </dx-tooltip>
      </td>
      <td class="tf" #oneMinLower>
        {{ dataObj?.oneMinRow?.lower?.pivotPoint | currency:'USD':'symbol':'0.0-0' }}
        <dx-tooltip [target]="oneMinLower" showEvent="mouseenter" hideEvent="mouseleave">
          <div *dxTemplate="let data = data; of: 'content'">
            {{dataObj?.oneMinRow?.lower?.pivotPoint}}
            <br/>
            {{dataObj?.oneMinRow?.lower?.barTime}}
          </div>
        </dx-tooltip>
      </td>
    </tr>
    
<!-- 5 min -->
    <tr class="alter">
      <td class="tf">5-minute</td>
      <td class="tf" #fiveMinUpper>
        {{ dataObj?.fiveMinRow?.upper?.pivotPoint | currency:'USD':'symbol':'0.0-0' }}
        <dx-tooltip [target]="fiveMinUpper" showEvent="mouseenter" hideEvent="mouseleave">
          <div *dxTemplate="let data = data; of: 'content'">
            {{dataObj?.fiveMinRow?.upper?.pivotPoint}}
            <br/>
            {{dataObj?.fiveMinRow?.upper?.barTime}}
          </div>
        </dx-tooltip>
      </td>
      <td class="tf" #fiveMinLower>
        {{ dataObj?.fiveMinRow?.lower?.pivotPoint | currency:'USD':'symbol':'0.0-0' }}
        <dx-tooltip [target]="fiveMinLower" showEvent="mouseenter" hideEvent="mouseleave">
          <div *dxTemplate="let data = data; of: 'content'">
            {{dataObj?.fiveMinRow?.lower?.pivotPoint}}
            <br/>
            {{dataObj?.fiveMinRow?.lower?.barTime}}
          </div>
        </dx-tooltip>
      </td>
    </tr>

<!-- 10 min -->
    <tr>
      
      <td class="tf">10-minute</td>
      
      <td class="tf" #tenMinuteUpper>
        {{ dataObj?.tenMinRow?.upper?.pivotPoint | currency:'USD':'symbol':'0.0-0' }}
        <dx-tooltip [target]="tenMinuteUpper" showEvent="mouseenter" hideEvent="mouseleave">
          <div *dxTemplate="let data = data; of: 'content'">
            {{dataObj?.tenMinRow?.upper?.pivotPoint}}
            <br/>
            {{dataObj?.tenMinRow?.upper?.barTime}}
          </div>
        </dx-tooltip>
      </td>
      
      <td class="tf" #tenMinuteLower>
        {{ dataObj?.tenMinRow?.lower?.pivotPoint | currency:'USD':'symbol':'0.0-0' }}
        <dx-tooltip [target]="tenMinuteLower" showEvent="mouseenter" hideEvent="mouseleave">
          <div *dxTemplate="let data = data; of: 'content'">
            {{dataObj?.tenMinRow?.lower?.pivotPoint}}
            <br/>
            {{dataObj?.tenMinRow?.lower?.barTime}}
          </div>
        </dx-tooltip>
      </td>
      
    </tr>

<!-- 15 min -->
    <tr class="alter">
      
      <td class="tf">15-minute</td>
      
      <td class="tf" #fifteenMinUpper>
        {{ dataObj?.fifteenMinRow?.upper?.pivotPoint | currency:'USD':'symbol':'0.0-0' }}
        <dx-tooltip [target]="fifteenMinUpper" showEvent="mouseenter" hideEvent="mouseleave">
          <div *dxTemplate="let data = data; of: 'content'">
            {{dataObj?.fifteenMinRow?.upper?.pivotPoint}}
            <br/>
            {{dataObj?.fifteenMinRow?.upper?.barTime}}
          </div>
        </dx-tooltip>
      </td>
      
      <td class="tf" #fifteenMinLower>
        {{ dataObj?.fifteenMinRow?.lower?.pivotPoint | currency:'USD':'symbol':'0.0-0' }}
        <dx-tooltip [target]="fifteenMinLower" showEvent="mouseenter" hideEvent="mouseleave">
          <div *dxTemplate="let data = data; of: 'content'">
            {{dataObj?.fifteenMinRow?.lower?.pivotPoint}}
            <br/>
            {{dataObj?.fifteenMinRow?.lower?.barTime}}
          </div>
        </dx-tooltip>
      </td>
      
    </tr>
    </tbody>
    
  </table>
</ng-template>