import {NgModule} from '@angular/core';

import {HedgingGridComponent} from './hedging-grid.component';
import {
    HgPortfolioPositionsComponent
} from "./positions-section/portfolio-positions/hg-portfolio-positions.component";
import {HgHedgePositionsComponent} from "./positions-section/hedge-positions/hg-hedge-positions.component";
import {HgPositionsSectionComponent} from "./positions-section/hg-positions-section.component";
import {HgSettingsSectionComponent} from "./settings-section/hg-settings-section.component";
import {HgStrategiesSectionComponent} from "./strategies-section/hg-strategies-section.component";
import {DevExtremeModule} from "../devextreme.module";
import {HgPriceboxSectionComponent} from "./pricebox-section/hg-pricebox-section.component";
import {HgZonesGridSectionComponent} from "./zones-grid-section/hg-zones-grid-section.component";
import {CommonModule} from "@angular/common";
import {HedgingGridPanelComponent} from "./hedging-grid-panel.component";
import {HgPortfolioSelectorComponent} from "./positions-section/portfolio-selector/hg-portfolio-selector.component";
import {HgSectionHeaderComponent} from "./section-header/hg-section-header.component";
import {EtsSymbolPickerModule} from "../symbol-picker/symbol-picker.module";
import {OptionsCommonModule} from "../options-common/options-common.module";
import {
    CashFlowStrategyTemplatesService
} from "../adjustment-pricing-grid/services/cashflow-strategy-templates.service";
import {CashFlowPortfoliosService} from "../adjustment-pricing-grid/services/cashflow-portfolios.service";
import {SavedPositionsService} from "../adjustment-pricing-grid/services/saved-positions.service";
import {HedgePositionsService} from "./positions-section/hedge-positions/hedge-positions.service";
import {HgTransactionPopupComponent} from "./transaction-popup/hg-transaction-popup.component";
import {FriendlyDatePipe} from "../common-services/friendly-date.pipe";
import {HgAfterStatePreviewComponent} from "./positions-section/after-state-preview/hg-after-state-preview.component";
import {MarketSidePipe} from "./market-side.pipe";
import {HgOptionStrategyService} from "./hg-option-strategy.service";
import {ColorPickerModule} from "../color-picker/color-picker.component.module";
import {
    HgZonesGridColumnSelectorComponent
} from "./zones-grid-section/hg-zones-grid-column-selector/hg-zones-grid-column-selector.component";
import {
    HgZonesGridCalculatorComponent
} from "./zones-grid-section/hg-zones-grid-calculator/hg-zones-grid-calculator.component";
import {HgMergeDialogComponent} from "./settings-section/merge-dialog/hg-merge-dialog.component";
import {FriendlyTickerPipe} from "../common-services/friendly-ticker.pipe";
import {LastPriceModule} from "../last-price/last-price.module";
import {
    HgOriginalClientPositionsService
} from "./positions-section/portfolio-selector/hg-original-client-positions.service";
import {EtsCommonModule} from "../common-services/common.module";
import {
    HgHedgePositionsSyncDialogComponent
} from "./positions-section/hedge-positions-sync-dialog/hg-hedge-positions-sync-dialog.component";
import {HgZonesGridMenuComponent} from "./zones-grid-section/hg-zones-grid-menu/hg-zones-grid-menu.component";
import {
    HgZonesGridParametersComponent
} from "./zones-grid-section/hg-zones-grid-parameters/hg-zones-grid-parameters.component";
import {GenericConfirmationDialogModule} from "../generic-confirmation-dialog/generic-confirmation-dialog.module";
import {PositionsStateService} from "../adjustment-pricing-grid/services/positions-before-state.service";
import {ApgDataService} from "../adjustment-pricing-grid/services/apg-data.service";
import {HedgesSortingDialogComponent} from "./settings-section/sorting-dialog/hedges-sorting-dialog.component";
import {OrdersRegroupModule} from "../orders-regroup-dialog/orders-regroup.module";
import {OrdersChainDialogModule} from "../orders-chain-dialog/orders-chain-dialog.module";
import {HgHedgeMatrixComponent} from "./hedge-matrix/hg-hedge-matrix.component";
import {AgGridModule} from "ag-grid-angular";
import {HedgeMatrixDataService} from "./hedge-matrix/hedge-matrix-data.service";
import {
    HgHedgeMatrixHedgesSelectorComponent
} from "./hedge-matrix/hg-hedge-matrix-hedges-selector/hg-hedge-matrix-hedges-selector.component";
import {HedgeMatrixCustomColumnHeaderGroup} from "./hedge-matrix/hedge-matrix-custom-colgroup-header";
import {SettlementPriceService} from "./settlement-price.service";
import {HgMatrixPunchPadComponent} from "./hedge-matrix/punch-pad/hg-matrix-punch-pad.component";
import {HedgeExpirationCellEditorComponent} from "./hedge-matrix/hedge-expiration-cell-editor.component";
import {MultiExpirationCellRenderer} from "./hedge-matrix/multi-expiration-cell-renderer.component";
import {HedgeMatrixSlideHedgeDialog} from "./hedge-matrix/slide-hedge/slide-hedge-dialog.component";
import {HedgePocketComponent} from "./hedge-matrix/hege-pocket/hedge-pocket.component";

@NgModule({
    imports: [
        CommonModule,
        EtsCommonModule,
        DevExtremeModule,
        EtsSymbolPickerModule,
        OptionsCommonModule,
        ColorPickerModule,
        LastPriceModule,
        GenericConfirmationDialogModule,
        OrdersRegroupModule,
        OrdersChainDialogModule,
        AgGridModule
    ],
    declarations: [
        HedgingGridPanelComponent,
        HedgingGridComponent,
        HgHedgePositionsComponent,
        HgPortfolioPositionsComponent,
        HgPositionsSectionComponent,
        HgSettingsSectionComponent,
        HgStrategiesSectionComponent,
        HgPriceboxSectionComponent,
        HgZonesGridSectionComponent,
        HgPortfolioSelectorComponent,
        HgSectionHeaderComponent,
        HgTransactionPopupComponent,
        HgAfterStatePreviewComponent,
        MarketSidePipe,
        HgZonesGridParametersComponent,
        HgZonesGridColumnSelectorComponent,
        HgZonesGridCalculatorComponent,
        HgMergeDialogComponent,
        HgHedgePositionsSyncDialogComponent,
        HgZonesGridMenuComponent,
        HedgesSortingDialogComponent,
        HgHedgeMatrixComponent,
        HgHedgeMatrixHedgesSelectorComponent,
        HedgeMatrixCustomColumnHeaderGroup,
        HgMatrixPunchPadComponent,
        HedgeExpirationCellEditorComponent,
        MultiExpirationCellRenderer,
        HedgeMatrixSlideHedgeDialog,
        HedgePocketComponent
    ],
    exports: [
        HedgingGridPanelComponent,
        HgTransactionPopupComponent,
        MarketSidePipe,
        HgHedgeMatrixComponent
    ],
    providers: [
        CashFlowStrategyTemplatesService,
        CashFlowPortfoliosService,
        SavedPositionsService,
        HedgePositionsService,
        ApgDataService,
        HgOptionStrategyService,
        HgOriginalClientPositionsService,
        PositionsStateService,
        HedgeMatrixDataService,
        SettlementPriceService
    ],
})
export class HedgingGridModule {
}
