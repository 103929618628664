import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {DetectMethodChanges, isVoid, makePortfolioKey} from "../../../utils";
import {ApgPortfolio} from "../../../adjustment-pricing-grid/model/ApgPortfolio";
import * as Enumerable from "linq";
import {OptionPricingGridTemplatesService} from "../../../options-pricing-grid/option-pricing-grid-templates.service";
import {DxTreeViewComponent} from "devextreme-angular";
import {ToastrService} from "ngx-toastr";
import {UserSettingsService} from "../../../user-settings.service";

export const hedgePocketStorageKeyResolver = (portfolio: ApgPortfolio) => {
    const pfKey = makePortfolioKey(portfolio);
    return `hg.hedge-pocket.${pfKey}`
}

@Component({
    selector: 'ets-hedge-pocket',
    template: `
      <dx-popup [visible]="this.visible"
                width="20vw"
                title="Hedge Pocket" (onHidden)="this.onHidden()"
                height="55vh"
      >
        <div *dxTemplate="let data of 'content'" #view class="root">
          <div class="tree">
            <dx-scroll-view>
              <dx-tree-view #tree
                            [selectionMode]="'multiple'"
                            [selectByClick]="true"
                            [selectNodesRecursive]="true"
                            [showCheckBoxesMode]="'normal'"
                            [items]="this.treeItems"
                            (onItemSelectionChanged)="this.onTreeSelectionChanged($event)"
              ></dx-tree-view>
            </dx-scroll-view>
          </div>
          <div class="buttons">
            <div class="btn">
              <dx-button text="Save" width="100%" (onClick)="this.saveSettings(tree)"></dx-button>
            </div>
            <div class="btn">
              <dx-button text="Cancel" width="100%" (onClick)="this.cancelClicked()"></dx-button>
            </div>
          </div>
        </div>
      </dx-popup>`,
    styles: [
        `
            .root {
                display: flex;
                flex-direction: column;
                row-gap: 10px;
            }
            
            .tree {
                flex: 1;
                overflow: hidden;
            }
            
            .buttons {
                display: flex;
                column-gap: 30px;
            }

            .buttons .btn {
                flex: 1;
            }
        `
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})

export class HedgePocketComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _opgTemplatesService: OptionPricingGridTemplatesService,
        private readonly _toastr: ToastrService,
        private readonly _userSettingsService: UserSettingsService
    ) {
    }

    private _config: { portfolio: ApgPortfolio };

    visible = false;

    treeItems = [];

    ngOnInit() {
    }

    @DetectMethodChanges()
    show(config: { portfolio: ApgPortfolio }) {
        this._config = config;

        const callNodes = this.getHedgeNodes(config.portfolio, 'Call');

        const putNodes = this.getHedgeNodes(config.portfolio, 'Put');

        this.treeItems = [
            callNodes,
            putNodes
        ];

        const pfKey = makePortfolioKey(this._config.portfolio);
        const settings = this._userSettingsService.getValue<any>(`hg.hedge-pocket.${pfKey}`, this._config.portfolio.userId);
        if (!isVoid(settings)) {

            const hedgesCall = isVoid(settings.hedgesCall) ? [] : settings.hedgesCall;
            const hedgesPut = isVoid(settings.hedgesPut) ? [] : settings.hedgesPut;

            try {
                hedgesCall.forEach(callHedgeFav => {
                    const hedges = callNodes.items.flatMap(x => x.items);
                    const node = hedges.find(h => h.data.strategyId === callHedgeFav.strategyId);
                    if (!isVoid(node)) {
                        node.selected = true;
                    }
                });
            } catch  {
                //
            }

            try {
                hedgesPut.forEach(putHedgeFav => {
                    const hedges = putNodes.items.flatMap(x => x.items);
                    const node = hedges.find(h => h.data.strategyId === putHedgeFav.strategyId);
                    if (!isVoid(node)) {
                        node.selected = true;
                    }
                });
            } catch {
                //
            }
        }


        this.visible = true;
    }

    @DetectMethodChanges()
    onHidden() {
        this.visible = false;
        this.treeItems = [];
        this._config = null;
    }

    onTreeSelectionChanged($event: any) {

    }

    private getHedgeNodes(portfolio: ApgPortfolio, type: 'Call' | 'Put'): any {

        const userId = portfolio?.userId;

        const templates = type === 'Call'
            ? this._opgTemplatesService.getCallTemplates(userId, true)
            : this._opgTemplatesService.getPutTemplates(userId, true);

        const groups = Enumerable.from(templates)
            .select(x => {

                const id = x.templateId;

                const text = x.templateName;

                const items = Enumerable.from(x.descriptors)
                    .select(d => {
                        const id = d.strategyId;
                        const text = d.strategyName;
                        const data = {strategyName: d.strategyName, strategyId: d.strategyId};

                        return {
                            id,
                            text,
                            data,
                            type: 'hedge_' + type
                        };

                    }).toArray();

                return {
                    id,
                    text,
                    items
                };

            }).toArray();


        const text = type === 'Call' ? 'Call Hedges' : 'Put Hedges';

        const node = {
            id: 'hedges_' + text,
            text,
            items: groups
        };

        return node;
    }

    @DetectMethodChanges()
    saveSettings(tree: DxTreeViewComponent) {

        // Call Hedges
        const callHedgeNodes = tree.instance.getSelectedNodes()
            .map(x => x.itemData)
            .filter(x => x.type === 'hedge_Call');

        const distinctCallHedges = callHedgeNodes.map(x => x.text).filter((value, index, array) => {
            return array.indexOf(value) === index;
        });

        if (callHedgeNodes.length !== distinctCallHedges.length) {
            this._toastr.error('Hedges With Duplicate Names Are Not Allowed', 'Call Hedges');
            return;
        }

        // Put Hedges
        const putHedgeNodes = tree.instance.getSelectedNodes()
            .map(x => x.itemData)
            .filter(x => x.type === 'hedge_Put');

        const distinctPutHedges = putHedgeNodes
            .map(x => x.text).filter((value, index, array) => {
                return array.indexOf(value) === index;
            });

        if (putHedgeNodes.length !== distinctPutHedges.length) {
            this._toastr.error('Hedges With Duplicate Names Are Not Allowed', 'Put Hedges');
            return;
        }

        const hedgesCall = [];
        const hedgesPut = [];

        tree.instance.getSelectedNodes()
            .map(x => x.itemData)
            .filter(x => !isVoid(x.type) && !isVoid(x.data))
            .forEach(node => {
                if (node.type === 'hedge_Call') {
                    hedgesCall.push(node.data);
                } else if (node.type === 'hedge_Put') {
                    hedgesPut.push(node.data);
                }
            });


        const settings = {
            hedgesCall,
            hedgesPut
        };

        const storageKey = hedgePocketStorageKeyResolver(this._config.portfolio);
        this._userSettingsService.setValue(storageKey, settings, this._config.portfolio.userId);

        this.visible = false;
    }

    @DetectMethodChanges()
    cancelClicked() {
        this.visible = false;
    }
}