<dx-popup
  (onHidden)="this.onHidden()"
  [closeOnOutsideClick]="false"
  [dragEnabled]="true"
  [resizeEnabled]="true"
  [shading]="false"
  [showTitle]="true"
  [visible]="this.visible"
  [height]="145"
  [width]="60"
>
  <div *dxTemplate="let data of 'content'" class="slide-hedge-dialog">
    <div class="slide up">
      <dx-button icon="arrowup" width="40" height="35" (onClick)="this.slideUp()"></dx-button>
    </div>
    <div class="slide down">
      <dx-button icon="arrowdown" width="40" height="35" (onClick)="this.slideDown()"></dx-button>
    </div>
  </div>
</dx-popup>