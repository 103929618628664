import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter, OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';
import {ServiceConfiguration} from "../../../adjustment-pricing-grid/services/ServiceConfiguration";
import {SessionService} from "../../../authentication/session-service.service";
import {CashFlowPortfoliosService} from "../../../adjustment-pricing-grid/services/cashflow-portfolios.service";
import {ApgPortfolio} from "../../../adjustment-pricing-grid/model/ApgPortfolio";
import {UserDto} from "../../../authentication/dtos/auth-result-dto.inteface";
import {arraysEqual, DetectMethodChanges, DxValueChanged, isVoid, makePortfolioKey} from "../../../utils";
import {HgOriginalClientPositionsService} from "./hg-original-client-positions.service";
import {HedgePosition} from "../../data-model/hedge-position";
import {HedgePositionsService} from "../hedge-positions/hedge-positions.service";
import {MessageBusService} from "../../../message-bus.service";
import {
    HgHedgePositionsSyncDialogComponent
} from "../hedge-positions-sync-dialog/hg-hedge-positions-sync-dialog.component";
import {Subscription} from "rxjs";
import {ApgDataService} from "../../../adjustment-pricing-grid/services/apg-data.service";
import {Toast, ToastrService} from "ngx-toastr";
import {filter} from "rxjs/operators";

@Component({
    selector: 'ets-hg-portfolio-selector',
    templateUrl: 'hg-portfolio-selector.component.html',
    styleUrls:[
        './hg-portfolio-selector.component.scss',
        '../../hedging-grid-common-styles.scss'
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
    ]
})

export class HgPortfolioSelectorComponent implements OnInit, OnDestroy, AfterViewInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
        private readonly _sessionService: SessionService,
        private readonly _portfoliosService: CashFlowPortfoliosService,
        private readonly _clientPositionsService: HgOriginalClientPositionsService,
        private readonly _hedgePositionsService: HedgePositionsService,
        private readonly _messageBus: MessageBusService,
        private readonly _apgDataService: ApgDataService,
        private readonly _toastr: ToastrService
    ) {
    }

    private _subscriptions : Subscription[] = [];

    isDataLoading: boolean;

    selectedPortfolio: ApgPortfolio;

    portfolios: { key: string, userId: string, items: ApgPortfolio[] }[];

    get canRefreshPortfolio(): boolean {
        return !isVoid(this.selectedPortfolio) && !this.isOwnUserContext;
    }

    get isOwnUserContext(): boolean {
        return this.selectedPortfolio?.userId
            === this._sessionService.sessionData.userId;
    }

    @Output()
    portfolioSelected = new EventEmitter<ApgPortfolio>();

    @ViewChild(HgHedgePositionsSyncDialogComponent)
    portfolioSyncDialogCmp: HgHedgePositionsSyncDialogComponent;

    ngOnInit() {
        this._subscriptions.push(
            this._messageBus.of('Hg.HedgePositionsChanged')
                .subscribe(() => {
                    this._changeDetector.detectChanges();
                })
        );

        this._subscriptions.push(
            this._messageBus.of<{portfolios: ApgPortfolio[]}>('Apg.PortfoliosUpdated')
                .subscribe((msg) => this.onPortfoliosUpdated(msg.payload))
        );

        this._subscriptions.push(
            this._messageBus.of<{inUse: boolean, portfolio: ApgPortfolio, sender: object}>('Hg.PortfolioIsInUse')
                .pipe(
                    filter(x => x.payload.sender !== this)
                )
                .subscribe((args) => {
                    const payload = args.payload;

                    const selectedPortfolioKey = makePortfolioKey(this.selectedPortfolio);
                    const requestPortfolioKey = makePortfolioKey(payload.portfolio);

                    payload.inUse = selectedPortfolioKey === requestPortfolioKey
                     && !isVoid(this.selectedPortfolio) && !isVoid(payload.portfolio);
                })
        );
    }

    ngOnDestroy(): void {
        this._subscriptions.forEach(subscription => subscription.unsubscribe());
    }

    @DetectMethodChanges({isAsync: true})
    async ngAfterViewInit() {

        this.isDataLoading = true;

        try {
            await this.initPortfolios();

        } finally {

            this.isDataLoading = false;

        }
    }

    private async initPortfolios() {
        const users = this._sessionService.sessionData.users
            .sort((a, b) => a.userName.localeCompare(b.userName));

        const grps = [];

        for (const user of users) {
            const grp = {
                key: user.userName,
                userId: user.userId,
                items: []
            };

            const cfg: ServiceConfiguration = {
                orientation: undefined,
                userId: user.userId,
                userName: user.userName
            };

            this._portfoliosService.configure(cfg);

            const pfs = this._portfoliosService.getPortfolios();

            const defaultPortfolios = pfs.filter(x => x.id.startsWith('----'));

            for (const defaultPortfolio of defaultPortfolios) {
                const ul = await this._apgDataService.getUnderlyingOfPortfolio(defaultPortfolio);
                defaultPortfolio.asset = ul;
            }

            grp.items.push(...pfs);

            grps.push(grp);
        }

        const ownUser = this.getUserObject(this._sessionService.sessionData.userId);

        this._portfoliosService.configure({
            orientation: undefined,
            userId: ownUser.userId,
            userName: ownUser.userName
        });

        const pfs = this._portfoliosService.getPortfolios();

        const defaultPortfolios = pfs.filter(x => x.id.startsWith('----'));

        for (const defaultPortfolio of defaultPortfolios) {
            const ul = await this._apgDataService.getUnderlyingOfPortfolio(defaultPortfolio);
            defaultPortfolio.asset = ul;
        }

        const ownGrp = {
            key: ownUser.userName,
            userId: ownUser.userId,
            items: pfs
        }

        grps.unshift(ownGrp);

        this.portfolios = grps;

        if (!isVoid(this.portfolios)) {
            const apgPortfolios = this.portfolios.flatMap(x => x.items);
            if (apgPortfolios.length === 1) {
                setTimeout(() => {
                    this.selectedPortfolio = apgPortfolios[0];
                    this._changeDetector.detectChanges();
                }, 25);
            }
        }
    }

    private getUserObject(userId: string): UserDto {

        if (this._sessionService.sessionData.userId === userId) {

            const ownUserName = this._sessionService.sessionData.firstName + ' ' + this._sessionService.sessionData.lastName[0] + '.';

            return {
                userId,
                userName: ownUserName
            };

        } else {

            return this._sessionService.sessionData.users.find(x => x.userId === userId);
        }
    }

    private onPortfolioSelected() {
        this.portfolioSelected.emit(this.selectedPortfolio);

    }

    @DetectMethodChanges()
    onPortfolioChange(portfolio: ApgPortfolio) {
        const payload = {
            portfolio: portfolio,
            sender: this,
            inUse: undefined
        };

        this._messageBus.publish({
            topic: 'Hg.PortfolioIsInUse',
            payload
        });

        if (payload.inUse) {
            this._toastr.error('Selected Portfolio Is Already In Use By Other Open "Hedging Grid" Panel');
            return;
        }

        this.selectedPortfolio = portfolio;

        setTimeout(() => this.onPortfolioSelected());
    }

    refreshPortfolio(selectedPortfolio: ApgPortfolio) {
        this.portfolioSyncDialogCmp.show(selectedPortfolio, this);
    }

    getRefreshPortfolioCss() : Promise<string[]> {

        return this.hasOwnChanges()
            .then((hasOwnChanges) => {

                const hasTheirChanges = this.hasTheirChanges();

                const result = [];

                if (hasOwnChanges) {
                    result.push('own-changes');
                }

                if (hasTheirChanges) {
                    result.push('their-changes');
                }

                return result;

            });
    }

    hasTheirChanges(): boolean {

        if (isVoid(this.selectedPortfolio)) {
            return false;
        }

        if (this.selectedPortfolio.userId === this._sessionService.sessionData.userId) {
            return false;
        }

        if (this.isDataLoading) {
            return null;
        }

        const theirSnapshotPositions: HedgePosition[] = this._clientPositionsService
            .getTheirOriginalPositions(this.selectedPortfolio) || [];

        const theirCurrentPositions = this._clientPositionsService
            .getTheirCurrentPositions(this.selectedPortfolio) || [];

        if (isVoid(theirCurrentPositions)) {
            return false;
        }

        const theirSnapshotIds = theirSnapshotPositions.map(x => `${x.ticker}|${x.qty}|${x.groupId}`);
        const theirCurrentIds = theirCurrentPositions.map(x => `${x.ticker}|${x.qty}|${x.groupId}`);

        const areEqual = arraysEqual(theirSnapshotIds, theirCurrentIds);

        return !areEqual;
    }

    async hasOwnChanges(): Promise<boolean> {

        if (isVoid(this.selectedPortfolio)) {
            return false;
        }

        if (this.selectedPortfolio.userId === this._sessionService.sessionData.userId) {
            return false;
        }

        if (this.isDataLoading) {
            return null;
        }

        const theirSnapshotPositions: HedgePosition[] = this._clientPositionsService
            .getTheirOriginalPositions(this.selectedPortfolio) || [];

        const currentPositions = (await this._hedgePositionsService
            .getHedgePositions(this.selectedPortfolio)) || [];

        if (isVoid(theirSnapshotPositions) && isVoid(currentPositions)) {
            return false;
        }

        if (isVoid(theirSnapshotPositions) && !isVoid(currentPositions)) {
            return true;
        }

        if (isVoid(currentPositions) && !isVoid(theirSnapshotPositions)) {
            return true;
        }

        const strings = theirSnapshotPositions.map(x => `${x.ticker}|${x.qty}|${x.groupId}`);

        const strings1 = currentPositions.map(x => `${x.ticker}|${x.qty}|${x.groupId}`);

        const areSame = arraysEqual(strings, strings1);

        return !areSame;
    }

    @DetectMethodChanges({isAsync: true})
    private async onPortfoliosUpdated(payload: { portfolios: ApgPortfolio[] }) {
        const selectedPortfolioId = this.selectedPortfolio?.id + this.selectedPortfolio?.userId;

        const updatedSelected = payload.portfolios.find(x =>  {
            const key = x.id + x.userId;
            return key === selectedPortfolioId;
        });

        try {
            this.isDataLoading = true;
            await this.initPortfolios();
            if (updatedSelected) {
                this.selectedPortfolio =updatedSelected;
            }
        } catch(e)  {
            console.log(e);
        } finally {
            this.isDataLoading = false;
        }
    }
}