<div [ngClass]="{'ets-hidden': !isActive}" class="panel-container">
  
  <div class="container">
    <ag-grid-angular
      style="width: 100%;height: 100%" class="ag-theme-balham-dark"
      [gridOptions]="this.gridOptions"
    ></ag-grid-angular>
  </div>
  
</div>

