import {Injectable} from '@angular/core';
import {HedgePositionsService} from "../positions-section/hedge-positions/hedge-positions.service";
import {ApgPortfolio} from "../../adjustment-pricing-grid/model/ApgPortfolio";
import {
    isVoid, makePortfolioKey,
} from "../../utils";
import {OptionsChainService} from "../../option-chains.service";
import {
    OptionExpirationDescriptor
} from "../../shell-communication/shell-dto-protocol";
import {ApgDataService} from "../../adjustment-pricing-grid/services/apg-data.service";
import {LastQuoteCacheService} from "../../last-quote-cache.service";
import {HedgeData} from "./hedge-data";
import {HedgeMatrixCellData} from "./hedge-matrix-cell.data";
import {HedgeMatrixTransLeg} from "./hedge-matrix-trans.leg";
import {PortfolioHedgeMatrixDataService} from "./portfolio-hedge-matrix-data.service";

@Injectable()
export class HedgeMatrixDataService {

    constructor(
        private readonly _hedgePositionsService: HedgePositionsService,
        private readonly _optionChainService: OptionsChainService,
        private readonly _apgDataService: ApgDataService,
        private readonly _lastQuoteCache: LastQuoteCacheService
    ) {
    }

    private _portfolioDataServices: Record<string, PortfolioHedgeMatrixDataService> = {};

    private getPortfolioDataService(portfolio: ApgPortfolio) {
        if (isVoid(portfolio)) {
            return null;
        }

        const pfKey = makePortfolioKey(portfolio);

        let dataService = this._portfolioDataServices[pfKey];

        if (isVoid(dataService)) {
            dataService = new PortfolioHedgeMatrixDataService(
                portfolio,
                this._optionChainService,
                this._lastQuoteCache,
                this._apgDataService,
                this._hedgePositionsService
            );
            this._portfolioDataServices[pfKey] = dataService;
        }

        return dataService;
    }

    async onPortfolioSelected(portfolio: ApgPortfolio): Promise<void> {
        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return;
        }

        dataService.reset();

        await dataService.onPortfolioSelected();
    }

    getPortfolioUnderlying(portfolio: ApgPortfolio): string {
        return this.getPortfolioDataService(portfolio)?.getPortfolioUnderlying();
    }

    getCellData(portfolio: ApgPortfolio, strike: number, hedgeId: string): HedgeMatrixCellData[] {
        return this.getPortfolioDataService(portfolio)?.getCellData(strike, hedgeId) || [];
    }

    removeHedgeCellData(portfolio: ApgPortfolio, cellData: HedgeMatrixCellData) {
        this.getPortfolioDataService(portfolio)?.removeHedgeCellData(cellData);
    }

    getLegStrikes(portfolio: ApgPortfolio): number[] {
        return this.getPortfolioDataService(portfolio)?.getLegStrikes() || [];
    }

    getTotalQtyForStrike(portfolio: ApgPortfolio, strike: number, side: 'Call' | 'Put', visible: string[]): number {

        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return;
        }

        const data = dataService.getTotalQtyForStrike(strike, side, visible);

        return data;
    }

    getHedges(portfolio: ApgPortfolio): HedgeData[] {
        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return [];
        }
        const data = dataService.getHedges();

        return data;
    }

    getHedge(portfolio: ApgPortfolio, id: string) {
        const hedgeData = this.getHedges(portfolio).find(x => x.id === id);
        return hedgeData;
    }

    getPortfolioLegColorByStrike(portfolio: ApgPortfolio, strike: number): string[] {
        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return [];
        }

        const data = dataService.getPortfolioLegColorByStrike(strike);

        return data;
    }

    getPortfolioPositionStrikes(portfolio: ApgPortfolio): number[] {

        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return [];
        }

        const data = dataService.getPortfolioPositionStrikes();

        return data;

    }

    getTickerForCell(portfolio: ApgPortfolio, strike: number, expiration: string, side: "Call" | "Put"): string {

        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return undefined;
        }

        const data = dataService.getTickerForCell(strike, expiration, side);

        return data;

    }

    getNearestExpiration(portfolio: ApgPortfolio): OptionExpirationDescriptor {
        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return null;
        }

        const data = dataService.getNearestExpiration();

        return data;
    }

    setTransQty(portfolio: ApgPortfolio, hedge: HedgeData, strike: number, expiration: string, transQty: number) {
        const dataService = this.getPortfolioDataService(portfolio);

        dataService?.setTransQty(hedge,strike, expiration, transQty);
    }

    setOutcomeQty(portfolio: ApgPortfolio, hedge: HedgeData, strike: number, expiration: string, outcomeQty: number) {
        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return;
        }

        dataService.setOutcomeQty(hedge, strike, expiration, outcomeQty);
    }

    onHedgeModificationFinished(portfolio: ApgPortfolio, hedgeData: HedgeData): boolean {
        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return;
        }

        const data = dataService.onHedgeModificationFinished(hedgeData);

        return data;
    }

    getTransCost(portfolio: ApgPortfolio, hedgeId: string, expiration?: string): number {

        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return;
        }

        const data = dataService.getTransCost(hedgeId, expiration);

        return data;

    }

    getTransCostAsOwned(portfolio: ApgPortfolio, hedgeId: string, expiration?: string): number {
        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return;
        }

        const data = dataService.getTransCostAsOwned(hedgeId, expiration);

        return data;
    }

    getOutcomeCost(portfolio: ApgPortfolio, hedgeId: string, expiration?: string): number {

        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return;
        }

        const data = dataService.getOutcomeCost(hedgeId, expiration);

        return data;
    }

    getOriginalCost(portfolio: ApgPortfolio, hedgeId: string, expiration?: string): number {

        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return;
        }

        const data = dataService.getOriginalCost(hedgeId, expiration);

        return data;

    }

    onHedgeModificationStarted(portfolio: ApgPortfolio, hedgeData: HedgeData): boolean {

        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return;
        }

        const data = dataService.onHedgeModificationStarted(hedgeData);

        return data;
    }

    async addNewHedge(portfolio: ApgPortfolio, side: 'Call' | 'Put', underlying: string): Promise<HedgeData> {

        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return Promise.resolve(null);
        }

        const data = dataService.addNewHedge(side, underlying);

        return data;
    }

    onNewHedgeExpirationChanged(portfolio: ApgPortfolio, hedgeId: string, expiration: string) {

        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return;
        }

        const data = dataService.onNewHedgeExpirationChanged(hedgeId, expiration);

        return data;
    }

    removeHedge(portfolio: ApgPortfolio, hedgeId: string) {

        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return;
        }

        const data = dataService.removeHedge(hedgeId);

        return data;

    }

    getTransactionLegs(portfolio: ApgPortfolio, hedgeId: string): HedgeMatrixTransLeg[] {

        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return [];
        }

        const data = dataService.getTransactionLegs(hedgeId);

        return data;
    }

    getOutcomeLegs(portfolio: ApgPortfolio, hedgeId: string): HedgeMatrixTransLeg[] {

        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return [];
        }

        const data = dataService.getOutcomeLegs(hedgeId);

        return data;
    }

    calculatePnls(portfolio: ApgPortfolio, strikes: number[]) {

        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return;
        }

        const data = dataService.calculatePnls(strikes);

        return data;
    }

    getExpirationPnl(portfolio: ApgPortfolio, expiration: string, strike: number, side: 'Call' | 'Put', visible: string[]): number {

        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return;
        }

        const data = dataService.getExpirationPnl(expiration, strike, side, visible);

        return data;

    }

    getQty(portfolio: ApgPortfolio, strike: number, hedgeId: string): HedgeMatrixCellData[] {

        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return [];
        }

        const data = dataService.getQty(strike, hedgeId);

        return data;

    }

    setQty(portfolio: ApgPortfolio, hedge: HedgeData, strike: number, expiration: string, field: keyof HedgeMatrixCellData, qty: number) {

        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return;
        }

        dataService.setQty(hedge, strike, expiration, field, qty);
    }


    getCellDataForHedge(portfolio: ApgPortfolio, hedgeId: string): HedgeMatrixCellData[] {

        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return [];
        }

        const data = dataService.getCellDataForHedge(hedgeId);

        return data;

    }

    doesHaveMultipleExpirations(portfolio: ApgPortfolio, hedgeId: string): boolean {

        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return;
        }

        const data = dataService.doesHaveMultipleExpirations(hedgeId);

        return data;

    }

    getHedgeExpirations(portfolio: ApgPortfolio, hedgeId: string): string[] {

        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return [];
        }

        const data = dataService.getHedgeExpirations(hedgeId);

        return data;
    }

    getHedgeExpirationsByColumn(portfolio: ApgPortfolio, hedgeId: string, state: 'original' | 'trans' | 'outcome'): string[] {

        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return [];
        }

        const data = dataService.getHedgeExpirationsByColumn(hedgeId, state);

        return data;

    }


    hedgeContainsExpiration(portfolio: ApgPortfolio, hedgeId: string, expiration: string): boolean {

        const dataService = this.getPortfolioDataService(portfolio);

        if (isVoid(dataService)) {
            return;
        }

        const data = dataService.hedgeContainsExpiration(hedgeId, expiration);

        return data;

    }


    getHedgeDefaultExpiration(portfolio: ApgPortfolio, hedgeId: string): string {

        const dataService = this.getPortfolioDataService(portfolio);

        const data = dataService.getHedgeDefaultExpiration(hedgeId);

        return data;
    }
}