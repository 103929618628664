import {ExpirationDescriptor, HedgeMatrixRow, HgHedgeMatrixComponent} from "./hg-hedge-matrix.component";
import {
    CellClassParams, CellEditingStartedEvent, CellEditingStoppedEvent,
    ColDef,
    ColGroupDef, ColumnMovedEvent, DisplayedColumnsChangedEvent,
    FirstDataRenderedEvent,
    GetContextMenuItemsParams, GridApi,
    GridOptions,
    GridReadyEvent, ICellEditorParams, ICellRendererParams,
    IsColumnFuncParams,
    ITooltipParams,
    MenuItemDef, RowNode, RowSpanParams,
    ValueSetterParams,
} from "ag-grid-community";
import {AgGridColumn} from "ag-grid-angular";
import * as Enumerable from "linq";
import {ValueFormatterParams, ValueGetterParams} from "ag-grid-community/dist/lib/entities/colDef";
import {centeredColumnDef} from "../../ag-grid-contrib";
import {
    defaultCurrencyFormatter, getShortUUID, isOptionExpired,
    isValidNumber,
    isVoid,
    makeDayOfWeekDate, makeGuiFriendlyExpirationDate, parseOrderLegs,
} from "../../utils";
import {HedgeData} from "./hedge-data";
import {HedgeMatrixCustomColumnHeaderGroup} from "./hedge-matrix-custom-colgroup-header";
import {HgMatrixPunchPadComponent} from "./punch-pad/hg-matrix-punch-pad.component";
import {HedgeExpirationCellEditorComponent} from "./hedge-expiration-cell-editor.component";
import {MultiExpirationCellRenderer} from "./multi-expiration-cell-renderer.component";
import {DateTime} from "luxon";
import {Subject} from "rxjs";
import {parseOptionTicker, zipOptionTicker} from "../../options-common/options.model";
import {hedgePocketStorageKeyResolver} from "./hege-pocket/hedge-pocket.component";
import {HedgeMatrixCellData} from "./hedge-matrix-cell.data";

const nanoWidth = 60;
const microWidth = 70;
const miniWidth = 80;
const minWidth = 90;
const colWidth = 100;
const maxWidth = 110;

export function getHedgeMatrixGridOptions(comp: HgHedgeMatrixComponent): GridOptions {

    const subj = new Subject();

    const options: GridOptions = {};

    options.defaultColDef = centeredColumnDef;

    options.columnDefs = getColumnDefs(comp, true);

    options.rowClass = 'ets-text-centered';

    options.suppressRowTransform = true;

    options.rowModelType = 'clientSide';

    options.rowData = [];

    options.tooltipShowDelay = 250;

    options.applyColumnDefOrder = true;

    options.suppressDragLeaveHidesColumns = true;

    options.suppressColumnVirtualisation = true;

    options.suppressCellSelection = false;

    options.frameworkComponents = {
        matrixTotalGroupHeader: HedgeMatrixCustomColumnHeaderGroup,
        punchPadCellEditor: HgMatrixPunchPadComponent,
        hedgeExpirationCellEditor: HedgeExpirationCellEditorComponent,
        multiExpirationCellRenderer: MultiExpirationCellRenderer
    }

    options.onCellEditingStarted = (args: CellEditingStartedEvent) => {
        const hedgeData = args.colDef['ets-data'] as HedgeData;

        if (isVoid(hedgeData)) {
            return;
        }

        const hedgeId = hedgeData.id;

        const rowData = args.data as HedgeMatrixRow;

        if (isVoid(rowData)) {
            return;
        }

        const strike = rowData.strike;

        const cellDatas = comp.hedgeMatrixDataService.getCellData(
            comp.selectedPortfolio,
            strike,
            hedgeId
        );

        if (isVoid(cellDatas)) {
            return;
        }

        const expirationCellDataIndex = getExpirationCellDataIndex(strike, args.api, args.node);

        const cellData = cellDatas[expirationCellDataIndex];

        const descriptor = {
            strike,
            expiration: cellData.expiration,
            hedgeId: cellData.hedgeId,
            field: args.colDef.field
        };

        comp.setCellBeingEdited(descriptor);
    };

    options.onCellEditingStopped = (args: CellEditingStoppedEvent) => {
        comp.clearCellBeingEdited();
    };

    options.getRowNodeId = (data: HedgeMatrixRow) => data.rowId;

    options.onVirtualRowRemoved = (args) => {
        subj.next();
    };

    options.onColumnMoved = (args: ColumnMovedEvent) => {
        comp.updateVisibleColumns();
    }

    options.onDisplayedColumnsChanged = (args: DisplayedColumnsChangedEvent) => {
        comp.updateVisibleColumns();

    }

    options.onFirstDataRendered = (args: FirstDataRenderedEvent) => {
        const strings = args.columnApi.getAllColumns().map(x => x.getColId())
            .filter(x => !!x && !x.startsWith('spacer'));
        args.columnApi.autoSizeColumns(strings);

    };

    options.onGridReady = (args: GridReadyEvent) => comp.onGridReady(args);

    options.getContextMenuItems = (args: GetContextMenuItemsParams) => {

        const menuItems: (string | MenuItemDef)[] = [];

        const colDef = args.column.getColDef() || {};

        const hedgeData = colDef['ets-data'] as HedgeData;

        const isHedgeColumn = !isVoid(hedgeData);

        const isNewHedge = isHedgeColumn && hedgeData.isNew;

        if (isHedgeColumn && !isNewHedge) {

            const hedgeData = colDef['ets-data'] as HedgeData;

            const colGroupId = `${hedgeData.id}:colgroup`;

            const colGroup = args.columnApi.getColumnGroup(colGroupId);

            if (!isVoid(colGroup)) {

                const isModificationMode = colGroup.getDisplayedChildren().length > 1;

                let text = 'Modify Hedge';

                if (isModificationMode) {
                    text = 'Exit Modification Mode';
                }

                const isExpired = comp.isHedgeExpired(hedgeData);

                const modifyItem: MenuItemDef = {
                    name: text,
                    action: () => {
                        comp.modifyHege(args.column);
                    },
                    disabled: isExpired
                };

                menuItems.push(modifyItem);

                menuItems.push('separator');
            }
        }

        if (isNewHedge) {
            menuItems.push(
                {
                    name: 'Slide Hedge',
                    action: () => comp.showSlideHedgeDialog(hedgeData)
                },
            );
        }

        const hpStorageKey = hedgePocketStorageKeyResolver(comp.selectedPortfolio);

        const addHedgeItem: MenuItemDef = {
            name: 'Add Hedge',
            subMenu: [
                {
                    name: 'Call',
                    action: () => comp.addHedge('Call')
                },
                {
                    name: 'Put',
                    action: () => comp.addHedge('Put')
                },
            ]
        };

        const hedgePocketSettings = comp.userSettingsService.getValue<{
            hedgesCall: any[],
            hedgesPut: any[]
        }>(hpStorageKey);

        if (!isVoid(hedgePocketSettings)) {

            const rowData = args.node.data as HedgeMatrixRow;

            const callTemplates = hedgePocketSettings.hedgesCall.map(x => {
                const submenuItem = {
                    name: x.strategyName,
                    action: () => comp.addHedge('Call', x.strategyId, rowData)
                }

                return submenuItem as MenuItemDef;
            });

            const putTemplates = hedgePocketSettings.hedgesPut.map(x => {
                const submenuItem = {
                    name: x.strategyName,
                    action: () => comp.addHedge('Put', x.strategyId, rowData)
                }
                return submenuItem as MenuItemDef;
            });

            const menuItem = {
                name: 'From Pocket',
                subMenu: [
                    {
                        name: 'Call Templates',
                        subMenu: callTemplates
                    },
                    {
                        name: 'Put Templates',
                        subMenu: putTemplates
                    }
                ]
            };

            const arr = addHedgeItem.subMenu as Array<any>;
            arr.push(menuItem);
        }

        menuItems.push(addHedgeItem);

        const addSpacerItem: MenuItemDef = {
            name: 'Add Spacer',
            action: () => comp.addSpacer()
        }

        menuItems.push(addSpacerItem);

        if (args.column) {
            if (args.column.getColId().startsWith('spacer')) {
                menuItems.push({
                    name: 'Remove Spacer',
                    action: () => comp.removeSpacer(args.column.getColId())
                });
            }
        }

        const removeHedgeItem: MenuItemDef = {
            name: 'Remove Hedge',
            action: () => comp.removeHedge(hedgeData?.id),
        };

        if (hedgeData && hedgeData.isNew) {
            menuItems.push(removeHedgeItem);
        }

        menuItems.push({
            name: 'Copy Orders To...',
            subMenu: [
                {
                    name: 'ML Pad',
                    action: () => comp.copyOrders(hedgeData, 'ml-pad')
                },
                {
                    name: 'Hedging Grid',
                    action: () => comp.copyOrders(hedgeData, 'hg')
                },
                {
                    name: 'ToS',
                    action: () => comp.copyOrders(hedgeData, 'tos')
                }
            ]
        });

        menuItems.push('separator');

        menuItems.push({
            name: 'Rebuild Grid',
            action: () => comp.rebuildGrid()
        });

        menuItems.push('separator');

        menuItems.push('autoSizeAll');
        menuItems.push('sizeColumnsToFit');

        return menuItems;
    }

    options.context = {
        subj
    };

    return options;
}

export function getColumnDefs(comp: HgHedgeMatrixComponent, isEmpty?: boolean): Partial<AgGridColumn>[] {

    const cols: Partial<AgGridColumn>[] = [];

    const pinnedColumns = getPinnedColumns(comp);

    cols.push(pinnedColumns);

    if (!isEmpty) {

        const hedges = Enumerable.from(
            comp.hedgeMatrixDataService.getHedges(comp.selectedPortfolio)
        );

        const expirations = comp.expirationList.map(x => {
            const column = getExpirationColumn(comp, x);
            return column;
        });

        cols.push(...expirations);

        const hedgesColumns = hedges
            .select(h => getHedgeColumn(comp, h))
            .toArray();

        cols.push(...hedgesColumns);
    }

    const totals = getTotalColumns(comp);

    cols.push(...totals);

    const pnlExpirations = comp.pnlExpirationsList.flatMap(x => {
        const column = getExpirationPnlGroup(comp, x, false);
        const allInclusive = getExpirationPnlGroup(comp, x, true);
        return [column, allInclusive];
    });
    cols.push(...pnlExpirations);

    const uniqueExpirations = Enumerable.from(comp.expirationList)
        .select(x => x.expiration)
        .distinct()
        .toArray();

    const grandTotalCols = uniqueExpirations.map(x => {
        const exp = comp.expirationList.find(y => y.expiration === x);
        const col = getGrandTotalPnlExpirationColumn(comp, exp);
        return col;
    });

    cols.push(...grandTotalCols);

    const spacer = getSpacerColumns();

    cols.push(...spacer);

    return cols;
}

function getPinnedColumns(comp: HgHedgeMatrixComponent): ColGroupDef {
    const otmCol = getOtmColumn(comp);
    const strikeCol = getStrikeColumn(comp);

    const group: ColGroupDef = {
        groupId: 'pinned',
        headerGroupComponent: 'matrixTotalGroupHeader',
        headerGroupComponentParams: {
            context: {
                comp,
                type: 'master',
            },
        },
        children: [otmCol, strikeCol],
    }

    return group;
}

function getStrikeColumn(comp: HgHedgeMatrixComponent): ColDef {
    const col: ColDef = {
        headerName: 'Strike',
        pinned: true,
        field: 'strike',
        colId: 'strike',
        minWidth: miniWidth,
        width: miniWidth,
        headerClass: 'ets-text-centered',
        sortable: false,
        sortIndex: 0,
        sort: 'desc',
        rowSpan: (params: RowSpanParams) => params.data.rowSpan || 1,
        cellStyle(args: CellClassParams) {

            if (!args.data) {
                return undefined;
            }

            if (args.data.isPinned) {
                return {
                    background: '#2a2a2a',
                    color: 'gold',
                    'font-weight': 'bold',
                };
            }

            const styleObj = {
                color: 'white',
                'font-weight': '800',
                background: 'inherit'
            };

            const bgColors = [];

            const atmStrike = comp.getAtmStrike();

            if (args.data.strike === atmStrike) {
                bgColors.push('white');
            }

            let portfolioLegColors = comp.hedgeMatrixDataService
                .getPortfolioLegColorByStrike(comp.selectedPortfolio, args.data.strike);

            if (comp.showPortfolioPositions) {
                if (portfolioLegColors.length > 0) {
                    bgColors.push(...portfolioLegColors);
                }
            }

            if (args.data && (args.data.strike === comp.centerStrike)) {
                bgColors.push('black');
            }

            const rowData = args.data as HedgeMatrixRow;

            if (rowData.rowSpan > 1) {
                styleObj['display'] = 'flex';
                styleObj['align-items'] = 'center';
                styleObj['justify-content'] = 'center';
                styleObj['border-bottom-color'] = 'rgb(66, 66, 66)';
            }


            if (bgColors.length === 0) {
                if (rowData.rowSpan > 1) {
                    styleObj['background'] = '#2a2a2a';
                }
                return styleObj;
            }

            let background;

            if (bgColors.length === 1) {
                background = bgColors[0];
            } else if (bgColors.length === 2) {
                background = `linear-gradient(to right, ${bgColors[0]} 0% 50%, ${bgColors[1]} 50% 100%);`;
            } else if (bgColors.length === 3) {
                background = `linear-gradient(to right, ${bgColors[0]} 0% 33%, ${bgColors[1]} 33% 66%, ${bgColors[2]} 66% 100%);`;
            } else if (bgColors.length === 4) {
                background = `linear-gradient(to right, ${bgColors[0]} 0% 25%, ${bgColors[1]} 25% 50%, ${bgColors[2]} 50% 75%, ${bgColors[3]} 75% 100%);`;
            }

            styleObj['background'] = background;

            if (bgColors.indexOf('white') >= 0) {
                styleObj['color'] = 'black';
            }

            if (bgColors.indexOf('black') >= 0) {
                if (bgColors.indexOf('orange') === -1) {
                    styleObj['color'] = 'gold';
                }
            }

            return styleObj;
        },
    }

    return col;
}

export function getHedgeColumn(comp: HgHedgeMatrixComponent, hedge: HedgeData) {
    if (hedge.isNew) {
        return getNewHedgeColumn(comp, hedge);
    } else {
        return getExistingHedgeColumn(comp, hedge);
    }
}

function getExistingHedgeColumn(comp: HgHedgeMatrixComponent, hedge: HedgeData) {

    const colDef: ColGroupDef = {
        headerName: hedge.type.toUpperCase() + 'S',
        marryChildren: true,
        groupId: `${hedge.id}:colgroup`,
        children: [
            {
                headerName: hedge.name,
                colId: hedge.id,
                minWidth: colWidth,
                width: colWidth,
                sortable: false,
                cellStyle: getHedgeColumnCellStyle(comp),
                cellRendererSelector: (params: ICellRendererParams) => {
                    const isMulti = !params.data.isPinned
                        && comp.hedgeMatrixDataService.doesHaveMultipleExpirations(comp.selectedPortfolio, hedge.id);

                    if (isMulti) {
                        return {
                            component: 'multiExpirationCellRenderer',
                            params: {
                                etsComponent: comp,
                                hedge,
                                field: 'qty'
                            }
                        }
                    }

                    return null;
                },
                headerComponentParams: {
                    template:
                        `<div class="ag-cell-label-container" role="presentation" style="color: ${hedge.color};">
                          <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
                          <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
                          <div data-ref="eLabel" class="ag-header-cell-label" role="presentation" style="justify-content: center">
                            <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
                            <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
                            <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
                            <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
                            ${hedge.name} 
                            <span data-ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                            <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                          </div>
                        </div>`
                },
                tooltipValueGetter: (params: ITooltipParams) => {
                    if (!params.data.isPinned) {
                        return undefined;
                    }
                    return hedge.name;
                },
                valueGetter: (params: ValueGetterParams) => {
                    const strike = params.data.strike;

                    if (params.data.isPinned) {
                        if (params.data.isExpiration || params.data.isDayOfWeek) {
                            const val = params.data.isExpiration
                                ? getHedgeExpirationsString(comp, hedge, 'original')
                                : getDaysOfWeekString(comp, hedge, 'original')
                            return val;
                        } else if (params.data.isHedgePrice) {
                            return comp.getHedgePriceFormatted(hedge)
                        } else {
                            return undefined;
                        }
                    }

                    if (isVoid(strike)) {
                        return undefined;
                    }

                    const cellData = comp.hedgeMatrixDataService.getCellData(
                        comp.selectedPortfolio,
                        strike,
                        hedge.id,
                    );

                    if (isVoid(cellData)) {
                        return null;
                    }

                    const expirationCellDataIndex = getExpirationCellDataIndex(
                        strike,
                        params.api,
                        params.node
                    );
                    const expirationCellData = cellData[expirationCellDataIndex];

                    return expirationCellData?.qty;
                },
                valueFormatter: getValueFormatterForHedgeColumn(comp, hedge),
            },
            {
                headerName: 'Trans',
                colId: `${hedge.id}:trans`,
                field: 'transQty',
                headerClass: 'opg-difference',
                minWidth: colWidth,
                width: colWidth,
                hide: true,
                sortable: false,
                cellRendererSelector: (params: ICellRendererParams) => {
                    const isMulti = !params.data.isPinned
                        && comp.hedgeMatrixDataService.doesHaveMultipleExpirations(comp.selectedPortfolio, hedge.id);

                    if (isMulti) {
                        return {
                            component: 'multiExpirationCellRenderer',
                            params: {
                                etsComponent: comp,
                                hedge,
                                field: 'transQty'
                            }
                        }
                    }

                    return null;
                },
                cellEditorSelector: (params: ICellEditorParams) => {
                    const isMouseClick = isVoid(params.charPress) && isVoid(params.keyPress);
                    if (isMouseClick) {
                        if (params.data.isPinned) {
                            if (params.data.isExpiration) {
                                return {component: 'hedgeExpirationCellEditor'}
                            }
                        }
                        return {component: 'punchPadCellEditor'};
                    }
                },
                cellEditorParams: {
                    etsComponent: comp,
                    fieldEdited: 'transQty'
                },
                cellStyle: getModifierColumnCellStyle(comp, 'transQty'),
                editable: (params: IsColumnFuncParams) => !params.data.isPinned,
                valueGetter: (params: ValueGetterParams) => {
                    const strike = params.data.strike;

                    if (!isValidNumber(strike, true)) {
                        if (params.data.isPinned) {
                            if (params.data.isHedgePrice) {
                                const transCost = comp.hedgeMatrixDataService.getTransCost(
                                    comp.selectedPortfolio,
                                    hedge.id
                                );
                                return transCost;
                            } else if (params.data.isExpiration || params.data.isDayOfWeek) {
                                const val = params.data.isExpiration
                                    ? getHedgeExpirationsString(comp, hedge, 'trans')
                                    : getDaysOfWeekString(comp, hedge, 'trans')
                                return val;
                            }
                        }
                        return null;
                    }

                    const cellData = comp.hedgeMatrixDataService.getQty(comp.selectedPortfolio, strike, hedge.id);

                    if (isVoid(cellData)) {
                        return null;
                    }

                    const expirationCellDataIndex = getExpirationCellDataIndex(
                        strike,
                        params.api,
                        params.node
                    );
                    const expirationCellData = cellData[expirationCellDataIndex];
                    const transQty = expirationCellData?.transQty;
                    return transQty;
                },
                valueSetter: getQtyValueSetter(comp, hedge, 'transQty'),
                valueFormatter: getValueFormatterForHedgeColumn(comp, hedge)
            },
            {
                headerName: 'Outcome',
                colId: `${hedge.id}:outcome`,
                field: 'outcomeQty',
                headerClass: 'opg-difference',
                minWidth: colWidth,
                width: colWidth,
                hide: true,
                sortable: false,
                cellRendererSelector: (params: ICellRendererParams) => {
                    const isMulti = !params.data.isPinned
                        && comp.hedgeMatrixDataService.doesHaveMultipleExpirations(comp.selectedPortfolio, hedge.id);

                    if (isMulti) {
                        return {
                            component: 'multiExpirationCellRenderer',
                            params: {
                                etsComponent: comp,
                                hedge,
                                field: 'outcomeQty'
                            }
                        }
                    }

                    return null;
                },
                cellEditorSelector: (params: ICellEditorParams) => {
                    const isMouseClick = isVoid(params.charPress) && isVoid(params.keyPress);
                    if (isMouseClick) {
                        return {
                            component: 'punchPadCellEditor',
                        };
                    }
                },
                cellEditorParams: {
                    etsComponent: comp,
                    fieldEdited: 'outcomeQty'
                },
                cellStyle: getModifierColumnCellStyle(comp, 'outcomeQty'),
                editable: (params: IsColumnFuncParams) => !params.data.isPinned,
                valueGetter: (params: ValueGetterParams) => {
                    const strike = params.data.strike;
                    if (!isValidNumber(strike, true)) {
                        if (params.data.isPinned) {
                            if (params.data.isHedgePrice) {
                                const transCost = comp.hedgeMatrixDataService
                                    .getOutcomeCost(comp.selectedPortfolio, hedge.id);
                                return transCost;
                            } else if (params.data.isExpiration || params.data.isDayOfWeek) {
                                const val = params.data.isExpiration
                                    ? getHedgeExpirationsString(comp, hedge, 'outcome')
                                    : getDaysOfWeekString(comp, hedge, 'outcome')
                                return val;
                            }
                        }
                        return null;
                    }

                    const cellData = comp.hedgeMatrixDataService.getCellData(
                        comp.selectedPortfolio,
                        strike,
                        hedge.id
                    );

                    if (isVoid(cellData)) {
                        return null;
                    }

                    const expirationCellDataIndex = getExpirationCellDataIndex(
                        strike,
                        params.api,
                        params.node
                    );

                    const expirationCellData = cellData[expirationCellDataIndex];

                    const outcomeQty = expirationCellData?.outcomeQty;

                    return outcomeQty;
                },
                valueSetter: getQtyValueSetter(comp, hedge, 'outcomeQty'),
                valueFormatter: getValueFormatterForHedgeColumn(comp, hedge)
            }
        ]
    };

    colDef.children.forEach(c => c['ets-data'] = hedge);

    return colDef;
}

function getNewHedgeColumn(comp: HgHedgeMatrixComponent, hedge: HedgeData) {

    const colDef: ColGroupDef = {
        headerName: hedge.type.toUpperCase() + 'S',
        marryChildren: true,
        groupId: `${hedge.id}:colgroup`,
        children: [
            {
                headerName: hedge.name,
                colId: hedge.id,
                minWidth: colWidth,
                width: colWidth,
                sortable: false,
                cellStyle: getModifierColumnCellStyle(comp, 'transQty'),
                headerComponentParams: {
                    template:
                        `<div class="ag-cell-label-container" role="presentation" style="color: ${hedge.color};">
                          <span data-ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
                          <span data-ref="eFilterButton" class="ag-header-icon ag-header-cell-filter-button"></span>
                          <div data-ref="eLabel" class="ag-header-cell-label" role="presentation" style="justify-content: center">
                            <span data-ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
                            <span data-ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
                            <span data-ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
                            <span data-ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
                            ${hedge.name} 
                            <span data-ref="eText" class="ag-header-cell-text" role="columnheader"></span>
                            <span data-ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
                          </div>
                        </div>`
                },
                cellRendererSelector: (params: ICellRendererParams) => {
                    const isMulti = !params.data.isPinned
                        && comp.hedgeMatrixDataService.doesHaveMultipleExpirations(comp.selectedPortfolio, hedge.id);

                    if (isMulti) {
                        return {
                            component: 'multiExpirationCellRenderer',
                            params: {
                                etsComponent: comp,
                                hedge,
                                field: 'transQty'
                            }
                        }
                    }
                },
                cellEditorSelector: (params: ICellEditorParams) => {
                    const isMouseClick = isVoid(params.charPress) && isVoid(params.keyPress);
                    if (isMouseClick) {
                        if (params.data.isPinned) {
                            return {component: 'hedgeExpirationCellEditor'}
                        }
                        return {component: 'punchPadCellEditor'};
                    }
                },
                cellEditorParams: {
                    api: comp.theGrid,
                    etsComponent: comp,
                    hedgeId: hedge.id,
                    fieldEdited: 'transQty'
                },
                editable: (params: IsColumnFuncParams) => !params.data.isPinned || params.data.isExpiration || params.data.isDayOfWeek,
                valueGetter: (params: ValueGetterParams) => {
                    const strike = params.data.strike;

                    if (!isValidNumber(strike, true)) {
                        if (params.data.isPinned) {
                            if (params.data.isHedgePrice) {
                                const transCost = comp.hedgeMatrixDataService
                                    .getTransCost(
                                        comp.selectedPortfolio,
                                        hedge.id
                                    );
                                return transCost;
                            } else if (params.data.isExpiration || params.data.isDayOfWeek) {
                                const val = params.data.isExpiration
                                    ? getHedgeExpirationsString(comp, hedge, 'trans')
                                    : getDaysOfWeekString(comp, hedge, 'trans')
                                return val;
                            }
                        }
                        return null;
                    }

                    const cellData = comp.hedgeMatrixDataService.getCellData(
                        comp.selectedPortfolio,
                        strike,
                        hedge.id
                    );

                    if (isVoid(cellData)) {
                        return null;
                    }

                    const expirationCellDataIndex = getExpirationCellDataIndex(
                        strike,
                        params.api,
                        params.node
                    );

                    const expirationCellData = cellData[expirationCellDataIndex];

                    if (isVoid(expirationCellData)) {
                        return null;
                    }

                    const transQty = expirationCellData?.transQty;

                    return transQty;
                },
                valueSetter: getQtyValueSetter(comp, hedge, 'transQty'),
                valueFormatter: getValueFormatterForHedgeColumn(comp, hedge)
            },
        ]
    };

    colDef.children.forEach(c => c['ets-data'] = hedge);

    return colDef;
}

function getExpirationColumn(comp: HgHedgeMatrixComponent, expiration: ExpirationDescriptor) {
    const colId = `${expiration.expiration}:expiration:${expiration.side}`;
    const col: ColDef = {
        headerName: `${expiration.side.toUpperCase()}S`,
        colId: colId,
        minWidth: minWidth,
        width: minWidth,
        headerClass: 'ets-text-centered',
        hide: true,
        sortable: false,
        rowSpan: (params: RowSpanParams) => params.data.rowSpan || 1,
        valueGetter: (params: ValueGetterParams) => {
            if (params.data.isPinned) {
                if (params.data.isExpiration || params.data.isDayOfWeek) {
                    const val = params.data.isExpiration
                        ? expiration.expirationFriendly
                        : expiration.dayOfWeek
                    return val;
                } else {
                    return undefined;
                }
            }

            const ticker = comp.hedgeMatrixDataService.getTickerForCell(
                comp.selectedPortfolio,
                params.data.strike,
                expiration.expiration,
                expiration.side
            );
            const lastQuote = comp.lastQuoteCache.getLastQuote(ticker);
            return lastQuote?.mid;
        },
        valueFormatter: (params: ValueFormatterParams) => {
            if (!isValidNumber(params.value, true)) {
                return null;
            }
            return defaultCurrencyFormatter(params.value);
        },
        cellStyle: getExpirationColumnCellStyle(comp)
    }
    return col;
}

function getTotalColumns(comp: HgHedgeMatrixComponent): ColDef[] {
    const totalCalls: ColDef = getTotalColumn(comp, 'Call');
    const totalPuts: ColDef = getTotalColumn(comp, 'Put');
    return [totalCalls, totalPuts];
}

function getTotalColumn(comp: HgHedgeMatrixComponent, side: 'Call' | 'Put'): ColDef {

    const totalColumn: ColDef = {
        headerName: `TOTAL ${side.toUpperCase()}S`,
        minWidth: colWidth,
        maxWidth: colWidth,
        sortable: false,
        colId: `${side}:total`,
        cellStyle: getTotalColumnCellStyle(comp, side),
        valueGetter: (params: ValueGetterParams) => {

            const rowData = params.data as HedgeMatrixRow;

            if (isVoid(rowData)) {
                return null;
            }

            const selectedHedgesIds = comp.getSelectedHedgesIds(side);

            const uniqueExpirations = Enumerable.from(selectedHedgesIds)
                .selectMany(x =>
                    comp.hedgeMatrixDataService.getHedgeExpirations(comp.selectedPortfolio, x))
                .distinct()
                .toArray();

            const hasMixed = uniqueExpirations.length > 1;

            if (hasMixed && !rowData.isHedgePrice) {
                return null;
            }

            const strike = params.data.strike;

            const visible = selectedHedgesIds;

            if (rowData.isHedgePrice) {
                if (visible.length === 0) {
                    return null;
                }

                const hp = comp.hedgesPricingService
                    .getHedgesCostPicked(visible);

                const transCosts = visible
                    .map(vh => comp.hedgeMatrixDataService
                        .getTransCost(comp.selectedPortfolio, vh) || 0);

                const transSum = transCosts.reduce((p, c) => p + c, 0) * -1;

                const total = transSum + hp;

                return total;
            }

            const totalQty = comp.hedgeMatrixDataService.getTotalQtyForStrike(
                comp.selectedPortfolio,
                strike,
                side,
                visible
            );

            if (isVoid(totalQty)) {
                return null;
            }

            return totalQty;
        },
        valueFormatter: (params: ValueFormatterParams) => {
            const value = params.value;

            if (!isValidNumber(value)) {
                return null;
            }

            const rowData = params.data as HedgeMatrixRow;

            if (rowData && rowData.isHedgePrice) {
                return defaultCurrencyFormatter(value);
            }

            let ticker;

            const hedgeDatum = comp.hedgeMatrixDataService.getHedges(comp.selectedPortfolio)
                .filter(x => x.type === side && !isVoid(x.legs))[0];

            if (hedgeDatum) {
                let hedgePosition = Enumerable.from(hedgeDatum.legs)
                    .firstOrDefault(x => !isVoid(x.ticker));

                if (!isVoid(hedgePosition)) {
                    const optionTicker = parseOptionTicker(hedgePosition.ticker);
                    optionTicker.strike = rowData.strike;
                    ticker = zipOptionTicker(optionTicker);
                }
            }

            const lastQuote = comp.lastQuoteCache.getLastQuote(ticker);

            if (isVoid(lastQuote)) {
                return value;
            }

            let sign = '';
            if (value > 0) {
                sign = '+';
            }

            const quote = defaultCurrencyFormatter(lastQuote.mid)
            const v = `${quote} (${sign}${value}) `;

            return v;
        },
    };

    const grp: ColGroupDef = {
        headerGroupComponent: 'matrixTotalGroupHeader',
        headerGroupComponentParams: {
            context: {
                comp,
                type: 'side',
                side: side
            },
        },
        groupId: `${side}:total:group`,
        children: [totalColumn]
    }

    return grp;
}

function getOtmColumn(comp: HgHedgeMatrixComponent): ColDef {
    const col: ColDef = {
        headerName: '$ OTM',
        pinned: true,
        field: 'otm',
        colId: 'otm',
        minWidth: microWidth,
        maxWidth: microWidth,
        sortable: false,
        headerClass: 'ets-text-centered',
        rowSpan: (params: RowSpanParams) => params.data.rowSpan || 1,
        cellStyle: (params: CellClassParams) => {
            if (params.data.isPinned) {
                return {
                    background: '#2a2a2a',
                    'font-weight': 'bold'
                };
            }

            return {
                background: params.data.rowSpan > 1 ? '#2a2a2a' : undefined,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                borderBottomColor: 'rgb(66, 66, 66)',
            }
        }
    }
    return col;
}


//
// Pnl expirations
//
function getExpirationPnlGroup(comp: HgHedgeMatrixComponent, expiration: ExpirationDescriptor, allInclusive: boolean) {
    const col = getExpirationPnlColumn(comp, expiration, allInclusive);

    const groupId = allInclusive
        ? `${expiration.expiration}:pnl-expiration:${expiration.side}:all:group`
        : `${expiration.expiration}:pnl-expiration:${expiration.side}:group`;

    const grp: ColGroupDef = {
        headerGroupComponent: 'matrixTotalGroupHeader',
        headerGroupComponentParams: {
            context: {
                comp,
                type: 'expiration-side',
                expiration: expiration.expiration,
                side: expiration.side,
                allInclusive: true
            },
        },
        groupId: groupId,
        children: [col]
    }

    return grp;
}

function getExpirationPnlColumn(comp: HgHedgeMatrixComponent, expiration: ExpirationDescriptor, allInclusive: boolean): ColDef {

    const header = allInclusive
        ? `P&L ${expiration.side.toUpperCase()}S (All)`
        : `P&L ${expiration.side.toUpperCase()}S`;

    const colId = allInclusive
        ? `${expiration.expiration}:pnl-expiration:${expiration.side}:all`
        : `${expiration.expiration}:pnl-expiration:${expiration.side}`;

    const col: ColDef = {
        headerName: header,
        colId: colId,
        minWidth: minWidth,
        width: minWidth,
        headerClass: 'ets-text-centered',
        sortable: false,
        hide: true,
        rowSpan: (params: RowSpanParams) => params.data.rowSpan || 1,
        valueGetter: (params: ValueGetterParams) => {
            if (params.data.isPinned) {
                if (params.data.isExpiration || params.data.isDayOfWeek) {
                    const val = params.data.isExpiration
                        ? expiration.expirationFriendly
                        : expiration.dayOfWeek
                    return val;
                } else if (params.data.isHedgePrice) {

                    let visible = allInclusive
                        ? comp.hedgeMatrixDataService.getHedges(comp.selectedPortfolio)
                            .map(x => x.id)
                        : comp.getVisibleHedges();

                    const hedgeIds = comp
                        .hedgeMatrixDataService
                        .getHedges(comp.selectedPortfolio)
                        .filter(x => visible.indexOf(x.id) >= 0)
                        .filter(x => x.type === expiration.side)
                        .filter(x =>
                            comp.hedgeMatrixDataService.hedgeContainsExpiration(comp.selectedPortfolio, x.id, expiration.expiration));

                    const pnls = hedgeIds.map(x => {

                        const originalCost = comp.hedgeMatrixDataService.getOriginalCost(
                            comp.selectedPortfolio,
                            x.id,
                            expiration.expiration
                        ) || 0;

                        const transCost = comp.hedgeMatrixDataService.getTransCostAsOwned(
                            comp.selectedPortfolio,
                            x.id,
                            expiration.expiration
                        ) || 0;

                        const sum = originalCost + transCost;

                        return sum;
                    });

                    const sum = pnls.reduce((previousValue, currentValue) => previousValue + currentValue, 0);
                    return sum;
                } else {
                    return undefined;
                }
            }

            let visible = allInclusive
                ? comp.hedgeMatrixDataService.getHedges(comp.selectedPortfolio).map(x => x.id)
                : comp.getVisibleHedges();

            const pnl = comp.hedgeMatrixDataService.getExpirationPnl(
                comp.selectedPortfolio,
                expiration.expiration,
                params.data.strike,
                expiration.side,
                visible
            );

            return pnl;
        },
        valueFormatter: (params: ValueFormatterParams) => {
            if (!isValidNumber(params.value, true)) {
                return null;
            }
            return defaultCurrencyFormatter(params.value);
        },
        cellStyle: getExpirationColumnCellStyle(comp)
    }

    return col;
}

function getGrandTotalPnlExpirationColumn(comp: HgHedgeMatrixComponent, expiration: ExpirationDescriptor) {
    const colId = `${expiration.expiration}:grandtotalpnl`;
    const col: ColDef = {
        headerName: `P&L TOTAL`,
        colId: colId,
        minWidth: minWidth,
        width: minWidth,
        headerClass: 'ets-text-centered',
        hide: true,
        sortable: false,
        rowSpan: (params: RowSpanParams) => params.data.rowSpan || 1,
        valueGetter: (params: ValueGetterParams) => {
            if (params.data.isPinned) {
                if (params.data.isExpiration || params.data.isDayOfWeek) {
                    const val = params.data.isExpiration
                        ? expiration.expirationFriendly
                        : expiration.dayOfWeek
                    return val;
                } else if (params.data.isHedgePrice) {

                    let visible = comp.getVisibleHedges();

                    const hedgeIds = comp
                        .hedgeMatrixDataService
                        .getHedges(comp.selectedPortfolio)
                        .filter(x => visible.indexOf(x.id) >= 0)
                        .filter(x =>
                            comp.hedgeMatrixDataService.hedgeContainsExpiration(
                                comp.selectedPortfolio,
                                x.id,
                                expiration.expiration)
                        );

                    const pnls = hedgeIds.map(x => {

                        const originalCost = comp.hedgeMatrixDataService
                            .getOriginalCost(comp.selectedPortfolio,
                                x.id,
                                expiration.expiration
                            ) || 0;

                        const transCost = comp.hedgeMatrixDataService.getTransCostAsOwned(
                            comp.selectedPortfolio,
                            x.id,
                            expiration.expiration
                        ) || 0;

                        const sum = originalCost + transCost;

                        return sum;
                    });

                    const sum = pnls.reduce((previousValue, currentValue) => previousValue + currentValue, 0);

                    return sum;

                } else {
                    return undefined;
                }
            }

            const visible = comp.getVisibleHedges();

            const callPnl = comp.hedgeMatrixDataService.getExpirationPnl(
                comp.selectedPortfolio,
                expiration.expiration,
                params.data.strike,
                'Call',
                visible
            );

            const putPnl = comp.hedgeMatrixDataService.getExpirationPnl(
                comp.selectedPortfolio,
                expiration.expiration,
                params.data.strike,
                'Put',
                visible
            );

            let grandPnl = callPnl + putPnl;

            if (comp.expirationPnlShowNormalized.includes(expiration.expiration)) {
                grandPnl = grandPnl / 100 / comp.portfolioDefaultQty;
            }

            return grandPnl;
        },
        valueFormatter: (params: ValueFormatterParams) => {
            if (!isValidNumber(params.value, true)) {
                return null;
            }
            return defaultCurrencyFormatter(params.value);
        },
        cellStyle: getExpirationColumnCellStyle(comp)
    }

    const grp: ColGroupDef = {
        headerGroupComponent: 'matrixTotalGroupHeader',
        headerGroupComponentParams: {
            context: {
                comp,
                type: 'expiration',
                expiration: expiration.expiration,
            },
        },
        groupId: `${expiration.expiration}:grandtotalpnl:group`,
        children: [col]
    }

    return grp;
}

function getSpacerColumns(): ColDef[] {

    const spacers = Enumerable.range(1, 6)
        .select(x => {
            const col: ColDef = {
                headerName: '< SPACER >',
                colId: `spacer${x}`,
                enableRowGroup: false,
                showRowGroup: true,
                lockVisible: true,
                width: colWidth,
                hide: x !== 1,
                cellStyle: (args: CellClassParams) => {

                    const styleObj = {};

                    styleObj['font-weight'] = 'normal';
                    styleObj['background-color'] = 'rgba(0,0,0,0)';
                    styleObj['color'] = undefined;
                    styleObj['border-right-color'] = 'rgb(66, 66, 66)';

                    if (args.data && args.data.isPinned) {
                        styleObj['background-color'] = '#2a2a2a';
                    }

                    return styleObj;
                }
            }
            return col;
        }).toArray();

    return spacers;
}

function getHedgeColumnCellStyle(comp: HgHedgeMatrixComponent) {

    const f = function getCellStyle(params: CellClassParams) {

        const styleObj = {};
        styleObj['font-weight'] = 'normal';
        styleObj['background-color'] = 'rgba(0,0,0,0)';
        styleObj['color'] = undefined;
        styleObj['border-right-color'] = 'rgb(66, 66, 66)';


        const hedgeData: HedgeData = params.colDef['ets-data'];

        if (isVoid(hedgeData)) {
            return styleObj;
        }

        if (params.data.isPinned) {
            if (params.data.isExpiration || params.data.isDayOfWeek) {
                styleObj['background-color'] = '#e8ded1';
                styleObj['color'] = '#2a2a2a';
                styleObj['font-weight'] = 'bold';
            } else if (params.data.isHedgePrice) {

                const value = comp.getHedgePriceWithTrans(hedgeData);

                styleObj['background-color'] = '#2a2a2a';

                if (value < 0) {
                    styleObj['color'] = 'red';
                } else if (value > 0) {
                    styleObj['color'] = 'green';
                }
            }
        } else {

            const strike = params.data.strike;

            const legs = hedgeData.legs.filter(x => x.strike === strike);

            const expirationCellDataIndex = getExpirationCellDataIndex(
                strike,
                params.api,
                params.node
            );

            const leg = legs[expirationCellDataIndex];

            if (isVoid(leg)) {
                return styleObj;
            }

            styleObj['background-color'] = getColor(leg.qty);

            styleObj['color'] = 'white';
        }

        return styleObj;
    };

    return f;

}

function getTotalColumnCellStyle(comp: HgHedgeMatrixComponent, side: 'Call' | 'Put') {

    const f = function pricingColumnCellStyle(args: CellClassParams) {

        let styleObj = {};

        const rowData = args.data as HedgeMatrixRow;

        if (rowData && rowData.isPinned) {
            styleObj = {
                background: '#2a2a2a',
                color: 'white',
                'border-right-color': 'rgb(66, 66, 66)'
            };

            if (rowData.isHedgePrice) {
                const val = args.value + '';
                const replaced = val.replace('$', '');
                const number = parseFloat(replaced);

                if (number < 0) {
                    styleObj['color'] = 'red';
                } else if (number > 0) {
                    styleObj['color'] = 'green';
                }
            }

            return styleObj;
        }

        styleObj['font-weight'] = 'normal';
        styleObj['background'] = 'rgba(0,0,0,0)';
        styleObj['color'] = undefined;
        styleObj['border-right-color'] = 'rgb(66, 66, 66)';

        if (isVoid(args.value)) {
            return styleObj;
        }

        const strike = args.data.strike;

        const visible = comp
            .getSelectedHedges(side)
            .map(x => x.getColId());

        const qty = comp.hedgeMatrixDataService
            .getTotalQtyForStrike(comp.selectedPortfolio, strike, side, visible);

        if (!isValidNumber(qty, true)) {
            return styleObj;
        }

        styleObj['background-color'] = getColor(qty);

        styleObj['color'] = 'white';

        return styleObj;

    };

    return f;

}

function getColor(qty: number) {
    return qty > 0 ? '#00007c' : 'darkred';
}

function getExpirationColumnCellStyle(comp: HgHedgeMatrixComponent) {

    const f = function pricingColumnCellStyle(args: CellClassParams) {

        const styleObj = {};

        // styleObj['border-color'] = 'black';
        styleObj['font-weight'] = 'normal';
        styleObj['background-color'] = 'rgba(0,0,0,0)';
        styleObj['color'] = undefined;
        styleObj['border-right-color'] = 'rgb(66, 66, 66)';


        if (args.data.isPinned) {
            if (args.data.isExpiration || args.data.isDayOfWeek) {
                styleObj['background-color'] = '#e8ded1';
                styleObj['color'] = '#2a2a2a';
                styleObj['font-weight'] = 'bold';
            } else if (args.data.isHedgePrice) {
                styleObj['background-color'] = '#2a2a2a';

                const val = args.value + '';
                const replaced = val.replace('$', '');
                const number = parseFloat(replaced);

                if (number < 0) {
                    styleObj['color'] = 'red';
                } else if (number > 0) {
                    styleObj['color'] = 'green';
                }
            }
        }

        if (args.data.rowSpan > 1) {
            styleObj['display'] = 'flex';
            styleObj['align-items'] = 'center';
            styleObj['justify-content'] = 'center';
            styleObj['border-bottom-color'] = 'rgb(66, 66, 66)';
            styleObj['background-color'] = '#2a2a2a';
        }

        return styleObj;
    };

    return f;

}

function getModifierColumnCellStyle(comp: HgHedgeMatrixComponent, colType: 'outcomeQty' | 'transQty') {

    const f = function pricingColumnCellStyle(args: CellClassParams) {

        const styleObj = {};

        styleObj['font-weight'] = 'normal';
        styleObj['background-color'] = 'rgba(0,0,0,0)';
        styleObj['color'] = undefined;
        styleObj['border-right-color'] = 'rgb(66, 66, 66)';

        const hedgeData: HedgeData = args.colDef['ets-data'];

        if (isVoid(hedgeData)) {
            return styleObj;
        }

        if (args.data.isPinned) {

            styleObj['background-color'] = '#2a2a2a';

            if (args.data.isHedgePrice) {

                const value = parseFloat(args.value);

                if (!isValidNumber(value)) {
                    return styleObj;
                }

                if (value < 0) {
                    styleObj['color'] = 'red';
                } else if (value > 0) {
                    styleObj['color'] = 'green';
                }

            } else if (args.data.isExpiration || args.data.isDayOfWeek) {
                styleObj['background-color'] = '#e8ded1';
                styleObj['color'] = '#2a2a2a';
                styleObj['font-weight'] = 'bold';
            }

            return styleObj;
        }

        const val = parseFloat(args.value);

        if (!isValidNumber(val, true)) {
            return styleObj;
        }

        styleObj['background-color'] = getColor(val);

        styleObj['color'] = 'white';

        const strike = args.data.strike;

        const cellData = comp
            .hedgeMatrixDataService
            .getCellData(comp.selectedPortfolio, strike, hedgeData.id);

        if (isVoid(cellData)) {
            return styleObj;
        }

        const expirationCellDataIndex = getExpirationCellDataIndex(
            strike,
            args.api,
            args.node
        );

        const cell = cellData[expirationCellDataIndex];

        if (isVoid(cell)) {
            return styleObj;
        }

        if (comp.isCellSelected(cell, colType)) {
            styleObj['border'] = '2px solid yellow';
        }

        return styleObj;

    };
    return f;
}

export function getExpirationCellDataIndex(strike: number, gridApi: GridApi, selectedNode: RowNode) {
    let count = 0;
    let count2 = count;
    gridApi.forEachNode((node) => {
        const data = node.data as HedgeMatrixRow;

        if (isVoid(data)) {
            return;
        }

        if (data.strike !== strike) {
            return;
        }

        count++;

        if (node === selectedNode) {
            count2 = count - 1;
        }
    });
    return count2;
}

export function getHedgeExpirationsString(comp: HgHedgeMatrixComponent, hedge: HedgeData, column: 'original' | 'trans' | 'outcome'): string {
    let hedgeExpirations = comp.hedgeMatrixDataService
        .getHedgeExpirationsByColumn(comp.selectedPortfolio, hedge.id, column);

    if (isVoid(hedgeExpirations)) return null;

    if (hedgeExpirations.length === 1) return makeGuiFriendlyExpirationDate(hedgeExpirations[0]);

    const dtExpirations = hedgeExpirations.sort().map(x => DateTime.fromISO(x));

    const exp1 = dtExpirations[0];
    const exp2 = dtExpirations[1];

    const day1 = exp1.toFormat('dd');
    const day2 = exp2.toFormat('dd');

    const month1 = exp1.toFormat('MMM');
    const month2 = exp2.toFormat('MMM');

    const year1 = exp1.toFormat('yy');
    const year2 = exp2.toFormat('yy');

    const day = `${day1}/${day2}`;
    const month = month1 === month2 ? month1 : `${month1}/${month2}`;
    const year = year1 === year2 ? year2 : `${year1}/${year2}`;

    const header = [day, month, year].join('-');

    return header;
}

export function getDaysOfWeekString(comp: HgHedgeMatrixComponent, hedge: HedgeData, column: 'original' | 'trans' | 'outcome'): string {
    let hedgeExpirations = comp.hedgeMatrixDataService
        .getHedgeExpirationsByColumn(comp.selectedPortfolio, hedge.id, column);

    if (isVoid(hedgeExpirations)) return null;

    if (hedgeExpirations.length === 1) return makeDayOfWeekDate(hedgeExpirations[0]);

    const daysOfWeek = hedgeExpirations.sort()
        .map(x => makeDayOfWeekDate(x))
        .map(x => {
            const parts = x.split(' ');
            parts[1] = parts[1].replace('(', '');
            parts[1] = parts[1].replace(')', '');
            return parts;
        });

    const d1 = daysOfWeek[0];
    const d2 = daysOfWeek[1];

    const dows = `${d1[0]}/${d2[0]}`;
    const dtes = `${d1[1]}/${d2[1]}`;

    const result = `${dows} (${dtes})`;

    return result;
}

function getQtyValueSetter(comp: HgHedgeMatrixComponent, hedge: HedgeData, field: keyof HedgeMatrixCellData): (params: ValueSetterParams) => boolean {
    function valueSetter(params: ValueSetterParams) {
        const strike = params.data.strike;

        if (!isValidNumber(strike, true)) {
            return null;
        }

        const beforeCellData = comp.hedgeMatrixDataService.getCellData(
            comp.selectedPortfolio,
            strike,
            hedge.id
        );

        let beforeExpirationsCount: number;
        if (!isVoid(beforeCellData)) {
            beforeExpirationsCount = beforeCellData.length;
        }

        let beforeExpiration: string;

        if (!isVoid(beforeCellData)) {

            const expirationCellDataIndex = getExpirationCellDataIndex(
                strike,
                params.api,
                params.node
            );

            const expirationCellData = beforeCellData[expirationCellDataIndex];

            beforeExpiration = expirationCellData?.expiration;
        }

        const qtyObj = params.newValue;

        let expiration: string;
        let qtyToSet: number;
        let closeExisting = false;

        if (typeof qtyObj === 'object') {
            if (qtyObj.isCancel) {
                return false;
            }
            qtyToSet = qtyObj.qty;
            expiration = qtyObj.expiration;
            closeExisting = qtyObj.closeExisting;

        } else {

            expiration = beforeExpiration;

            if (isVoid(expiration)) {
                expiration = comp.hedgeMatrixDataService.getHedgeDefaultExpiration(comp.selectedPortfolio, hedge.id);
                if (isVoid(expiration)) {
                    const nearestExpiration = comp.hedgeMatrixDataService.getNearestExpiration(
                        comp.selectedPortfolio
                    );
                    expiration = nearestExpiration.optionExpirationDate;
                }
            }

            qtyToSet = parseInt(params.newValue);
        }

        if (!isValidNumber(qtyToSet, true)) {
            qtyToSet = null;
        }


        if (field === 'transQty') {

            comp.hedgeMatrixDataService.setTransQty(comp.selectedPortfolio,
                hedge,
                strike,
                expiration,
                qtyToSet
            );

            if (hedge.isNew) {
                const cellData = comp.hedgeMatrixDataService.getCellData(
                    comp.selectedPortfolio,
                    strike,
                    hedge.id
                );

                if (isVoid(cellData)) {
                    if (!isVoid(expiration)) {
                        hedge.initialExpiration = expiration;
                    }
                }
            }
        } else if (field === 'outcomeQty') {
            comp.hedgeMatrixDataService.setOutcomeQty(
                comp.selectedPortfolio,
                hedge,
                strike,
                expiration,
                qtyToSet
            );

            if (beforeExpiration !== expiration) {
                if (closeExisting) {
                    comp.hedgeMatrixDataService.setOutcomeQty(
                        comp.selectedPortfolio,
                        hedge,
                        strike,
                        beforeExpiration,
                        null
                    );
                }
            }

        } else {
            throw new Error(`unsupported field: ${field}`);
        }

        const afterCellData = comp.hedgeMatrixDataService.getCellData(
            comp.selectedPortfolio,
            strike,
            hedge.id
        );

        if (!isVoid(afterCellData)) {
            const afterExpirationCount = afterCellData.length;
            if (afterExpirationCount !== beforeExpirationsCount) {
                comp.rebuildRows(params.node);
            }
        }

        setTimeout(() => {
            comp.recalculatePnls();
            params.api.refreshCells({force: true});
        });

        return true;
    }

    return valueSetter;
}

function getValueFormatterForHedgeColumn(comp: HgHedgeMatrixComponent, hedge: HedgeData): (params: ValueFormatterParams) => string {
    function valueFormatter(params: ValueFormatterParams) {
        const value = params.value;

        if (!isValidNumber(value)) {
            return null;
        }

        const strike = params.data.strike;

        if (!isValidNumber(strike, true)) {
            if (params.data && params.data.isPinned) {
                if (params.data.isHedgePrice) {
                    return defaultCurrencyFormatter(value);
                }
            }
            return value;
        }

        const cellData = comp.hedgeMatrixDataService.getCellData(
            comp.selectedPortfolio,
            strike,
            hedge.id
        );

        if (isVoid(cellData)) {
            return value;
        }


        const expirationCellDataIndex = getExpirationCellDataIndex(
            strike,
            params.api,
            params.node
        );

        const expirationCellData = cellData[expirationCellDataIndex];

        if (isVoid(expirationCellData)) {
            return value;
        }

        let ticker = expirationCellData.ticker;

        if (isVoid(ticker)) {
            ticker = comp.hedgeMatrixDataService.getTickerForCell(
                comp.selectedPortfolio,
                strike,
                expirationCellData.expiration,
                expirationCellData.optionType
            );

            if (isVoid(ticker)) {
                return value;
            }
            expirationCellData.ticker = ticker;
        }

        const isExpired = isOptionExpired(ticker);

        comp.lastQuoteCache.subscribeIfNotYet(ticker);

        const lastQuote = comp.lastQuoteCache.getLastQuote(ticker);

        if (isVoid(lastQuote)) {
            if (!isExpired) {
                return value;
            }
        }

        let sign = '';

        if (value > 0) {
            sign = '+';
        }


        const quote = isExpired ? undefined : defaultCurrencyFormatter(lastQuote.mid);

        let formatValue = isExpired ? `EXP (${sign}${value}) ` : `${quote} (${sign}${value}) `;

        const hasMultipleExpiration = comp.hedgeMatrixDataService.doesHaveMultipleExpirations(
            comp.selectedPortfolio,
            expirationCellData.hedgeId
        );

        if (hasMultipleExpiration) {
            const dow = makeDayOfWeekDate(expirationCellData.expiration);
            formatValue += `, ${dow}`;
        }

        return formatValue;


    }

    return valueFormatter;
}