import {ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {DetectMethodChanges, findHCF, isValidNumber, isVoid} from "../../utils";

interface TradeSide {
    side: 'Buy' | 'Sell',
    value: 1 | -1 | 0;
}

export class NormCalcFill {
    constructor() {
        this.sideList = [
            {side: 'Buy', value: 1},
            {side: 'Sell', value: -1},
        ];
    };
    price: number;
    quantity: number;
    side: TradeSide
    sideList: TradeSide[];
    get total() : number {
        if (isVoid(this.side)) {
            return null;
        }
        return this.price * this.quantity * this.side?.value * -1;
    }
}


@Component({
    selector: 'ets-normalizing-calculator',
    templateUrl: 'normalizing-calculator.component.html',
    styleUrls: ['normalizing-calculator.component.scss']
})

export class NormalizingCalculatorComponent implements OnInit {
    constructor(
        private readonly _changeDetector: ChangeDetectorRef,
    ) {
    }

    title = "Normalizing Calculator";

    fills: NormCalcFill[] = [];

    defaultQty: number;

    visible: boolean

    numberBoxInputAttr = {
        style: 'text-align: center;'
    }

    mode : 'Single Leg' | 'Whole Combo' = 'Whole Combo';

    modeList = [
        'Single Leg',
        'Whole Combo'
    ];

    ngOnInit() {
    }

    @DetectMethodChanges()
    show() {
        this.fills.push(new NormCalcFill());
        this.visible = true;
    }

    @DetectMethodChanges()
    onClosed() {
        this.defaultQty = null;
        this.fills = [];
        this.visible = false;
    }

    @DetectMethodChanges()
    removeFill(fill: NormCalcFill) {
        const number = this.fills.indexOf(fill);
        if (number === -1) {
            return;
        }
        this.fills.splice(number, 1);
    }

    @DetectMethodChanges()
    addFill() {
        this.fills.push(new NormCalcFill());
    }

    getAvgFillPrice() {

        if (this.mode === 'Whole Combo') {
            const prices = this.getTransValue();

            const hcf = findHCF(this.fills.map(x => x.quantity));

            const result = prices / hcf;

            return result;
        } else {

            const prices = this.getTransValue();
            const totalQty = this.getTotalQty();

            return prices / totalQty;

        }
    }

    getTransValue(): number {
        const prices = this.fills.map(x => {
            let value = x.price * x.quantity * x.side?.value * -1;
            if (!isValidNumber(value)) {
                value = 0;
            }
            return value;
        }).reduce((a, b) => a + b, 0);

        return prices;
    }

    getTotalQty(): number {
        const totalQ = this.fills.map(x => x.quantity || 0)
            .reduce((a, b) => a + b, 0) || 1;

        return totalQ;
    }

    getNormalizedPrice(): number {

        const transPx = this.getTransValue();
        const defaultQty = this.defaultQty;

        const normPx = transPx / defaultQty;

        if (!isValidNumber(normPx)) {
            return null;
        }

        return normPx;

    }

    @DetectMethodChanges()
    onChange() {

    }

    getInputFieldStyle(fill: NormCalcFill) {
        if (isVoid(fill?.side)) {
            return null;
        }


        let color;

        switch (fill.side.side) {
            case "Buy":
                color = 'cornflowerblue';
                break;
            case "Sell":
                color = 'red';
                break;
        }

        const obj = {style: `color: ${color};`};

        return obj;
    }

    getPnLAttribute(value: number)  {

        let style = 'text-align: center;';

        const obj = {style};

        if (!isValidNumber(value, true)) {
            return obj;
        }

        const color = value > 0 ? 'green'  : 'red';
        style = style + `color: ${color}`;

        obj.style = style;

        return obj;
    }
}