import {Injectable} from '@angular/core';
import {ApgPortfolio} from "../../../adjustment-pricing-grid/model/ApgPortfolio";
import {HedgePosition} from "../../data-model/hedge-position";
import {MessageBusService} from "../../../message-bus.service";
import {UserSettingObject, UserSettingsService} from "../../../user-settings.service";
import {SessionService} from "../../../authentication/session-service.service";
import {isVoid} from "../../../utils";
import {filter} from "rxjs/operators";
import {LastUsedTemplate} from "../../../adjustment-pricing-grid/services/cashflow-strategy-templates.service";
import {setElementHeight} from "ag-grid-community/dist/lib/utils/dom";

@Injectable({providedIn: 'root'})
export class HgOriginalClientPositionsService {

    constructor(
        private readonly _messageBus: MessageBusService,
        private readonly _userSettings: UserSettingsService,
        private readonly _sessionService: SessionService,
    ) {
        this._userSettings.userSettingChanged$
            .pipe(
                filter(x => x.key.startsWith('hg.')),
                filter(x => {
                    const users = this._sessionService.sessionData.users;
                    if (isVoid(users)) {
                        return false;
                    }
                    return users.some(usr => usr.userId === x.userId);
                })
            )
            .subscribe(x => this.onUserSettingChanged(x));
    }

    getTheirOriginalPositions(selectedPortfolio: ApgPortfolio): HedgePosition[] {
        const ul = this.getTheirSnapshotTemplate(selectedPortfolio)?.underlying;

        const key = `hg.hedge-positions.${selectedPortfolio.id}.${ul}`;

        const positions = this._userSettings
            .getValue<HedgePosition[]>(key, selectedPortfolio.userId, 'their-snapshot') || [];

        return positions;
    }

    getTheirCurrentPositions(selectedPortfolio: ApgPortfolio): HedgePosition[] {
        let ul = this.getTheirCurrentTemplate(selectedPortfolio)?.underlying;

        if (isVoid(ul)) {
            ul = this.getTheirSnapshotTemplate(selectedPortfolio)?.underlying;
        }

        const key = `hg.hedge-positions.${selectedPortfolio.id}.${ul}`;

        const positions = this._userSettings.getValue<HedgePosition[]>(
            key, selectedPortfolio.userId, 'their-current'
        ) || [];

        return positions;
    }

    updateSnapshotStateWithCurrent(portfolio: ApgPortfolio): void {
        let tpl = this.getTheirCurrentTemplate(portfolio);
        if (isVoid(tpl)) {
            tpl = this.getTheirSnapshotTemplate(portfolio);
        }
        const positionsKey = `hg.hedge-positions.${portfolio.id}.${tpl?.underlying}`;
        this._userSettings.applyTheirCurrentState(positionsKey, portfolio.userId);

        this.resetToSnapshotState(portfolio);
    }

    resetToSnapshotState(portfolio: ApgPortfolio) {
        let tpl = this.getTheirSnapshotTemplate(portfolio);
        const key = `hg.hedge-positions.${portfolio.id}.${tpl?.underlying}`;
        this._userSettings.resetToSnapshotState(key, portfolio.userId);
    }


    private getTheirSnapshotTemplate(selectedPortfolio: ApgPortfolio): LastUsedTemplate {
        const key = `apg.last-used-template.${selectedPortfolio.id}`;

        const template = this._userSettings
            .getValue<LastUsedTemplate>(key, selectedPortfolio.userId, 'their-snapshot');

        return template;
    }

    private getTheirCurrentTemplate(selectedPortfolio: ApgPortfolio): LastUsedTemplate {

        const key = `apg.last-used-template.${selectedPortfolio.id}`;

        const template = this._userSettings
            .getValue<LastUsedTemplate>(key, selectedPortfolio.userId, 'their-current');

        return template;
    }

    private onUserSettingChanged(x: UserSettingObject) {

        if (x.userId === this._sessionService.sessionData?.userId) {
            return;
        }

        if (x.key.startsWith('hg.hedge-positions')) {
            this._messageBus.publish({
                topic: 'Hg.HedgePositionsChanged',
                payload: {}
            });
        }
    }
}