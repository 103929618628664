import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild} from '@angular/core';
import {OptionPricingGridMode, OptionsPricingGridComponentBase} from "./options-pricing-grid-base.component";
import {HedgePositionsService} from "../hedging-grid/positions-section/hedge-positions/hedge-positions.service";
import {MessageBusService} from "../message-bus.service";
import {LastQuoteCacheService} from "../last-quote-cache.service";
import {OptionsChainService} from "../option-chains.service";
import {ToastrService} from "ngx-toastr";
import {ClipboardService} from "../clipboard.service";
import {TradingInstrumentsService} from "../trading-instruments/trading-instruments-service.interface";
import {OptionPricingGridTemplatesService} from "./option-pricing-grid-templates.service";
import {AccessControlService} from "../access-control-service.class";
import {SessionService} from "../authentication/session-service.service";
import {CashFlowPortfoliosService} from "../adjustment-pricing-grid/services/cashflow-portfolios.service";
import {UserSettingsService} from "../user-settings.service";
import {ApgDataService} from "../adjustment-pricing-grid/services/apg-data.service";
import {PricingGridStrategy} from "./model/pricing-grid.strategy";


@Component({
    selector: 'ets-options-pricing-grid',
    templateUrl: 'options-pricing-grid.component.html',
    styleUrls: ['options-pricing-grid.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [ApgDataService, HedgePositionsService]
})
export class OptionsPricingGridComponent extends OptionsPricingGridComponentBase {

    constructor(changeDetector: ChangeDetectorRef, layoutService: UserSettingsService, messageBus: MessageBusService, lastQuoteCache: LastQuoteCacheService, optionsChainService: OptionsChainService, toastr: ToastrService, clipboardService: ClipboardService, tiService: TradingInstrumentsService, templatesService: OptionPricingGridTemplatesService, accessControlService: AccessControlService, sessionService: SessionService, portfolioService: CashFlowPortfoliosService, apgDataService: ApgDataService, hedgePositionService: HedgePositionsService) {

        super(changeDetector,
            layoutService,
            messageBus,
            lastQuoteCache,
            optionsChainService,
            toastr,
            clipboardService,
            tiService,
            templatesService,
            accessControlService,
            sessionService,
            portfolioService,
            apgDataService,
            hedgePositionService);
    }

    mode: OptionPricingGridMode = 'opg';
}
