import {IHedgeMatrixDataService} from "./hedge-matrix-data-service.interface";
import {LastQuoteCacheService} from "../../last-quote-cache.service";
import {ApgDataService} from "../../adjustment-pricing-grid/services/apg-data.service";
import {HedgePositionsService} from "../positions-section/hedge-positions/hedge-positions.service";
import {HedgeMatrixCellData} from "./hedge-matrix-cell.data";
import {HedgeData} from "./hedge-data";
import {
    BucketRoleColor,
    GetOptionChainShellResponse,
    OptionExpirationDescriptor
} from "../../shell-communication/shell-dto-protocol";
import {
    convertFriendlyExpirationToIso,
    determineDateFormat,
    findHCF,
    getShortUUID,
    isValidNumber,
    isVoid
} from "../../utils";
import * as Enumerable from "linq";
import {makeOptionTicker, parseOptionTicker} from "../../options-common/options.model";
import {HedgeMatrixTransLeg} from "./hedge-matrix-trans.leg";
import {HedgePosition} from "../data-model/hedge-position";
import {ApgPortfolio} from "../../adjustment-pricing-grid/model/ApgPortfolio";
import {BeforePositionDto} from "../../adjustment-pricing-grid/model/BeforePositionDto";
import {OptionsChainService} from "../../option-chains.service";

export class PortfolioHedgeMatrixDataService implements IHedgeMatrixDataService {

    constructor(
        public readonly portfolio: ApgPortfolio,
        private readonly _optionChainService: OptionsChainService,
        private readonly _lastQuoteCache: LastQuoteCacheService,
        private readonly _apgDataService: ApgDataService,
        private readonly _hedgePositionsService: HedgePositionsService
    ) {
    }


    private _cellDataIndex: Record<number, Record<string, Record<string, HedgeMatrixCellData>>> = {};
    private _existingHedges: HedgeData[] = [];
    private _newHedges: HedgeData[] = [];
    private _portfolioPositionsByStrike: Record<number, string[]> = {};
    private _underlying: string;
    private _portfolioDefaultQty: number;
    private _chain: GetOptionChainShellResponse;
    private _strikes: number[];

    //  expiration: { strike: { hedge: [pnl] } }
    private _callExpirationPnl: Record<string, Record<number, Record<string, number>>> = {};
    private _putExpirationPnl: Record<string, Record<number, Record<string, number>>> = {}

    reset() {
        this._cellDataIndex = {};
        this._existingHedges = [];
        this._newHedges = [];
        this._portfolioPositionsByStrike = {};
        this._underlying = undefined;
        this._portfolioDefaultQty = undefined;
        this._chain = undefined;
        this._strikes = [];
        this._callExpirationPnl = {};
        this._putExpirationPnl = {};
    }

    async onPortfolioSelected(): Promise<void> {

        this._underlying = await this._apgDataService.getUnderlyingOfPortfolio(this.portfolio);

        this._portfolioDefaultQty = await this._apgDataService.getDefaultQtyForPortfolio(this.portfolio);

        this._chain = await this._optionChainService.getChain(this._underlying)

        const hedgePositions = await this._hedgePositionsService
            .getHedgePositions(this.portfolio);

        const hedges = Enumerable.from(hedgePositions)
            .groupBy(x => x.groupId)
            .select(x => {
                const hedge: HedgeData = {
                    name: x.first().label,
                    id: x.key(),
                    color: x.first().color,
                    legs: x.toArray(),
                    type: x.first().type,
                };
                return hedge;
            })
            .toArray();

        const sorted = hedges
            .sort((grpA, grpB) => {

                const grpAOrder = grpA.legs[0].groupOrder;
                const grpBOrder = grpB.legs[0].groupOrder;

                if (isValidNumber(grpAOrder) && isValidNumber(grpBOrder)) {
                    return grpAOrder - grpBOrder;
                } else {
                    const lastStrikeA = grpA.legs[grpA.legs.length - 1].strike;
                    const lastStrikeB = grpB.legs[grpB.legs.length - 1].strike;
                    return lastStrikeB - lastStrikeA;
                }
            });

        this._existingHedges = sorted;

        Enumerable.from(hedgePositions)
            .groupBy(x => x.strike)
            .forEach(x => {
                const strike = x.key();

                let hedgeContainer = this._cellDataIndex[strike];

                if (isVoid(hedgeContainer)) {
                    hedgeContainer = {};
                    this._cellDataIndex[strike] = hedgeContainer;
                }

                x.forEach(y => {

                    let expirationContainer = hedgeContainer[y.groupId];

                    if (isVoid(expirationContainer)) {
                        expirationContainer = {};
                        hedgeContainer[y.groupId] = expirationContainer;
                    }

                    const cData: HedgeMatrixCellData = {
                        ticker: y.ticker,
                        underlying: y.asset,
                        hedgeId: y.groupId,
                        color: y.color,
                        label: y.label,
                        optionType: y.type,
                        strike: y.strike,
                        expiration: y.expiration,
                        qty: y.qty
                    }

                    expirationContainer[y.expiration] = cData;
                });
            });

        const portfolioPositions = await this.getPortfolioPositions(this.portfolio);

        portfolioPositions
            .flatMap(x => x)
            .forEach(x => {

                const ticker = parseOptionTicker(x.ticker);

                const role = x.role;
                const strike = ticker!.strike;
                const color = BucketRoleColor[role];
                const type = ticker.type;

                let container = this._portfolioPositionsByStrike[strike];

                if (isVoid(container)) {
                    container = [];
                    this._portfolioPositionsByStrike[strike] = container;
                }

                if (container.indexOf(color) < 0) {
                    container.push(color);
                }
            });
    }

    getPortfolioUnderlying(): string {
        return this._underlying;
    }

    getCellData(strike: number, hedgeId: string): HedgeMatrixCellData[] {
        let hedgeContainer = this._cellDataIndex[strike];

        if (isVoid(hedgeContainer)) {
            return null;
        }

        const expirationContainer = hedgeContainer[hedgeId];

        if (isVoid(expirationContainer)) {
            return null;
        }

        return Object.values(expirationContainer);
    }

    removeHedgeCellData(cellData: HedgeMatrixCellData) {
        const strike = cellData.strike;
        const hedgeId = cellData.hedgeId;
        const expiration = cellData.expiration;

        try {
            delete this._cellDataIndex[strike][hedgeId][expiration];
        } catch {
        }
    }

    getLegStrikes(): number[] {
        const strikes = Object.keys(this._cellDataIndex)
            .map(x => parseInt(x))
            .filter(x => isValidNumber(x, true));
        return strikes;
    }

    getTotalQtyForStrike(strike: number, side: 'Call' | 'Put', visible: string[]): number {

        const hasMixed = visible.some(x => this.doesHaveMultipleExpirations(x));

        if (hasMixed) {
            return null;
        }

        const strikeContainer = this._cellDataIndex[strike];

        if (isVoid(strikeContainer)) {
            return null;
        }

        const hedgeContainers = Object.values(strikeContainer);

        if (isVoid(hedgeContainers)) {
            return null;
        }

        const cellDatas = hedgeContainers
            .flatMap(x => Object.values(x) as HedgeMatrixCellData[]);

        const total = Enumerable.from(cellDatas)
            .where(x => x.optionType === side)
            .where(x => visible.indexOf(x.hedgeId) !== -1)
            .select(x => {
                const qty = x.qty || 0;
                const transQty = x.transQty || 0;
                const totalQty = qty + transQty;
                return totalQty;
            })
            .aggregate(0, (p, c) => p + c);

        return total;
    }

    getHedges(): HedgeData[] {
        return this._newHedges.concat(this._existingHedges).sort(
            (a, b) => a.type.localeCompare(b.type)
        );
    }

    getHedge(id: string) {
        const hedgeData = this.getHedges().find(x => x.id === id);
        return hedgeData;
    }

    getPortfolioLegColorByStrike(strike: number): string[] {
        const arr = this._portfolioPositionsByStrike[strike] || [];
        return arr.slice();
    }

    getPortfolioPositionStrikes(): number[] {
        return Object.keys(this._portfolioPositionsByStrike).map(x => parseInt(x));
    }

    getTickerForCell(strike: number, expiration: string, side: "Call" | "Put"): string {

        const expirationDateFormat = determineDateFormat(expiration);

        if (expirationDateFormat === 'friendly') {
            expiration = convertFriendlyExpirationToIso(expiration);
        }

        const expDescriptor = this._chain.expirations
            .find(x => x.optionExpirationDate
                .indexOf(expiration) >= 0);

        const ticker = makeOptionTicker(expDescriptor, side, strike);

        return ticker;

    }

    getNearestExpiration(): OptionExpirationDescriptor {
        return this._chain.expirations[0];
    }

    setTransQty(hedge: HedgeData, strike: number, expiration: string, transQty: number) {
        this.setQty(hedge, strike, expiration, 'transQty', transQty);
    }

    setOutcomeQty(hedge: HedgeData, strike: number, expiration: string, outcomeQty: number) {
        this.setQty(hedge, strike, expiration, 'outcomeQty', outcomeQty);
    }

    onHedgeModificationFinished(hedgeData: HedgeData): boolean {
        let needToRebuildRows = false;
        Object.keys(this._cellDataIndex)
            .forEach((strike: string) => {

                const iStrike = parseInt(strike, 10);

                const hedgeContainer = this._cellDataIndex[iStrike];

                if (isVoid(hedgeContainer)) {
                    return;
                }

                const expirationContainer = hedgeContainer[hedgeData.id];

                if (isVoid(expirationContainer)) {
                    return;
                }

                const cellData = Object.values(expirationContainer);

                if (isVoid(cellData)) {
                    return;
                }

                cellData.forEach((cd, ix) => {
                    cd.transQty = cd.outcomeQty = null;
                    if (!isValidNumber(cd.qty, true)) {
                        this.removeHedgeCellData(cd);
                        needToRebuildRows = true;
                    }
                });
            });

        return needToRebuildRows;
    }

    getTransCost(hedgeId: string, expiration?: string): number {
        const cost = this.getHedgeCostByField(hedgeId, 'transQty', expiration);
        return cost;
    }

    getTransCostAsOwned(hedgeId: string, expiration?: string): number {
        let cost = this.getTransCost(hedgeId, expiration);
        cost *= -1;
        return cost;
    }

    getOutcomeCost(hedgeId: string, expiration?: string): number {
        const cost = this.getHedgeCostByField(hedgeId, 'outcomeQty', expiration);
        return cost;
    }

    getOriginalCost(hedgeId: string, expiration?: string): number {
        const cost = this.getHedgeCostByField(hedgeId, 'qty', expiration);
        return cost;
    }

    private getHedgeCostByField(hedgeId: string, field: keyof HedgeMatrixCellData, expiration?: string) {
        const hedgeContainers = Object.values(this._cellDataIndex);

        const expirationContainers = hedgeContainers
            .map(x => x[hedgeId])
            .filter(x => !isVoid(x));

        const cellDatas = expirationContainers.flatMap(x => Object.values(x));

        const qtyDatas = cellDatas.filter(x => !!x)
            .filter(x => isValidNumber(x[field] as number, true))

        const qtties = qtyDatas
            .filter(x => isValidNumber(x[field] as number, true))
            .map(x => x[field] as number);

        if (qtties.length === 0) {
            return null;
        }

        let totalCost = qtyDatas
            .filter(x => isVoid(expiration) ? true : x.expiration === expiration)
            .map(x => {
                const qty = x[field] as number;
                const quote = this._lastQuoteCache.getLastQuote(x.ticker);
                const defaultQty = this._portfolioDefaultQty;

                const hcf = findHCF(qtties);

                let cost = quote?.mid * qty / hcf;

                const ratio = hcf / defaultQty;

                if (isValidNumber(ratio, true)) {
                    cost = cost * ratio;
                }

                return cost * -1;

            })
            .reduce((p, c) => {
                if (!isValidNumber(p) || !isValidNumber(c)) {
                    return null;
                }
                return p + c;
            }, 0);

        if (field !== 'transQty') {
            totalCost *= -1;
        }

        return totalCost;
    }

    onHedgeModificationStarted(hedgeData: HedgeData): boolean {
        Object.keys(this._cellDataIndex)
            .forEach(strike => {

                const iStrike = parseInt(strike);

                const hedgeContainer = this._cellDataIndex[iStrike];

                if (isVoid(hedgeContainer)) {
                    return;
                }

                const expirationContainer = hedgeContainer[hedgeData.id];

                if (isVoid(expirationContainer)) {
                    return;
                }


                const cellData = Object.values(expirationContainer);

                if (isVoid(cellData)) {
                    return;
                }

                cellData.forEach(cd => {
                    cd.outcomeQty = cd.qty;
                });
            });

        return false;
    }

    async addNewHedge(side: 'Call' | 'Put', underlying: string): Promise<HedgeData> {

        const hedgeData: HedgeData = {
            id: getShortUUID(),
            isNew: true,
            type: side,
            name: `* New Hedge ${this._newHedges.length + 1} *`,
            color: undefined,
            legs: []
        };

        this._newHedges.push(hedgeData);

        return hedgeData;
    }

    onNewHedgeExpirationChanged(hedgeId: string, expiration: string) {

        const hedgeData = this.getHedge(hedgeId);

        Object.keys(this._cellDataIndex).forEach(strike => {

            const strikeContainer: Record<string, Record<string, HedgeMatrixCellData>> = this._cellDataIndex[strike];

            if (isVoid(strikeContainer)) {
                return;
            }

            const hedgeContainer = strikeContainer[hedgeId];

            if (isVoid(hedgeContainer)) {
                return;
            }

            const cellDatas = Object.values(hedgeContainer) as HedgeMatrixCellData[];

            if (isVoid(cellDatas)) {
                return;
            }

            const totalQty = cellDatas
                .map(x => x.transQty)
                .reduce((a, b) => a + b);

            cellDatas.forEach(cd => delete hedgeContainer[cd.expiration]);

            this.setTransQty(hedgeData, parseInt(strike), expiration, totalQty);
        });

        this.calculatePnls(this._strikes);
    }

    removeHedge(hedgeId: string) {
        const ix = this._newHedges.findIndex(x => x.id === hedgeId);
        if (ix === -1) {
            return;
        }
        this._newHedges.splice(ix, 1);

        Object.keys(this._cellDataIndex)
            .forEach(strike => {
                const strikeContainer = this._cellDataIndex[strike];
                if (isVoid(strikeContainer)) {
                    return;
                }
                delete strikeContainer[hedgeId];
            });
    }

    getTransactionLegs(hedgeId: string): HedgeMatrixTransLeg[] {

        const cellData = this.getCellDataForHedge(hedgeId);

        const legs = cellData
            .filter(x => isValidNumber(x.transQty, true))
            .map(x => {

                const ticker = x.ticker;
                const transQty = x.transQty;

                return {
                    hedgeId,
                    ticker,
                    qty: transQty,
                }
            });

        return legs;
    }

    getOutcomeLegs(hedgeId: string): HedgeMatrixTransLeg[] {

        const cellData = this.getCellDataForHedge(hedgeId);

        const legs = cellData
            .filter(x => isValidNumber(x.outcomeQty, true))
            .map(x => {

                const ticker = x.ticker;
                const outcomeQty = x.outcomeQty;

                return {
                    hedgeId,
                    ticker,
                    qty: outcomeQty,
                }
            });

        return legs;
    }

    calculatePnls(strikes: number[]) {
        this._strikes = strikes;
        this._callExpirationPnl = {};
        this._putExpirationPnl = {};
        this.calculateExpirationPnls('Call', strikes, this._callExpirationPnl);
        this.calculateExpirationPnls('Put', strikes, this._putExpirationPnl);
    }

    getExpirationPnl(expiration: string, strike: number, side: 'Call' | 'Put', visible: string[]) {

        const index = side === 'Call'
            ? this._callExpirationPnl
            : this._putExpirationPnl;


        const expContainer = index[expiration];

        if (isVoid(expContainer)) {
            return null;
        }

        const strikeContainer = expContainer[strike];

        if (isVoid(strikeContainer)) {
            return null;
        }

        const numbers = Object.keys(strikeContainer).filter(x => visible.indexOf(x) !== -1)
            .map(x => strikeContainer[x]);

        const total = numbers.reduce((p, c) => p + c, 0);

        return total;
    }

    private calculateExpirationPnls(side: 'Call' | 'Put', strikes: number[], index: Record<string, Record<number, Record<string, number>>>) {
        const hedges = this.getHedges();
        const sideHedges = hedges.filter(x => x.type === side);
        sideHedges.forEach(hedge => {

            const hedgeExpirations = this.getHedgeExpirations(hedge.id);

            hedgeExpirations.forEach(hedgeExpiration => {
                strikes.forEach((strike) => {

                    const hedgeId = hedge.id;

                    const hedgeIsNew = hedge.isNew;

                    let hedgeLegs = hedge.legs.slice();

                    if (hedgeIsNew) {
                        const transactionLegs = this
                            .getTransactionLegs(hedgeId)
                            .map(x => {
                                const optionTicker = parseOptionTicker(x.ticker);

                                const hp: HedgePosition = {
                                    qty: x.qty,
                                    ticker: x.ticker,
                                    groupId: x.hedgeId,
                                    type: optionTicker?.type,
                                    strike: optionTicker?.strike,
                                    expiration: optionTicker?.expiration,
                                };

                                return hp;
                            });

                        hedgeLegs = transactionLegs;

                        hedge.legs = hedgeLegs;
                    } else {

                        const outcomeLegs = this.getOutcomeLegs(hedgeId);

                        if (outcomeLegs.length > 0) {
                            const positions = outcomeLegs.map(x => {

                                const optionTicker = parseOptionTicker(x.ticker);

                                const hp: HedgePosition = {
                                    qty: x.qty,
                                    ticker: x.ticker,
                                    groupId: x.hedgeId,
                                    type: optionTicker?.type,
                                    strike: optionTicker?.strike,
                                    expiration: optionTicker?.expiration,
                                };

                                return hp;

                            });

                            hedgeLegs = positions;
                        }
                    }

                    const legPnls = hedgeLegs
                        .filter(x => x.expiration === hedgeExpiration)
                        .map(leg => {

                            const expiration = leg.expiration;

                            const cellData = this.getQty(leg.strike, hedgeId);

                            if (isVoid(cellData)) {
                                return 0;
                            }

                            const hedgeMatrixCellData = cellData.find(x => x.expiration === expiration);

                            if (isVoid(hedgeMatrixCellData)) {
                                return 0;
                            }

                            let outcomeQty = hedgeMatrixCellData?.outcomeQty;

                            if (!isValidNumber(outcomeQty)) {
                                outcomeQty = leg.qty;
                            }

                            let delta = (strike - leg.strike) * outcomeQty;

                            if (leg.type === 'Put') {
                                delta *= -1;
                            }

                            if (delta < 0) {
                                if (outcomeQty > 0) {
                                    delta = 0;
                                }
                            } else if (delta > 0) {
                                if (outcomeQty < 0) {
                                    delta = 0;
                                }
                            }

                            return delta * 100;

                        })
                        .reduce((p, c) => p + c, 0);

                    let expContainer = index[hedgeExpiration];

                    if (isVoid(expContainer)) {
                        expContainer = {};
                        index[hedgeExpiration] = expContainer;
                    }

                    let strikeContainer = expContainer[strike];

                    if (isVoid(strikeContainer)) {
                        strikeContainer = {};
                        expContainer[strike] = strikeContainer;
                    }

                    strikeContainer[hedgeId] = legPnls;
                });
            });
        });
    }

    getQty(strike: number, hedgeId: string): HedgeMatrixCellData[] {
        const hedgeContainer = this._cellDataIndex[strike];

        if (isVoid(hedgeContainer)) {
            return null;
        }

        const expirationContainer = hedgeContainer[hedgeId];

        if (!expirationContainer) {
            return null;
        }

        const cellData = Object.values(expirationContainer);

        return cellData || [];
    }

    setQty(hedge: HedgeData, strike: number, expiration: string, field: keyof HedgeMatrixCellData, qty: number) {
        try {
            this.setQtyInternal(hedge, strike, expiration, field, qty);
        } finally {
            if (!isVoid(this._strikes)) {
                this.calculatePnls(this._strikes);
            }

        }
    }

    private setQtyInternal(hedge: HedgeData, strike: number, expiration: string, field: keyof HedgeMatrixCellData, qty: number) {
        let hedgeContainer = this._cellDataIndex[strike];

        if (isVoid(hedgeContainer)) {
            hedgeContainer = {};
            this._cellDataIndex[strike] = hedgeContainer;
        }

        let expirationContainer = hedgeContainer[hedge.id];

        if (!expirationContainer) {
            expirationContainer = {};
            hedgeContainer[hedge.id] = expirationContainer;
        }

        let cellData = expirationContainer[expiration];

        if (isVoid(cellData)) {

            cellData = {
                hedgeId: hedge.id,
                optionType: hedge.type,
                label: hedge.name,
                color: hedge.color,
                expiration: expiration,
                strike: strike,
                underlying: null,
                ticker: null
            };

            const tickerForCell = this
                .getTickerForCell(strike, expiration, hedge.type);

            cellData.ticker = tickerForCell;

            // @ts-ignore
            cellData[field] = qty;

            expirationContainer[expiration] = cellData;
        } else {
            // @ts-ignore
            cellData[field] = qty;
        }

        const legQty = cellData.qty || 0;

        if (!isValidNumber(qty)) {

            if (field === 'transQty') {
                cellData.transQty = null;
                cellData.outcomeQty = legQty || null;
            } else if (field === 'outcomeQty') {
                if (legQty !== 0) { // existing leg
                    cellData.transQty = -legQty;
                    cellData.outcomeQty = null;
                } else { // deleting just created leg
                    cellData.transQty = null;
                    cellData.outcomeQty = legQty || null;
                }
            }
        } else {
            if (field === 'outcomeQty') {
                const outcomeQty = cellData.outcomeQty || 0;
                const trans = (outcomeQty - legQty) || null;
                cellData.transQty = trans;
            } else {
                const transQty = cellData.transQty || 0;
                const outcome = (legQty + transQty) || null;
                cellData.outcomeQty = outcome;
            }
        }

        if (!isValidNumber(cellData.qty, true) &&
            !isValidNumber(cellData.transQty, true) &&
            !isValidNumber(cellData.outcomeQty, true)) {
            this.removeHedgeCellData(cellData);
        }
    }

    async getPortfolioPositions(portfolio: ApgPortfolio): Promise<BeforePositionDto[][]> {
        const positions = await this._apgDataService.getPortfolioPositions(portfolio);
        return positions;
    }

    getCellDataForHedge(hedgeId: string): HedgeMatrixCellData[] {
        const hedgeContainers = Object.values(this._cellDataIndex);

        const expirationContainers = hedgeContainers
            .map(x => x[hedgeId])
            .filter(x => !isVoid(x));

        const cellDatas = expirationContainers.flatMap(x => Object.values(x));

        return cellDatas;
    }

    doesHaveMultipleExpirations(hedgeId: string): boolean {
        const hedgeExpirations = this.getHedgeExpirations(hedgeId);
        return hedgeExpirations.length > 1;
    }

    getHedgeExpirations(hedgeId: string): string[] {
        const cellData = this.getCellDataForHedge(hedgeId);

        if (isVoid(cellData)) {
            const defaultExpiration = this.getHedgeDefaultExpiration(hedgeId);
            return [defaultExpiration];
        }

        const expirations = Enumerable.from(cellData)
            .select(x => x.expiration)
            .where(x => !isVoid(x))
            .distinct()
            .toArray();

        return expirations;
    }

    getHedgeExpirationsByColumn(hedgeId: string, state: 'original' | 'trans' | 'outcome'): string[] {
        let cellData = this.getCellDataForHedge(hedgeId);

        switch (state) {
            case "original":
                cellData = cellData.filter(x => isValidNumber(x.qty, true));
                break;
            case "trans":
                cellData = cellData.filter(x => isValidNumber(x.transQty, true));
                break;
            case "outcome":
                cellData = cellData.filter(x => isValidNumber(x.outcomeQty, true));
                break;
        }

        if (isVoid(cellData)) {
            const defaultExpiration = this.getHedgeDefaultExpiration(hedgeId);
            return [defaultExpiration];
        }

        const expirations = Enumerable.from(cellData)
            .select(x => x.expiration)
            .where(x => !isVoid(x))
            .distinct()
            .toArray();

        return expirations;
    }

    hedgeContainsExpiration(hedgeId: string, expiration: string): boolean {
        const expirations = this.getHedgeExpirations(hedgeId);
        if (expirations.length > 0) {
            return expirations.includes(expiration);
        }
        const defaultExpiration = this.getHedgeDefaultExpiration(hedgeId);
        return defaultExpiration === expiration;
    }

    getHedgeDefaultExpiration(hedgeId: string): string {
        const hedgeData = this.getHedge(hedgeId);
        const cellData = this.getCellDataForHedge(hedgeId);

        if (isVoid(cellData)) {
            if (hedgeData) {
                if (hedgeData.initialExpiration) {
                    return hedgeData.initialExpiration;
                }
            }
            return this.getNearestExpiration()?.optionExpirationDate;
        }

        const sortedExpirations = cellData.map(x => x.expiration)
            .sort();

        const defaultExpiration = sortedExpirations[0];

        return defaultExpiration;
    }
}