import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MessageBusService } from '../message-bus.service';
import { PanelBaseComponent } from '../panels/panel-base.component';
import { TimestampsService } from '../timestamps.service';
import {UserSettingsService} from "../user-settings.service";
import {GridApi, GridOptions, GridReadyEvent, RowSpanParams} from "ag-grid-community";
import * as Enumerable from "linq";
import {CellClassParams} from "ag-grid-community/dist/lib/entities/colDef";
import {DetectMethodChanges} from "../utils";


type RowData = {
   strike: number;
   rowSpan: number;
   expiration?: string;
   hedgeA?: number;
   hedgeB?: number;
   total?: number;
}



@Component({
   selector: 'ets-test-panel',
   templateUrl: 'test-panel.component.html',
   styleUrls: ['test-panel.component.scss'],
})
export class TestPanelComponent extends PanelBaseComponent {

   constructor(
      protected readonly _changeDetector: ChangeDetectorRef,
      protected readonly _userSettingsService: UserSettingsService,
      protected readonly _messageBus: MessageBusService,
      private readonly _timestampsService: TimestampsService
   ) {
      
      super(_changeDetector, _userSettingsService, _messageBus);
   }

   private _expirationsByStrike: Record<number, string[]> = {};

   gridOptions: GridOptions;
   gridApi: GridReadyEvent;

   ngOnInit() {
      this.gridOptions = {
         columnDefs: [
            {
               headerName: 'Strike',
               field: 'strike',
               rowSpan: (params: RowSpanParams) => {
                  const data = params.data as RowData;
                  return data.rowSpan;
                  // return this._expirationsByStrike[data.strike]?.length || 1;
               },
               cellStyle: (params: CellClassParams) => {
                  const data = params.data as RowData;
                  if (data.rowSpan > 1) {
                     return {'background': '#0CCCCC'}
                  }
               },
            },
            {
               headerName: 'Hedge A',
               field: 'hedgeA',
            },
            {
               headerName: 'Hedge B',
               field: 'hedgeB',
            },
            {
               headerName: 'Total',
               field: 'total',
               cellStyle: (params: CellClassParams) => {
                  const data = params.data as RowData;
                  if (data.rowSpan > 1) {
                     return {'background': '#0CCCCC'}
                  }
               },
               rowSpan: (params: RowSpanParams) => {
                  const data = params.data as RowData;
                  return data.rowSpan;
                  // return this._expirationsByStrike[data.strike]?.length || 1;
               },
            },
         ],
         suppressRowTransform: true,
         onGridReady: (event: GridReadyEvent) => {
            this.gridApi = event;
            const rows = this.makeRows();
            this.gridApi.api.setRowData(rows);
         },
         getContextMenuItems: params => {
            return [
               {
                  name: 'Add Expiration',
                  action: () => {
                     this.addExpiration(params.node.data as RowData);
                  }
               }
            ]
         }
      }
   }

   //#region base interface

   // @DetectMethodChanges()
   etsOnInit(): void {

   }

   etsOnDestroy(): void {
   }

   etsAfterViewInit(): void {
      this._changeDetector.detach();
   }

   protected getState() {
      return null;
   }

   protected setState(state: any) {
   }
   
   //#endregion

   @DetectMethodChanges()
   private addExpiration(data: RowData) {
      const rowData = this.makeRows();
      rowData.push({
         strike: data.strike,
         expiration: '2342343',
         hedgeA: -80,
         hedgeB: 75,
         total: 8532.123,
         rowSpan: 1
      });
      rowData.sort((a, b) => a.strike - b.strike);
      const strikeData = rowData.filter(x => x.strike === data.strike);
      strikeData[0].rowSpan = strikeData.length;
      this.gridApi.api.setRowData(rowData);
   }

   private makeRows() {
      const rows = Enumerable.range(5760, 20, 5)
          .select(x => {
             const row: RowData = {
                strike: x,
                expiration: '2024-10-20',
                hedgeA: 3,
                hedgeB: -4,
                total: 343,
                rowSpan: 1
             }
             return row;
          }).toArray();

      rows.push({
         strike: 5800,
         expiration: '2024-10-21',
         hedgeA: 7,
         hedgeB: -11,
         total: 321.25,
         rowSpan: 1
      });

      rows.sort((a,b)=>a.strike-b.strike);

      Enumerable.from(rows)
          .groupBy(x => x.strike)
          .forEach(x => {
             if (x.count() > 1) {
                x.first().rowSpan = x.count();
             }
          });

      return rows;
   }
}
